import React from "react";
import { HiArrowRight } from "react-icons/hi";
import { IImageInfo } from "../../../types/my-images";
import { ImageItem } from "../../commons/ImageItem/ImageItem";
import { Loader } from "../../commons/Loader";
import { NoDataFound } from "../../commons/NoDataFound";

interface Props {
  data: IImageInfo[];
  count: number;
  isLoadMore: boolean;
  onLoadMore: () => void;
  setListHashtag?: React.Dispatch<React.SetStateAction<string[]>>;
  listHashtag?: string[];
}

const ImageBox: React.FC<Props> = (props) => {
  const { data, count, isLoadMore, onLoadMore, setListHashtag, listHashtag } = props;
  return (
    <div className="w-full text-center">
      <>
        {data.length > 0 ? (
          <div
            className="
            grid 
            gap-x-4 
            gap-y-4 
            md:gap-x-3 
            md:gap-y-3 
            lg:gap-x-4 
            lg:gap-y-4 

            2xl:grid-cols-4 
            lg:grid-cols-3
            sm:grid-cols-2

            mt-5
            sm:mt-5
            md:mt-9
            "
          >
            {data.map((item: IImageInfo, index) => (
              <ImageItem
                setListHashtag={setListHashtag}
                listHashtag={listHashtag}
                dataImage={item}
                key={index}
                type="homepage"
              />
            ))}
          </div>
        ) : (
          <div className="mt-[20px] w-4/6 mx-auto">
            <NoDataFound />
          </div>
        )}
      </>

      <>
        {data.length < count && (
          <div className="text-center mt-10 ">
            {isLoadMore ? (
              <Loader />
            ) : (
              <div className="flex justify-center items-center">
                <button
                  className="text-white px-[50px] py-[14px] text-lg font-black bg-primary rounded-rddefault flex items-center hover:bg-bgbtn transition-all"
                  onClick={onLoadMore}
                >
                  Load More
                  <span className="w-4 h-[14px] ml-[14px] mb-1">
                    <HiArrowRight />
                  </span>
                </button>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};

export default React.memo(ImageBox);
