import React, { memo, RefObject } from "react";
import { ReactSketchCanvas, ReactSketchCanvasRef } from "react-sketch-canvas";

interface CanvasPainterProps {
  style?: any;
  brushSize: number;
  canvasRef: RefObject<ReactSketchCanvasRef>;
  canvasRefHidden: RefObject<ReactSketchCanvasRef>;
  backgroundImage: string;
  editImgType: "add object" | "remove object" | undefined;
  width: number;
  height: number;
}

const CanvasPainter: React.FC<CanvasPainterProps> = (props) => {
  const { brushSize, canvasRef, canvasRefHidden, backgroundImage, editImgType, style, width, height } = props;

  const pointerCircleSvg = `<svg xmlns="http://www.w3.org/2000/svg"  fill="%23000000" opacity="0.3" height="${brushSize}" viewBox="0 0 ${brushSize} ${brushSize}" width="${brushSize}"><circle cx="${
    brushSize / 2
  }" cy="${brushSize / 2}" r="${brushSize / 2}" fill="%23000000" /></svg>`;

  const canvasStyle = {
    ...style,
    cursor: `url('data:image/svg+xml;utf8,${pointerCircleSvg}') ${brushSize / 2} ${brushSize / 2}, auto`,
  };
  return (
    <>
      <ReactSketchCanvas
        style={canvasStyle}
        className="mx-auto border-0 col-span-5 lg:col-span-2"
        ref={canvasRef}
        strokeColor={editImgType === "add object" ? "rgba(255,255,255, 0.5)" : "rgba(255,0,0, 0.3)"}
        width={width + "px"}
        height={height + "px"}
        strokeWidth={brushSize}
        backgroundImage={backgroundImage}
      />
      <ReactSketchCanvas
        className="mx-auto border-0 opacity-0 absolute top-0 z-minus999"
        ref={canvasRefHidden}
        width={width + "px"}
        height={height + "px"}
        strokeWidth={brushSize}
        canvasColor="#000000"
      />
    </>
  );
};

export default memo(CanvasPainter);
