interface Props {
  label?: string;
  onSort: (sort: string) => void;
  sortList: string[];
}

const SortBox: React.FC<Props> = ({ onSort, sortList, label }) => {
  const handleSelect = (e: any) => {
    onSort(e.target.value);
  };

  return (
    <div>
      <label className="font-bold text-tprimary">{label || "Sort by:"}</label>
      <ul onChange={handleSelect} className="mt-2">
        {sortList.map((s, i) => {
          return (
            <li key={i}>
              <label className="label cursor-pointer justify-start py-1">
                <input
                  type="radio"
                  name="sort"
                  value={s}
                  className="radio checked:bg-primary border-bdcolor border-2"
                  defaultChecked={i === 0}
                />
                <span className="labelText ml-2 text-tprimary cursor-pointer">{s}</span>
              </label>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { SortBox };
