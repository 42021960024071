export const SOCKET_EVENT = {
  CONNECTION: "connection",
  INITIAL_NOTIFICATION: "initialNoti",
  MARK_IS_READ_NOTIFICATION: "markIsReadNoti",
  MARK_IS_READ_ALL_NOTIFICATION: "markAllReadNotification",
  GENERATE_IMAGE: "generatedImage",
  EDIT_IMAGE: "editImage",
  DISCONNECT: "disconnect",
  UPDATE_NOTIFICATION: "updateNotification",
  GET_ALL_NOTIFICATIONS: "getAllNotificationUser",
  GENERATE_RESULT: "generateResult",
};
