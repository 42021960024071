import axios from "axios";
import { COMMON } from "../constants";
import { LOCAL_STORAGE_TOKEN_NAME } from "../constants/common.constants";
export const BASE_URL = process.env.REACT_APP_BE_URL;
export const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-type": "application/json",
  },
});
// set interceptors requests
axiosClient.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(COMMON.LOCAL_STORAGE_TOKEN_NAME)?.replace(/"/g, "");
    if (token && config.headers) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      if (response.data?.data?.accessToken) {
        localStorage.setItem(LOCAL_STORAGE_TOKEN_NAME, JSON.stringify(response.data.data.accessToken));
      }
    }
    // return response;
    return response.data;
  },
  (error) => {
    // Handle errors
    throw error;
  },
);
