import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { userApis } from "../../../apis/auth";
import { imagesCommon } from "../../../assets/images";
import { InputPassword, MessageError } from "../../../components";
import { useToast } from "../../../hooks/useToast/useToast";
import CatchError from "../../../utils/catch-error";
import { REGEX_PWD } from "../../../utils/regex";

export type FormDataLogin = {
  secretCode: string;
  newPassword: string;
};

const schema = yup.object().shape({
  secretCode: yup.string().required("Secret code is required").min(8, "Minimum of 8 characters"),
  newPassword: yup
    .string()
    .required("This field is required")
    .matches(REGEX_PWD, "Only use letters, numbers, and common punctuation characters")
    .min(8, "Minimum of 8 characters"),
});

export const FormCheckAndUpdatePwd: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [isDisable, setIsDisable] = useState<boolean>(false);
  const navigate = useNavigate();
  const { toast } = useToast();
  const [counter, setCounter] = useState<number>(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataLogin>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    checkCoundownTimeResendCode();
    return () => {
      clearInterval(timer as unknown as number);
    };
  }, [counter]);

  const checkCoundownTimeResendCode = () => {
    if (counter === 0) {
      setIsDisable(false);
    }
  };

  const handleResendCode = async () => {
    try {
      await userApis.resetPassword({ email: searchParams && (searchParams.get("email") as string) });
      toast.success("Resend code successfully!");
      setIsDisable(true);
      setCounter(30);
    } catch (error) {
      toast.error(CatchError(error));
    }
  };

  const onSubmit = async (data: FormDataLogin) => {
    setLoading(true);
    try {
      await userApis.checkAndUpdatePassword(searchParams && (searchParams.get("email") as string), data);
      toast.success("Your password has been reset successfully!");
      navigate("/sign-in");
    } catch (error) {
      toast.error(CatchError(error));
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <h2 className="card-title justify-center !mb-9 text-tsecondary text-[32px] font-black">
        Request sent successfully!
      </h2>
      <p className="text-ttitle font-normal text-center sm:text-base lg:text-lg sm:px-2 lg:px-4 mb-2">
        We've sent a secret code confirmation email to your email. Please enter the code in below box to verify your
        email.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="flex flex-col">
        <div className="relative">
          <img
            className="absolute left-4 top-1/2 -translate-y-1/2 object-cover w-5 h-4 "
            src={imagesCommon.emailIcon}
            alt="email"
          />
          <input
            type="text"
            disabled
            className={`input w-full focus:outline-none bg-bggrayprimary border border-solid border-bdinput rounded-rddefault text-tsecondary pl-12 disabled:opacity-75 disabled:text-black`}
            placeholder="Email"
            defaultValue={searchParams && (searchParams.get("email") as string)}
          />
        </div>
        <div className="relative mt-5">
          <img
            className="absolute left-4 top-1/2 -translate-y-1/2 object-contain w-5 h-5"
            src={imagesCommon.passwordIcon}
            alt="secretCode"
          />
          <input
            {...register("secretCode")}
            type="text"
            autoComplete="off"
            placeholder="Secret Code*"
            className={`input w-full focus:outline-none bg-bggrayprimary border border-solid border-bdinput rounded-rddefault text-tsecondary pl-12 ${
              errors.secretCode && "border-red-600"
            }`}
          />
        </div>
        {errors.secretCode && <MessageError message={errors.secretCode.message} />}

        <InputPassword
          inputAttribute={register("newPassword")}
          placeholder="New Password*"
          error={errors?.newPassword}
        />
        {errors.newPassword && <MessageError message={errors.newPassword.message} />}

        <button
          type="submit"
          disabled={loading}
          className={`btn bg-primary hover:bg-bgbtn text-white mt-5 mb-3 focus:outline-none  ${
            loading && "loading"
          } hover:border-none`}
        >
          {loading || "Update Password"}
        </button>

        <p className="text-center text-tplaceholder">
          Don’t have a code?{" "}
          <span
            onClick={handleResendCode}
            className={`text-primary hover:text-bgbtn transition cursor-pointer ${
              isDisable && "pointer-events-none text-gray-600"
            }`}
          >
            Resend code
          </span>
        </p>
        {isDisable && <p className="text-center text-sm text-red-600 mt-2">{`${counter} seconds`}</p>}
        <div className="divider after:bg-bdcolor before:bg-bdcolor"></div>

        <div className="flex items-center justify-center group">
          <BsArrowLeftShort className="text-primary group-hover:text-bgbtn transition text-lg" />
          <Link className="text-primary group-hover:text-bgbtn transition" to="/sign-in">
            Return to sign in
          </Link>
        </div>
      </form>
    </>
  );
};
