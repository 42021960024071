import { axiosClient } from "./apis/axiosClient";
import { COMMON } from "./constants";
import Router from "./routes";
import "./variables.scss";

axiosClient.defaults.headers.common = {
  Authorization: `Bearer ${localStorage.getItem(COMMON.LOCAL_STORAGE_TOKEN_NAME)}`,
};

function App() {
  return <Router />;
}

export default App;
