import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCommonContext } from "../../contexts/commonContext";
import { useModalContext } from "../../contexts/modalContext";
import { IProfileAction } from "../../types/user";
import { PackageUpgrade } from "./PackageUpgrade";
import { ModalBankTransfer } from "./payment/ModalBankTransfer/ModalBankTransfer";
import { ModalUploadImageConfirm } from "./payment/ModalBankTransfer/ModalUploadImageConfirm";
import { ModalCreditCard } from "./payment/ModalCreditCard";
import { ModalPayment } from "./payment/ModalPayment";
import { ModalVNPay } from "./payment/ModalVNPay";
import { ModalWaiting } from "./payment/ModalWaiting";

export type PackageAllDataType = {
  id: string;
  title: string;
  content: [];
  level: number;
  numberOfExpDays: number;
  timesGenPerDay: number;
  timesEditPerDay: number;
  totalPrice: number;
  originalPrice: number;
  adopterPrice: number;
  sale?: number;
  isHidden: boolean;
  typePackage: number;
};

interface Props {
  packageData: any;
  userFetch?: any;
}

export const Upgrade: React.FC<Props> = ({ packageData }) => {
  const { setProfileAction, user } = useCommonContext();
  const { modalWaiting, modalUploadImageConfirm, modalBankTransfer, modalVNPay } = useModalContext();
  const [levelCurrentPackageUser, setLevelCurrentPackageUser] = useState<number>(-1);
  const navigate = useNavigate();

  useEffect(() => {
    const currentPackage = packageData?.find(
      (item: PackageAllDataType) => item.id === user.userInfo?.userPackage?.packageId,
    );
    if (currentPackage) {
      setLevelCurrentPackageUser(currentPackage.level);
    }
  }, [user.userInfo, packageData]);

  useEffect(() => {
    if (user.userInfo) {
      if (!user.userInfo.isProvidedPassword) {
        navigate("/profile");
        setProfileAction(IProfileAction.changePassword);
      }
    }
  }, [user.userInfo]);

  return (
    <section>
      <div className="card w-full md:bg-bggrayprimary bg-transparent md:px-8 md:py-[30px] flex-row justify-center">
        <div className="grid grid-cols-12 2xl:grid-cols-5 gap-x-10 gap-y-3">
          {packageData
            ?.filter((item: PackageAllDataType) => item.isHidden !== true)
            ?.map((item: PackageAllDataType) => (
              <PackageUpgrade key={item.id} packageItem={item} levelCurrentPackageUser={levelCurrentPackageUser} />
            ))}
        </div>
      </div>
      <ModalPayment />
      <ModalCreditCard />
      {modalVNPay && <ModalVNPay />}
      {modalBankTransfer && <ModalBankTransfer />}
      {modalWaiting && <ModalWaiting />}
      {modalUploadImageConfirm && <ModalUploadImageConfirm />}
    </section>
  );
};
