import React from "react";
import backImage from "../../assets/images/BackImage.svg";
import { ART_FORM, SELECT_IMAGE } from "../../constants";
import { CollapseBtn } from "./CollapseBtn";
export interface RightFormArt {
  collapes: string | boolean;
  setCollapes(e: string): void;
  items: string[];
  handlerChangeItem(i: string): void;
}
export const StylesArt: React.FC<RightFormArt> = ({ collapes, setCollapes, items, handlerChangeItem }) => {
  return (
    <section className="mt-4">
      {collapes === "" ? (
        <>
          {ART_FORM.rightArtComponent.map((items, index) => {
            return (
              <div key={index + items.label}>
                <div onClick={() => setCollapes(items?.value)}>
                  <div className="cursor-pointer mb-3 border-solid justify-between border-[#667085] border-[1px] h-[48px] rounded-xl px-2  w-full flex items-center">
                    {items?.label}
                    <img className="rotate-180" src={backImage} alt="" />
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {collapes === ART_FORM.COLLAPSE.STYLES && (
            <CollapseBtn
              items={items}
              setCollapes={setCollapes}
              handlerChangeItem={handlerChangeItem}
              title={ART_FORM.COLLAPSE.STYLES}
              listItemCollapse={SELECT_IMAGE.optionItemStyles}
            />
          )}
          {collapes === ART_FORM.COLLAPSE.ARTISTS && (
            <CollapseBtn
              items={items}
              setCollapes={setCollapes}
              handlerChangeItem={handlerChangeItem}
              title={ART_FORM.COLLAPSE.ARTISTS}
              listItemCollapse={ART_FORM.listArtist}
            />
          )}
          {collapes === ART_FORM.COLLAPSE.OTHERS && (
            <CollapseBtn
              items={items}
              setCollapes={setCollapes}
              handlerChangeItem={handlerChangeItem}
              title={ART_FORM.COLLAPSE.OTHERS}
              listItemCollapse={ART_FORM.listOther}
            />
          )}
        </>
      )}
    </section>
  );
};
