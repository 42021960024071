import classname from "classnames";
import { imagesPayment } from "../../../../assets/images";
import { useModalContext } from "../../../../contexts/modalContext";

export const ModalBankTransfer: React.FC = () => {
  const { modalBankTransfer, packageUpgrade, setModalBankTransfer, setRandomCode, setModalUploadImageConfirm } =
    useModalContext();
  const codeRandom = Math.floor(100000 + Math.random() * 900000);

  const handleNext = async () => {
    setModalBankTransfer(false);
    setModalUploadImageConfirm(true);
    setRandomCode(codeRandom);
  };

  return (
    <>
      <div className={classname("modal w-full", modalBankTransfer && "modal-open")}>
        <div className="modal-box md:min-w-[700px] bg-[#1D2939] py-[30px] rounded-rddefault overflow-auto scrollbar w-full overflow-x-hidden mx-3">
          <h2 className="font-bold text-2xl text-tsecondary text-center mb-6">Bank Transfer</h2>
          <div className="grid grid-cols-12 md:gap-x-[50px] mb-10">
            <div className="col-span-12 md:col-span-6">
              <div className="mb-4">
                <p className="text-tlabel font-bold text-lg mb-1">Bank Name</p>
                <span className="text-ttitle font-normal text-lg">tpBank</span>
              </div>
              <div className="mb-4">
                <p className="text-tlabel font-bold text-lg mb-1">Account Number</p>
                <span className="text-ttitle font-normal text-lg">0129 4627 001</span>
              </div>
              <div className="mb-4">
                <p className="text-tlabel font-bold text-lg mb-1">Account Name</p>
                <span className="text-ttitle font-normal text-lg">Vo Duy Khanh</span>
              </div>
              <div className="mb-4">
                <p className="text-tlabel font-bold text-lg mb-1">{`Payment for ${packageUpgrade?.title}`}</p>
                <span className="text-ttitle font-normal text-lg">{`${
                  packageUpgrade &&
                  Math.floor(packageUpgrade?.totalPrice).toLocaleString("vi", {
                    style: "currency",
                    currency: "VND",
                  })
                }`}</span>
              </div>
              <div className="mb-4">
                <p className="text-tlabel font-bold text-lg mb-1">Content Transfer</p>
                <span className="text-ttitle font-normal text-lg">{codeRandom}</span>
              </div>
            </div>
            <div className="col-span-12 md:col-span-6">
              <div className="bg-[#333e4c] px-[37px] py-[25px] rounded-rddefault">
                <div className="flex items-center justify-center mb-5">
                  <img className="w-12 h-12 object-contain" src={imagesPayment.tpBank} alt="bank" />
                  <h2 className="font-black text-lg text-white ml-2">tpBank</h2>
                </div>
                <img className="w-full h-full object-cover" src={imagesPayment.qrCode} alt="QR" />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <button
              onClick={() => setModalBankTransfer(false)}
              className="btn md:w-[240px] border border-bdinput border-solid px-10 py-3 text-base font-black self-center capitalize bg-[#1D2939] mr-7 text-[#D0D5DD] transition-all hover:border-primary hover:bg-[#1D2939]"
            >
              Cancel
            </button>
            <button
              onClick={handleNext}
              className="btn md:w-[240px] px-10 py-3 text-base font-black self-center capitalize bg-primary text-white transition-all hover:bg-blue-600 border border-primary hover:border-solid hover:border-bggrayprimary"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
