import * as yup from "yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { BsArrowLeftShort } from "react-icons/bs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { userApis } from "../../../apis/auth";
import { imagesCommon } from "../../../assets/images";
import { MessageError } from "../../../components";
import { useToast } from "../../../hooks/useToast/useToast";
import CatchError from "../../../utils/catch-error";

type Props = {
  setIsSendSecretCode: React.Dispatch<React.SetStateAction<boolean>>;
};

export type FormDataLogin = {
  email: string;
};

const schema = yup.object().shape({
  email: yup.string().required("Email is required").email("Email is not valid"),
});

export const FormVerifyEmail: React.FC<Props> = ({ setIsSendSecretCode }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>();
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataLogin>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormDataLogin) => {
    setLoading(true);
    try {
      await userApis.resetPassword(data);
      setIsSendSecretCode(true);
      navigate(`/reset-password?email=${data.email}`);
    } catch (error) {
      toast.error(CatchError(error));
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <h2 className="card-title justify-center !mb-9 text-tsecondary text-[32px] font-black">Reset Password</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
        <div className="relative">
          <img
            className="absolute left-4 top-1/2 -translate-y-1/2 object-cover w-5 h-4 "
            src={imagesCommon.emailIcon}
            alt="email"
          />
          <input
            {...register("email")}
            type="text"
            placeholder="Email*"
            defaultValue={searchParams && (searchParams.get("email") as string)}
            className={`input w-full focus:outline-none bg-bggrayprimary border border-solid border-bdinput rounded-rddefault text-tsecondary pl-12 ${
              errors.email && "border-red-600"
            }`}
          />
        </div>
        {errors.email && <MessageError message={errors.email.message} />}

        <button
          type="submit"
          disabled={loading}
          className={`btn bg-blue-700 hover:bg-blue-800 text-white mt-5 mb-3 focus:outline-none  ${
            loading && "loading"
          } hover:border-none`}
        >
          {loading || "Reset Password"}
        </button>

        <div className="divider after:bg-bdcolor before:bg-bdcolor"></div>

        <div className="flex items-center justify-center group">
          <BsArrowLeftShort className="text-primary group-hover:text-bgbtn transition text-lg" />
          <Link className="text-primary group-hover:text-bgbtn transition" to="/sign-in">
            Return to sign in
          </Link>
        </div>
      </form>
    </>
  );
};
