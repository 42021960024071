import React, { ChangeEvent, memo, RefObject, useCallback, useRef, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ReactSketchCanvasRef } from "react-sketch-canvas";
import { GENERATE } from "../../../constants";
import { useGenerateImage } from "../../../contexts/generateImageContext";
import outSideClick from "../../../hooks/outSideClick";
import ButtonsEditImg from "./ActionBtnEdit";
import CanvasPainter from "./CanvasPainter";
import InputAddObject from "./InputAddObject";

interface ModalEditImageProps {
  onClickOutside: () => void;
  onChangeBrushSize: (event: ChangeEvent<HTMLInputElement>) => void;
  hiddenSubmitEdit: boolean;
  canvasRef: RefObject<ReactSketchCanvasRef>;
  canvasRefHidden: RefObject<ReactSketchCanvasRef>;
  brushSize: number;
  handleEditImage: (content: string) => void;
  backgroundImage: string;
  imgSize: {
    width: number;
    height: number;
  };
}
const ModalEditImage: React.FC<ModalEditImageProps> = memo((props) => {
  const {
    onClickOutside,
    onChangeBrushSize,
    hiddenSubmitEdit,
    canvasRef,
    canvasRefHidden,
    brushSize,
    handleEditImage,
    backgroundImage,
    imgSize,
  } = props;
  const { editType } = useGenerateImage();
  const [addContent, setAddContent] = useState<string>("");
  const [validAddContent, setValidAddContent] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeInputAddObject = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setAddContent(e.target.value);
    setValidAddContent(true);
  }, []);

  const handleClickOutside = () => {
    onClickOutside();
  };
  const content = outSideClick(handleClickOutside);

  const icons = [
    <React.Fragment />,
    <button
      onClick={handleClickOutside}
      className="w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] cursor-pointer
    hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120"
    >
      <AiOutlineCloseCircle />
    </button>,
  ];

  const handleAction = useCallback(
    async (type?: string) => {
      if (type) {
        switch (type) {
          case "Submit":
            if (editType === GENERATE.TYPE_EDIT.ADD && addContent === "") {
              setValidAddContent(false);
              inputRef.current?.focus();
              return;
            }
            handleEditImage(addContent);
            break;
          case "Undo":
            canvasRef.current?.undo();
            canvasRefHidden.current?.undo();
            break;
          case "Redo":
            canvasRef.current?.redo();
            canvasRefHidden.current?.redo();
            break;
          case "Clear":
            canvasRef.current?.clearCanvas();
            canvasRefHidden.current?.clearCanvas();
            break;
          default:
            break;
        }
      }
    },
    [canvasRef, canvasRefHidden, inputRef, addContent, handleEditImage],
  );

  return (
    <div
      className="flex justify-center items-center select-none 
    fixed top-0 left-0 bottom-0 right-0 w-full h-full bg-[#f7f7f780] z-[999]"
    >
      <div
        ref={content}
        className="inline-flex flex-col justify-center relative rounded-[10px] shadow-lg shadow-bggray max-w-full max-h-full"
      >
        <div className="flex justify-between bg-bggray py-[5px] px-[10px] rounded-tl-[10px] rounded-tr-[10px]">
          {icons.map((icon, index) => {
            return <span key={index}>{icon}</span>;
          })}
        </div>

        <div className="inline-block relative overflow-hidden">
          <CanvasPainter
            brushSize={brushSize}
            canvasRef={canvasRef}
            canvasRefHidden={canvasRefHidden}
            backgroundImage={backgroundImage}
            editImgType={"add object"}
            width={imgSize.width}
            height={imgSize.height}
          />
        </div>

        <div className="bg-bggray flex flex-col items-start p-[20px] rounded-bl-[10px] rounded-br-[10px] select-text">
          {editType === "add object" && (
            <InputAddObject
              validAddContent={validAddContent}
              inputRef={inputRef}
              addContent={addContent}
              handleChangeInputAddObject={handleChangeInputAddObject}
            />
          )}

          <ButtonsEditImg
            onChangeBrushSize={onChangeBrushSize}
            handleAction={handleAction}
            hiddenSubmitEdit={hiddenSubmitEdit}
          />
        </div>
      </div>
    </div>
  );
});
export { ModalEditImage };
