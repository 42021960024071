// Handle display format day dd/mm/yyyy

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  let month = (date.getMonth() + 1) as unknown as string;
  if (month < "10") month = `0${month}`;
  let day = date.getDate() as unknown as string;
  if (day < "10") day = `0${day}`;

  return `${day}/${month}/${year}`;
};
