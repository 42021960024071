import classNames from "classnames";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { paymentApis } from "../../apis/payment";
import { imagesCommon } from "../../assets/images";
import { LEVEL_PACKAGE } from "../../constants/package.constants";
import { useCommonContext } from "../../contexts/commonContext";
import { useModalContext } from "../../contexts/modalContext";
import { useToast } from "../../hooks/useToast/useToast";
import CatchError from "../../utils/catch-error";
import { formatDate } from "../../utils/fomat-day";
import { PackageAllDataType } from "./Upgrade";

interface Props {
  packageItem: PackageAllDataType;
  levelCurrentPackageUser: number;
}

export const PackageUpgrade: React.FC<Props> = ({ packageItem, levelCurrentPackageUser }) => {
  const { setModalPayment, setPackageUpgrade } = useModalContext();
  const { user } = useCommonContext();
  const { userInfo } = user;
  const expiredDays = new Date(userInfo?.userPackage?.expiredDays as string);
  const { toast } = useToast();
  const navigate = useNavigate();

  const handlePayment = async () => {
    if (userInfo) {
      try {
        const response: any = await paymentApis.getStatusPayment();
        if (response?.success) {
          setModalPayment(true);
          setPackageUpgrade(packageItem);
        }
      } catch (error: any) {
        toast.error(CatchError(error));
      }
    } else {
      navigate("/sign-in");
    }
  };

  const renderEarlyAdopterPackagePrice = useMemo(
    () => (
      <span className="font-medium text-2xl text-center text-tsecondary mb-[10px] flex-grow-1 line-through">
        {packageItem?.level !== LEVEL_PACKAGE.CUSTOM &&
          packageItem?.level !== LEVEL_PACKAGE.FREE &&
          Math.floor(packageItem?.originalPrice).toLocaleString("vi", {
            style: "currency",
            currency: "VND",
          })}
      </span>
    ),
    [packageItem?.level, packageItem?.originalPrice],
  );

  const renderPackagePriceCommon = useMemo(
    () =>
      packageItem?.level !== LEVEL_PACKAGE.CUSTOM &&
      Math.floor(packageItem?.totalPrice).toLocaleString("vi", {
        style: "currency",
        currency: "VND",
      }),
    [packageItem?.level, packageItem?.totalPrice],
  );

  const renderExpirationDate = useMemo(
    () =>
      packageItem?.level !== LEVEL_PACKAGE.FREE &&
      packageItem?.id === userInfo?.userPackage.packageId &&
      `Expiration date: ${formatDate(expiredDays)}`,
    [packageItem?.level, packageItem?.id],
  );

  const renderPackageContent = useMemo(
    () =>
      packageItem?.content.map((ct, index) => {
        return (
          <div key={index} className="flex items-start mb-[15px]">
            <img className="w-5 h-5 mr-2" src={imagesCommon.checkCircle} alt="check" />
            <p className="text-sm lg:text-sm font-normal text-tsecondary">{`${ct}`}</p>
          </div>
        );
      }),
    [packageItem?.content],
  );

  return (
    <div className="col-span-12 md:col-span-6 2xl:col-span-1 flex justify-center">
      <div
        className={classNames(
          "card sm:w-full border bg-bggrayprimary border-solid border-[#667085] items-center w-[340px] md:min-h-[460px] hover:border-primary transition-all",
          packageItem?.id === userInfo?.userPackage.packageId && "!border-primary",
        )}
      >
        <h2 className="card-title text-tsecondary text-sm lg:text-base justify-center font-black pt-5 pb-4 text-center border-b border-solid border-[#667085] mb-[15px] w-full relative before:absolute before:h-[2px] before:w-10 before:-bottom-[1px] before:bg-primary">
          {packageItem?.title}
        </h2>
        <div className="card-body flex flex-col px-[15px] h-full">
          {userInfo?.isEarlyAdopter && renderEarlyAdopterPackagePrice}
          <span className="font-bold text-3xl text-center text-tsecondary mb-[10px] flex-grow-1">
            {renderPackagePriceCommon}
          </span>
          <p className="text-xs min-h-[16px] lg:text-xs font-normal text-[#F75E5E] text-center flex-grow-0">
            {renderExpirationDate}
          </p>
          <p className="text-xs min-h-[16px] lg:text-xs font-normal text-[#F75E5E] text-center flex-grow-0">
            {packageItem?.id === userInfo?.userPackage.packageId &&
              `Number of edit remaining: ${userInfo?.userPackage.timesEditLeftOnDay}`}
          </p>
          <p className="text-xs min-h-[16px] lg:text-xs font-normal text-[#F75E5E] text-center flex-grow-0">
            {packageItem?.id === userInfo?.userPackage.packageId &&
              `Number of generate remaining: ${userInfo?.userPackage.timesGenerateLeftOnDay}`}
          </p>
          <div className="flex flex-col justify-start flex-grow-[2]">{renderPackageContent}</div>
          <button
            onClick={() => (packageItem?.level === LEVEL_PACKAGE.CUSTOM ? navigate("/contact") : handlePayment())}
            className={classNames(
              "btn bg-bggrayprimary border border-primary border-solid px-10 py-3 text-base flex-grow-1 font-black self-center capitalize text-primary hover:bg-primary hover:text-white transition-all",
              packageItem?.level > levelCurrentPackageUser || "bg-bgdark pointer-events-none border-bgdark",
            )}
          >
            {packageItem?.level === LEVEL_PACKAGE.CUSTOM ? "Contact Us" : "Get Started"}
          </button>
        </div>
      </div>
    </div>
  );
};
