import { useEffect, useRef, useState } from "react";
import {
  AiOutlineCloseCircle,
  AiOutlineRotateLeft,
  AiOutlineRotateRight,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from "react-icons/ai";
import { MdContentPaste, MdDateRange, MdOutlineFavorite } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import generateImgApis from "../../apis/generateImage";
import noneAvatar from "../../assets/images/none_avatar.png";
import { useToast } from "../../hooks/useToast/useToast";
import { IDetailImage } from "../../types/my-images";
// import { HideLoader, ShowLoader } from "../../redux/reducers/LoadingSlice";
import { Helmet } from "react-helmet";
import CatchError from "../../utils/catch-error";
import Notfound from "../404";

export const DetailImage = () => {
  const [detailImage, setDetailImage] = useState<IDetailImage | null>(null);
  const [paramsError, setParamsError] = useState<boolean>(false);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);

  const content: any = useRef(null);
  const modalRefLeft: any = useRef(null);
  const modalRefRight: any = useRef(null);

  const params = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();

  const timeCreated = detailImage && new Date(detailImage?.createdAt);
  const year = timeCreated && timeCreated.getFullYear();
  const month = timeCreated && timeCreated.getMonth() + 1;
  const date = timeCreated && timeCreated.getDate();

  useEffect(() => {
    getDetailImage();
  }, [params.id]);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (
        content.current &&
        !content?.current?.contains(event.target) &&
        modalRefLeft.current &&
        !modalRefLeft?.current?.contains(event.target) &&
        modalRefRight.current &&
        !modalRefRight?.current?.contains(event.target)
      ) {
        handleClickOutside();
      }
    };

    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, []);

  const getDetailImage = async () => {
    try {
      // dispatch(ShowLoader());
      const response = (await generateImgApis.getDetailImage(params.id)) as any;
      setDetailImage(response.data);
      setParamsError(response.success);
    } catch (error: any) {
      toast.error(CatchError(error));
    } finally {
      // dispatch(HideLoader());
    }
  };

  const handleClickOutside = () => {
    navigate("/");
  };

  const icons = [
    <button
      onClick={() => setRotate((v) => v - 90)}
      className="w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] cursor-pointer
       hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120"
    >
      <AiOutlineRotateLeft />
    </button>,
    <button
      onClick={() => setRotate((v) => v + 90)}
      className="w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] cursor-pointer
       hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120"
    >
      <AiOutlineRotateRight />
    </button>,
    <button
      onClick={() => setScale((v) => v + 1)}
      className="w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] cursor-pointer
       hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120"
    >
      <AiOutlineZoomIn />
    </button>,
    <button
      disabled={scale < 2}
      className={`${
        scale < 2 && "opacity-50 cursor-no-drop"
      } w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] cursor-pointer
       hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120`}
      onClick={() => setScale((v) => v - 1)}
    >
      <AiOutlineZoomOut />
    </button>,

    <button
      onClick={handleClickOutside}
      className="w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] cursor-pointer
       hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120"
    >
      <AiOutlineCloseCircle />
    </button>,
  ];

  if (!paramsError) return <Notfound />;

  return (
    <>
      <Helmet>
        <meta property="og:title" content="DopikAI-Sotatek" />
        <meta property="og:description" content={detailImage?.prompt} />
        <meta property="og:image" content={detailImage?.imageUrl} />
      </Helmet>
      <div className="flex justify-center items-center select-none fixed top-0 w-full h-full z-[999] bg-[#f7f7f780]">
        <div
          ref={content}
          className="inline-flex flex-col relative rounded-[10px] shadow-lg shadow-bggray 
          min-w-[350px] max-w-[100%] max-h-[100%]"
        >
          <div
            className="flex justify-between items-center bg-bggray 
            text-[#fff]  py-[5px] px-[10px] rounded-tl-[10px] rounded-tr-[10px]"
          >
            {icons.map((icon, index) => {
              return <span key={index}>{icon}</span>;
            })}
          </div>

          <div className="inline-block relative overflow-hidden">
            <img
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
              }}
              className="object-contain mx-auto duration-300 w-full"
              src={detailImage?.imageUrl}
              alt="..."
            />
            <div className=" absolute w-full flex items-center bottom-[7px] justify-between px-[14px]">
              <div className=" flex items-center">
                <div
                  className="w-[50px] h-[50px] border-[1px] border-solid 
                  border-[#f1f1f2] rounded-[50%]"
                >
                  <img
                    className="w-full h-full  rounded-[50%] object-cover"
                    src={(detailImage && detailImage.owner.thumbnailUrl) || noneAvatar}
                    alt="..."
                  />
                </div>
                <div className="text-[#fff] font-medium drop-shadow-lg ml-2">{detailImage?.owner.name}</div>
              </div>

              <div className="flex items-center justify-end ">
                <span className="flex items-center text-[30px] text-red-500">
                  <MdOutlineFavorite />
                </span>
                <span className="ml-2 text-[#fff] font-medium">{detailImage?.numberOfLike}</span>
              </div>
            </div>
          </div>

          <div className="bg-bggray flex flex-col items-start p-[20px] rounded-bl-[10px] rounded-br-[10px]">
            <div className="flex items-center text-[#fff] overflow-hidden w-full">
              <MdContentPaste />
              <div className="ml-2 flex-1 w-min overflow-hidden text-ellipsis">{detailImage?.prompt}</div>
            </div>
            <div className="flex items-center text-[#fff] overflow-hidden w-full">
              <MdDateRange />
              <div className="ml-2"> {`${date} / ${month} / ${year}`}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
