import { gapi } from "gapi-script";
import { useEffect } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { FacebookIcon } from "react-share";
import { imagesCommon } from "../../../assets/images";
import { useCommonContext } from "../../../contexts/commonContext";
import { FormDataLogin } from "./SignIn";
import { useNavigate } from "react-router-dom";

export const SocialNetwork = () => {
  const { userLogin } = useCommonContext();
  const navigate = useNavigate();
  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "",
        clientSecret: process.env.REACT_APP_GOOGLE_CLIENT_SECRECT,
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  const responseGoogle = async (res: any) => {
    const payload: FormDataLogin = {
      email: res.profileObj.email,
      name: res.profileObj.name,
      googleId: res.profileObj.googleId,
    };
    userLogin(payload, () => navigate(0));
  };

  const responseFacebook = (res: any) => {
    const payload: FormDataLogin = {
      email: res.email,
      name: res.name,
      facebookId: res.userID,
    };
    userLogin(payload, () => navigate(0));
  };

  return (
    <>
      <div className="flex justify-center mt-2 mb-3">
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
          autoLoad={false}
          fields="name,email,picture"
          callback={responseFacebook}
          render={(renderProps: any) => (
            <>
              <span onClick={renderProps.onClick} className="text-lg mr-2 cursor-pointer">
                <FacebookIcon className="rounded-full text-base" />
              </span>
            </>
          )}
        />
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
          buttonText="Login"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
          render={(renderProps: any) => (
            <>
              <span onClick={renderProps.onClick} className="text-lg mr-2 cursor-pointer">
                <img className="w-16 h-16" src={imagesCommon.googleIcon} alt="google" />
              </span>
            </>
          )}
        />
      </div>
    </>
  );
};
