import { GENERATE } from "../../constants";
import { useGenerateImage } from "../../contexts/generateImageContext";
import { useModalContext } from "../../contexts/modalContext";
import { ModalComponent } from "../commons/Modal";
const ModalChooseEditOptions: React.FC = () => {
  const { setModalChooseOptionEdit, setModalEditImage } = useModalContext();
  const { setEditType } = useGenerateImage();
  return (
    <ModalComponent
      title="Please choose action"
      btnText="Cancel"
      onClose={() => setModalChooseOptionEdit(false)}
      btnActionName={[GENERATE.TYPE_EDIT.ADD, GENERATE.TYPE_EDIT.REMOVE]}
      btnActionClick={(typeEdit: string) => {
        setModalChooseOptionEdit(false);
        setEditType(typeEdit);
        setModalEditImage(true);
      }}
    />
  );
};

export { ModalChooseEditOptions };
