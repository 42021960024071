import React, { ChangeEvent, memo, RefObject } from "react";
import cn from "classnames";

interface InputAddObjectProps {
  validAddContent: boolean;
  inputRef: RefObject<HTMLInputElement>;
  addContent: string;
  handleChangeInputAddObject: (e: ChangeEvent<HTMLInputElement>) => void;
}

const InputAddObject: React.FC<InputAddObjectProps> = (props) => {
  const { validAddContent, inputRef, addContent, handleChangeInputAddObject } = props;

  return (
    <>
      <input
        ref={inputRef}
        name="add-content"
        type="text"
        className={cn(
          "input input-bordered w-full bg-transparent border border-solid border-gray-500 text-tprimary mt-4 lg:mt-0",
          {
            "border-red-500": !validAddContent,
          },
        )}
        autoComplete="off"
        value={addContent}
        onChange={(e) => handleChangeInputAddObject(e)}
      />
      {!validAddContent && (
        <div className="text-left text-sm text-red-600 mt-4 rounded-lg font-bold">This field is required</div>
      )}
    </>
  );
};
export default memo(InputAddObject);
