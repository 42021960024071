import React, { ChangeEvent, memo } from "react";
import { CiUndo, CiRedo, CiTrash, CiCircleCheck } from "react-icons/ci";

interface ButtonsEditProps {
  onChangeBrushSize: (event: ChangeEvent<HTMLInputElement>) => void;
  handleAction: (type?: string) => void;
  hiddenSubmitEdit: boolean;
}
const ButtonsEditImg: React.FC<ButtonsEditProps> = (props) => {
  const { onChangeBrushSize, handleAction, hiddenSubmitEdit } = props;

  const buttonEditAction = [
    { label: "", action: "Undo", icon: <CiUndo size={20} className="ml-1" />, isHidden: false },
    { label: "", action: "Redo", icon: <CiRedo size={20} className="ml-1" />, isHidden: false },
    { label: "", action: "Clear", icon: <CiTrash size={20} className="ml-1" />, isHidden: false },
    {
      label: "Submit",
      action: "Submit",
      icon: <CiCircleCheck size={20} className="ml-1" />,
      isHidden: hiddenSubmitEdit,
    },
  ];

  return (
    <div className={`btn__action-edit-img flex justify-between rounded-lg flex-wrap flex-row mt-4 w-full`}>
      <div className="btn border border-solid border-gray-500 bg-transparent text-white hover:bg-primary hover:shadow-lg mb-1 md:mb-4 mr-2 flex-col">
        <div className="">
          <input className="w-[90px]" defaultValue="10" type="range" min="10" max="90" onChange={onChangeBrushSize} />
        </div>
      </div>
      {buttonEditAction.map((btn, index) => (
        <button
          key={index}
          className="btn border border-solid border-gray-500 bg-transparent text-white hover:bg-primary mb-1 md:mb-4 disabled:bg-gray-300 disabled:text-gray-500 disabled:border-none"
          onClick={() => handleAction(btn.action)}
          disabled={btn?.isHidden}
        >
          {btn.label} {btn.icon}
        </button>
      ))}
    </div>
  );
};
export default memo(ButtonsEditImg);
