import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { paymentApis } from "../apis/payment";
import { imagesPayment } from "../assets/images";
import { Loader } from "../components";
import { useCommonContext } from "../contexts/commonContext";
import { useToast } from "../hooks/useToast/useToast";

export const ResultPayment: React.FC = () => {
  const { user } = useCommonContext();
  const [isSuccess, setIsSuccess] = useState<boolean | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams();
  const { toast } = useToast();
  const navigate = useNavigate();

  const getResultPayment = async () => {
    try {
      setLoading(true);
      const response = await paymentApis.getResultPayment(params.id);
      if (response.data.rspCode === "00") {
        setIsSuccess(true);
      }
    } catch (error: any) {
      error?.message ? toast.error(error?.message) : toast.error("An error occurred, please try again!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!user.userInfo) {
      navigate("/");
    }
  });

  useEffect(() => {
    getResultPayment();
  }, []);

  if (loading)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <div className="flex justify-center flex-col items-center w-full h-full mt-24 mb-10">
      <img
        className="w-1/3 h-1/3 object-contain rounded-rddefault overflow-hidden"
        src={isSuccess ? imagesPayment.paymentSuccess : imagesPayment.paymentFail}
        alt="success"
      />
      <h3 className="font-bold text-tprimary active:bg-transparent text-lg sm:text-base lg:text-lg px-0 sm:px-2 lg:px-4 mt-4">
        {`${isSuccess ? "Your payment is successfull." : "Your payment is failed"}`}
      </h3>
      <Link
        to="/"
        className="btn bg-bgdark border border-primary border-solid px-10 py-3 text-base font-black self-center capitalize text-primary hover:border-primary hover:bg-primary hover:text-white transition-all mt-10"
      >
        Back to Homepage
      </Link>
    </div>
  );
};
