import React from "react";
import { RiCloseFill } from "react-icons/ri";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

interface Props {
  id: any;
}
const ShareSocial = (props: Props) => {
  const { id } = props;
  return (
    <div>
      <input type="checkbox" id={`${id}/social`} className="modal-toggle" />
      <div
        className={`modal w-[300px] h-[250px] absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] 
        rounded-lg bg-transparent flex justify-center items-center`}
      >
        <div className="modal-box rounded-lg p-0 relative overflow-visible">
          <div className="modal-action mt-1 absolute top-[-20px] right-[-10px]">
            <label
              htmlFor={`${id}/social`}
              className="p-1 border-2 border-solid border-[#e7e7e9] bg-gray-200 rounded-full mr-1 hover:bg-gray-300 transition-all"
            >
              <RiCloseFill className="text-lg" />
            </label>
          </div>
          <div className="flex items-center justify-around p-6">
            <FacebookShareButton
              url={`${process.env.REACT_APP_BE_URL}/api/image/share-detail-image/fb/${id}`}
              hashtag={"#dopikai_sotatek_works "}
            >
              <FacebookIcon className="w-9 h-9 rounded-full hover:scale-[1.1] transition-all" />
            </FacebookShareButton>

            <TwitterShareButton url={`${process.env.REACT_APP_BE_URL}/api/image/share-detail-image/twitter/${id}`}>
              <TwitterIcon className="w-9 h-9 rounded-full hover:scale-[1.1] transition-all" />
            </TwitterShareButton>

            <LinkedinShareButton url={`${process.env.REACT_APP_BE_URL}/api/image/share-detail-image/linkedIn/${id}`}>
              <LinkedinIcon className="w-9 h-9 rounded-full hover:scale-[1.1] transition-all" />
            </LinkedinShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ShareSocial };
