import { memo } from "react";
import { MdFileDownload, MdOutlineModeEdit, MdShare } from "react-icons/md";
import generateImgApis from "../../../apis/generateImage";
import { COMMON } from "../../../constants";
import { useCommonContext } from "../../../contexts/commonContext";
import { useGenerateImage } from "../../../contexts/generateImageContext";
import { useModalContext } from "../../../contexts/modalContext";
import { useToast } from "../../../hooks/useToast/useToast";
import { IImageInfo } from "../../../types/my-images";
import CatchError from "../../../utils/catch-error";
import { downloadImgToDevice, getBase64FromUrl } from "../../../utils/helpers";
import { Favorite } from "../../commons/Favorite";
import { ShareSocial } from "../../commons/ShareSocial";

interface Props {
  dataImage: IImageInfo;
  isPopup: boolean;
  onClickPopup?: () => void;
}

const ActionButton: React.FC<Props> = memo((props) => {
  const { setModalRequiredLogin, setModalChooseOptionEdit } = useModalContext();
  const { collectionImages, setCollectionImages, user } = useCommonContext();
  const { setImgEdit } = useGenerateImage();
  const { toast } = useToast();

  const handleEdit = async (imageId: string) => {
    if (!user.userInfo) {
      setModalRequiredLogin(true);
      return;
    }
    try {
      const times = await generateImgApis.checkEditTimes();
      if (times.status === COMMON.HTTP_STATUS.SUCCESS) {
        const base64 = await getBase64FromUrl(props.dataImage?.imageUrl);
        setImgEdit({
          imgID: imageId,
          styleImage: props.dataImage?.styleImage,
          prompt: props.dataImage?.prompt,
          hashtag:
            props.dataImage?.hashtagList && props.dataImage?.hashtagList.length > 0
              ? props.dataImage?.hashtagList?.join(" ")
              : "",
          url: base64 as string,
          like: false,
        });
        setModalChooseOptionEdit(true);
      }
    } catch (e: any) {
      toast.error(CatchError(e));
    }
  };

  const FavoriteButton = (value: any) => {
    return (
      <Favorite
        item={value.data}
        dataArray={collectionImages}
        toggleFavorite={(array: IImageInfo[]) => setCollectionImages(array)}
      />
    );
  };

  const DownloadButton = (data: any) => {
    return (
      <MdFileDownload
        onClick={() => downloadImgToDevice(data.data.imageUrl)}
        className="w-full h-full rounded-[50%] p-[5px]"
      />
    );
  };

  const EditButton = (data: any) => {
    return (
      <MdOutlineModeEdit
        onClick={() => {
          handleEdit(data.data?.id);
        }}
        className="w-full h-full rounded-[50%] p-[5px]"
      />
    );
  };

  const array = [FavoriteButton, DownloadButton, EditButton];

  return (
    <>
      {array.map((Action: any, index: number) => {
        return (
          <span
            className="w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-bggray flex justify-center items-center
            hover:opacity-70 hover:scale-[1.1] active:opacity-90 active:scale-[1.2] mx-[5px]"
            key={index}
          >
            <Action data={props.dataImage} />
          </span>
        );
      })}
      <span className="cursor-pointer">
        <label
          htmlFor={`${props.dataImage.id}/social`}
          className="w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-bggray flex justify-center items-center
          hover:opacity-70 hover:scale-[1.1] active:opacity-90 active:scale-[1.2] mx-[5px] "
          onClick={props.onClickPopup}
        >
          <MdShare />
        </label>
      </span>
      <>{props.isPopup && <ShareSocial id={props.dataImage.id} />}</>
    </>
  );
});

export { ActionButton };
