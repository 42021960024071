import backImage from "../../assets/images/BackImage.svg";
type ListItem = {
  name: string;
  img?: string;
};
type CollapseBtn = {
  title: string;
  listItemCollapse: ListItem[];
  setCollapes(e: string): void;
  items: string[];
  handlerChangeItem(i: string): void;
};
export const CollapseBtn: React.FC<CollapseBtn> = ({
  items,
  setCollapes,
  handlerChangeItem,
  title,
  listItemCollapse,
}) => {
  const onBackCollaps = () => {
    setCollapes("");
  };
  return (
    <>
      <div className="flex items-center mb-4 gap-2 cursor-pointer" onClick={onBackCollaps}>
        <img src={backImage} alt={title} />
        <div className="text-center font-black">{title}</div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        {listItemCollapse.map((item, index) => {
          return (
            <div className="flex flex-col" key={index}>
              <img
                onClick={() => handlerChangeItem(item.name)}
                key={index}
                className={`w-full h-[128px] object-cover cursor-pointer  border-4  hover:border-[#3E80FF]
            ${items?.includes(item.name) ? "border-[#3E80FF]" : "border-transparent"}
          `}
                src={item.img}
                alt=""
              />
              <p className="text-center">{item.name}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};
