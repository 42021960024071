import { imagesArt } from "./art";
import { imagesInterior, imagesInteriorBanner } from "./interior";
import { imagesPayment } from "./payment";
const imagesCommon = {
  aboutUs01: require("./about_us_01.png"),
  aboutUs02: require("./about_us_02.png"),
  aboutUs03: require("./about_us_03.png"),
  aboutUs04: require("./about_us_04.png"),
  aboutUs05: require("./about_us_05.png"),
  aboutUs06: require("./about_us_06.png"),
  backImage: require("./BackImage.svg"),
  banner: require("./banner.png"),
  bgContact2: require("./bg_contact2.png"),
  blackWhite: require("./blackwhite.png"),
  camera: require("./camera.png"),
  cartoon: require("./cartoon.png"),
  checkCircle: require("./CheckCircle.png"),
  chiBi: require("./chibi.png"),
  closeIcon: require("./close_icon.png"),
  defaultImg: require("./default_img.jpg"),
  ellipse10: require("./Ellipse 10.png"),
  ellipse11: require("./Ellipse 11.png"),
  ellipse12: require("./Ellipse 12.png"),
  ellipse13: require("./Ellipse 13.png"),
  emailIcon: require("./email_icon.png"),
  en: require("./english.png"),
  googleIcon: require("./google_icon.png"),
  jp: require("./japan.png"),
  loading: require("./loading.gif"),
  locationIcon: require("./location_icon.png"),
  loginImage: require("./location_icon.png"),
  logo: require("./logo.png"),
  manga: require("./manga.png"),
  noDataFound: require("./no-data-found.webp"),
  noneAvatar: require("./none_avatar.png"),
  notTransaction: require("./not_transaction.png"),
  oldPhoto: require("./oldphoto.png"),
  passwordIcon: require("./password_icon.png"),
  phoneIcon: require("./phone_icon.png"),
  rectangle1: require("./rectangle1.png"),
  rectangle2: require("./rectangle2.png"),
  rectangle3: require("./rectangle3.png"),
  rectangle730: require("./Rectangle730.png"),
  rectangle732: require("./Rectangle732.png"),
  rectangle733: require("./Rectangle733.png"),
  rectangle734: require("./Rectangle734.png"),
  rectangle735: require("./Rectangle735.png"),
  rectangle736: require("./Rectangle736.png"),
  registerImage: require("./register_image.png"),
  retro: require("./retro.jpeg"),
  tick: require("./tick.png"),
  traced: require("./traced.png"),
  useIcon: require("./user_icon.png"),
  vi: require("./VietNam.png"),
  es: require("./spanish.png"),
};

export { imagesCommon, imagesArt, imagesInterior, imagesInteriorBanner, imagesPayment };
