import { memo, useEffect } from "react";
import { HiArrowRight } from "react-icons/hi";
import { useCommonContext } from "../../contexts/commonContext";
import { IImageInfo } from "../../types/my-images";
import { ImageItem } from "../commons/ImageItem/ImageItem";
import { Loader } from "../commons/Loader";
import { NoDataFound } from "../commons/NoDataFound";
import { myImagesApis } from "../../apis/image";

interface Props {
  valueSearch: string;
  arrFilterState: string[];
  typeSort: number | null;
  sortState: string;
  tab: string;
  onLoadMore: () => void;
}

const ListMyImages: React.FC<Props> = memo(({ onLoadMore, arrFilterState, sortState, typeSort, valueSearch }) => {
  const { myImage, setMyImage } = useCommonContext();
  const { listMyImages, limit, page, loadingMore, total } = myImage;
  const { isRefreshPage } = useCommonContext();

  useEffect(() => {
    setMyImage((pre) => ({ ...pre, loading: true, success: false }));
    myImagesApis
      .getMyImages({
        prompt: valueSearch,
        styleImage: arrFilterState,
        sort: sortState,
        type: typeSort,
        limit,
        page,
      })
      .then((response) => {
        let listMyImageData: any[];
        if (myImage.page === 1) {
          listMyImageData = [...response.data.listImage];
        } else if (myImage.listMyImages.length === myImage.total) {
          listMyImageData = [...myImage.listMyImages];
        } else if (myImage.page > 1 && myImage.isDelete) {
          const lastImg = response.data.listImage.slice(-1);
          listMyImageData = [...myImage.listMyImages, ...lastImg];
        } else {
          listMyImageData = [...myImage.listMyImages, ...response.data.listImage];
        }
        setMyImage((pre) => ({
          ...pre,
          loading: false,
          loadingMore: false,
          total: response.data.total,
          listMyImages: listMyImageData,
        }));
      })
      .catch((error) => {
        setMyImage((pre) => ({ ...pre, loading: false, success: false, error: error }));
      });
  }, [valueSearch, arrFilterState, sortState, typeSort, page, isRefreshPage, total]);

  return (
    <>
      {/* List My Images */}
      {listMyImages.length >= 1 ? (
        <div
          className="
        grid 
        gap-x-4 
        gap-y-4 
        md:gap-x-3 
        md:gap-y-3 
        lg:gap-x-4 
        lg:gap-y-4 

        2xl:grid-cols-4 
        lg:grid-cols-3
        sm:grid-cols-2

        mt-5
        sm:mt-5
        md:mt-9
        "
        >
          <>
            {listMyImages?.map((item: IImageInfo) => (
              <ImageItem key={item.id} dataImage={item} type="myImages" tab="myimages" />
            ))}
          </>
        </div>
      ) : (
        <div className="mt-[20px] w-4/6 mx-auto">
          <NoDataFound />
        </div>
      )}
      <>
        {listMyImages.length < total && (
          <div className="load-more text-center mt-10">
            {loadingMore ? (
              <Loader />
            ) : (
              <div className="flex justify-center items-center">
                <button
                  className="text-white px-[50px] py-[14px] text-lg font-black bg-primary rounded-rddefault flex items-center hover:bg-bgbtn transition-all"
                  onClick={onLoadMore}
                >
                  Load More
                  <span className="w-4 h-[14px] ml-[14px] mb-1">
                    <HiArrowRight />
                  </span>
                </button>
              </div>
            )}
          </div>
        )}
      </>
    </>
  );
});

export { ListMyImages };
