import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { imagesCommon } from "../../../assets/images";
import { InputPassword, MessageError } from "../../../components";
import { useCommonContext } from "../../../contexts/commonContext";
import { useToast } from "../../../hooks/useToast/useToast";
import { SocialNetwork } from "./SocialNetwork";

export type FormDataLogin = {
  email: string;
  password?: string;
  name?: string;
  googleId?: string;
  facebookId?: string;
  imageUrl?: string;
};

export type TErrorData = {
  code: number;
  message: string;
};

const schema = yup.object().shape({
  email: yup.string().required("Email is required").email("Email is not valid"),
  password: yup.string().required("Password is required"),
});

export const SignIn: React.FC = () => {
  const { user, setUser, getUserInformation, userLogin } = useCommonContext();
  const navigate = useNavigate();
  const { error, userToken, loading } = user;
  const [errorData, setErrorData] = useState<TErrorData | null>(null);
  const [errorMessageEmail, setErrorMessageEmail] = useState<string | null>(null);
  const [errorMessagePwd, setErrorMessagePwd] = useState<string | null>(null);
  const [isVerifiedPage, setIsVerifiedPage] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormDataLogin>({
    resolver: yupResolver(schema),
  });

  const emailValue = watch("email");
  const passwordValue = watch("password");

  useEffect(() => {
    if (searchParams.get("status") === "success") setIsVerifiedPage(true);
    setUser((pre) => ({ ...pre, error: false }));
  }, []);

  useEffect(() => {
    if (userToken) {
      getUserInformation();
      navigate("/");
    }
  }, [userToken]);

  useEffect(() => {
    setErrorData(error?.response?.data?.data);
  }, [error]);

  useEffect(() => {
    checkTypeError();
  }, [errorData]);

  useEffect(() => {
    setErrorData(null);
    setErrorMessageEmail(null);
    setErrorMessagePwd(null);
  }, [emailValue, passwordValue]);

  const checkTypeError = () => {
    switch (errorData?.code) {
      case 1:
        setErrorMessageEmail(errorData.message);
        break;
      case 3:
        setErrorMessagePwd(errorData.message);
        break;
      default:
        toast.error(errorData?.message);
        break;
    }
  };

  const handleOk = () => {
    if (searchParams.has("status")) {
      searchParams.delete("status");
      setSearchParams(searchParams);
    }
    setIsVerifiedPage(false);
  };

  const onSubmit = (data: FormDataLogin) => {
    setUser((pre) => ({ ...pre, loading: true, error: null, success: false }));
    userLogin(data, () => navigate(0));
  };

  if (isVerifiedPage)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <div className="w-fit h-fit flex flex-col justify-center items-center rounded-rddefault bg-white py-4">
          <img className="w-2/3 h-2/3 object-cover" src={imagesCommon.tick} alt="success" />
          <h4 className="text-black font-bold text-lg sm:text-base lg:text-2xl px-0 sm:px-2 lg:px-4">
            Your email address has been verified
          </h4>
          <button
            onClick={handleOk}
            className="btn hover:bg-bgdark border border-primary border-solid px-10 py-3 text-base font-black self-center capitalize hover:text-primary bg-primary text-white transition-all mt-10"
          >
            OK
          </button>
        </div>
      </div>
    );

  return (
    <>
      <div className="card w-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 backdrop-blur-sm lg:w-[450px] sm:w-4/6 rounded-[10px]">
        <div className="card-body justify-center rounded-lg bg-slate-700/10">
          <h2 className="card-title justify-center !mb-9 text-tsecondary text-[32px] font-black">Welcome Back!</h2>
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
            <div className="relative">
              <img
                className="absolute left-4 top-1/2 -translate-y-1/2 object-cover w-5 h-4 "
                src={imagesCommon.emailIcon}
                alt="email"
              />
              <input
                {...register("email")}
                type="text"
                placeholder="Email*"
                defaultValue={searchParams && (searchParams.get("email") as string)}
                className={`input w-full focus:outline-none bg-bggrayprimary border border-solid border-bdinput rounded-rddefault text-tsecondary pl-12 ${
                  errors.email && "border-red-600"
                }`}
              />
            </div>
            {errors.email && <MessageError message={errors.email.message} />}
            {errorMessageEmail && <MessageError message={errorMessageEmail} />}

            <InputPassword inputAttribute={register("password")} placeholder="Password*" error={errors?.password} />
            {errors.password && <MessageError message={errors.password.message} />}
            {errorMessagePwd && <MessageError message={errorMessagePwd} />}
            <Link
              className="text-sm mt-3 mr-2 text-primary hover:text-bgbtn transition-all self-end"
              to="/reset-password"
            >
              Forgot password?
            </Link>
            <button
              type="submit"
              disabled={loading}
              className={`btn bg-primary hover:bg-bgbtn transition-all text-white mt-4 mb-3 focus:outline-none  ${
                loading && "loading"
              } hover:border-none`}
            >
              {loading || "Sign In"}
            </button>

            <h3 className="text-tsecondary text-md font-bold text-center">OR</h3>
            {/* ------------------------------- */}
            <SocialNetwork />
            {/* ------------------------------- */}

            <p className="text-center text-tplaceholder">
              Don't have an account?{" "}
              <Link className="text-primary hover:text-bgbtn transition-all" to="/sign-up">
                Sign Up
              </Link>
            </p>
          </form>
        </div>
      </div>
    </>
  );
};
