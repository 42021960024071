import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { authApis } from "../../../apis/auth";
import { imagesCommon } from "../../../assets/images";
import { InputPassword, MessageError } from "../../../components";
import { useCommonContext } from "../../../contexts/commonContext";
import { REGEX_CHARACTERS, REGEX_PWD, emailRegExp } from "../../../utils/regex";

export type FormDataRegister = {
  name?: string;
  email: string;
  password: string;
  rePassword: string;
};

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Full Name is required")
    .matches(REGEX_CHARACTERS, "Full Name is invalid")
    .max(50, "Maximum of 50 characters")
    .trim(),
  email: yup
    .string()
    .required("Email is a required field")
    .matches(emailRegExp, "Email is invalid")
    .email("Email is invalid")
    .trim(),
  password: yup
    .string()
    .required("Password is required")
    .matches(REGEX_PWD, "Only use letters, numbers, and common punctuation characters")
    .min(8, "Minimum of 8 characters")
    .trim(),
  rePassword: yup
    .string()
    .required("Confirm password is required")
    .oneOf([yup.ref("password")], "Passwords does not match")
    .trim(),
});

export const SignUp: React.FC = () => {
  const { user, setUser } = useCommonContext();
  const { userToken, regisSuccess, loading, error } = user;
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    setUser((pre) => ({ ...pre, error: null }));
  }, []);

  useEffect(() => {
    if (userToken) navigate("/");
  }, [userToken]);

  useEffect(() => {
    if (regisSuccess) {
      navigate("/sign-in");
      setUser((pre) => ({ ...pre, success: false }));
    }
  }, [regisSuccess]);

  useEffect(() => {
    setErrorMessage(error?.response?.data?.data?.message);
  }, [error]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormDataRegister>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormDataRegister) => {
    setUser((pre) => ({ ...pre, loading: true, error: null }));
    await authApis
      .signUp(data)
      .then((response) => {
        navigate("/sign-in");
        setUser((pre) => ({ ...pre, loading: false, success: true, error: response.data }));
      })
      .catch((error) => {
        setUser((pre) => ({ ...pre, loading: false, error: error }));
      });
  };

  return (
    <div className="flex justify-center pt-28 mb-14 mx-3 lg:mx-0">
      <section className="bg-bgdark w-full lg:w-auto lg:max-w-screen-2xl flex justify-center items-center lg:px-8">
        <div className="card w-full lg:max-w-[1124px] lg:card-side lg:card-normal bg-base-100 rounded-[10px]">
          <figure className="w-1/2 bg-primary">
            <img
              className="w-full h-full hidden lg:block object-contain px-[64px] py-[84px]"
              src={imagesCommon.registerImage}
              alt="Album"
            />
          </figure>
          <div className="card-body justify-center bg-bggrayprimary">
            <h2 className="card-title justify-center !mb-9 text-tsecondary text-[32px] font-black">Register</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full">
              <div className="relative mt-5">
                <img
                  className="absolute left-4 top-1/2 -translate-y-1/2 object-contain w-5 h-5 "
                  src={imagesCommon.useIcon}
                  alt="email"
                />
                <input
                  {...register("name")}
                  type="text"
                  placeholder="Full Name*"
                  className={`input w-full focus:outline-none bg-bggrayprimary border border-solid border-bdinput rounded-rddefault text-tsecondary pl-12 ${
                    errors.name && "border-red-600"
                  }`}
                />
              </div>
              {errors.name && <MessageError message={errors.name.message} />}

              <div className="relative mt-5">
                <img
                  className="absolute left-4 top-1/2 -translate-y-1/2 object-contain w-5 h-4 "
                  src={imagesCommon.emailIcon}
                  alt="email"
                />
                <input
                  {...register("email")}
                  type="text"
                  placeholder="Email*"
                  className={`input w-full focus:outline-none bg-bggrayprimary border border-solid border-bdinput rounded-rddefault text-tsecondary pl-12 ${
                    errors.email && "border-red-600"
                  }`}
                />
              </div>
              {errorMessage ? (
                <MessageError message={errorMessage} />
              ) : (
                errors.email && <MessageError message={errors.email.message} />
              )}

              <InputPassword inputAttribute={register("password")} placeholder="Password*" error={errors?.password} />
              {errors.password && <MessageError message={errors.password.message} />}

              <InputPassword
                inputAttribute={register("rePassword")}
                placeholder="Confirm Password*"
                error={errors.rePassword}
              />
              {errors.rePassword && <MessageError message={errors.rePassword.message} />}
              <button
                type="submit"
                disabled={loading}
                className={`btn bg-primary hover:bg-bgbtn transition-all text-white mt-5 mb-3 focus:outline-none ${
                  loading && "loading"
                }`}
              >
                {loading || "Sign Up"}
              </button>
              <div className="divider after:bg-bdcolor before:bg-bdcolor"></div>
              <p className="text-center text-tplaceholder">
                Already have an account?{" "}
                <Link className="text-primary hover:text-bgbtn transition-all" to="/sign-in">
                  Sign In
                </Link>
              </p>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
