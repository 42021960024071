import { useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { userApis } from "../apis/auth";
import packageApis from "../apis/package";
import { FormChangePassword, FormInfoUser, PaymentHistory, Upgrade, UserInfo } from "../components";
import { ProfileState } from "../constants/profile.constants";
import { useCommonContext } from "../contexts/commonContext";
import { useToast } from "../hooks/useToast/useToast";
import { IProfileAction, IProfileState } from "../types/user";

export const Profile: React.FC = () => {
  const { profileAction, setProfileAction, user, getUserInformation } = useCommonContext();
  const { userToken, userInfo } = user;
  const navigate = useNavigate();
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const customId = "id-provided-pwd";
  useEffect(() => {
    if (!userToken) navigate("/sign-in");
    getUserInformation();
  }, [userToken]);

  useEffect(() => {
    if (searchParams.get("type") === "paymentHistory") {
      setProfileAction(IProfileAction.paymentHistory);
    }
  }, []);

  useEffect(() => {
    if (!userInfo?.isProvidedPassword) {
      setProfileAction(IProfileAction.changePassword);
      toast.warn("You need create new password to explore website!", {
        toastId: customId,
      });
    }
  }, [userInfo, setProfileAction]);

  const handleChangeType = (type: IProfileAction) => {
    !userInfo?.isProvidedPassword ? setProfileAction(IProfileAction.changePassword) : setProfileAction(type);
  };

  const fetchAllPackage = async () => {
    const res = await packageApis.getAllPackageUser();
    return res.data.listPackage;
  };
  const fetchUserInformation = async () => {
    return await userApis.information();
  };

  const { data: packageData }: any = useQuery("package", fetchAllPackage);
  const { data: userFetch }: any = useQuery("user-info", fetchUserInformation);
  useEffect(() => {
    getUserInformation();
  }, [userFetch]);

  const renderItem = () => {
    switch (profileAction) {
      case IProfileAction.info:
        return <FormInfoUser />;
      case IProfileAction.changePassword:
        return <FormChangePassword />;
      case IProfileAction.paymentHistory:
        return <PaymentHistory />;
      default:
        return <Upgrade packageData={packageData} userFetch={userFetch} />;
    }
  };

  return (
    <section className="pt-28 pb-16 flex justify-center flex-col items-center bg-bgdark">
      <div className="max-w-screen-2xl">
        <div className="grid grid-cols-12 px-3 md:px-0">
          <div className="col-span-12">
            <UserInfo />
            <div className="flex justify-center mb-9">
              {ProfileState.map((item: IProfileState) => (
                <span
                  key={item.type}
                  onClick={() => handleChangeType(item.type)}
                  className={`text-sm md:text-lg font-medium text-ttitle mr-8 md:mr-10 last:mr-0 cursor-pointer text-center ${
                    profileAction === item.type && "!font-black border-b-2 border-primary border-solid"
                  }`}
                >
                  {item.content}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`mx-3 max-w-screen-3xl ${profileAction === IProfileAction.paymentHistory && "max-w-full px-[20px]"}`}
      >
        {renderItem()}
      </div>
    </section>
  );
};
