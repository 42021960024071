import { ITabMyImages } from "../types/my-images";
export const TabMyImages: ITabMyImages[] = [
  {
    type: "myimages",
    content: "My Images",
  },
  {
    type: "myfavorites",
    content: "My Favorites",
  },
];
