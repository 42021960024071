import { imagesCommon } from "../../assets/images";

export const ContactHeader: React.FC = () => {
  return (
    <>
      <img src={imagesCommon.bgContact2} className="h-[550px] w-full" alt="contact" />
      <div className=" text-white w-full -mt-[350px] max-w-screen-2xl mx-auto">
        <p className="font-black text-[85px]">Let’s Discuss!</p>
        <p className="font-normal text-xl">Fill out the form below and we will get in touch with you shortly.</p>
      </div>
    </>
  );
};
