import creditCard from "../assets/images/payment/creditcard_logo.png";
import vnPay from "../assets/images/payment/vnpay_logo.png";
import bankTransfer from "../assets/images/payment/bank_transfer_logo.png";
import { PaymentType } from "../types/payment";

export interface IPaymentMethod {
  type: PaymentType;
  url: string;
  content: string;
}

export const PaymentMethod: IPaymentMethod[] = [
  {
    type: PaymentType.CreditCard,
    url: creditCard,
    content: "Pay with credit card",
  },
  {
    type: PaymentType.VNPay,
    url: vnPay,
    content: "Pay with vnPay",
  },
  {
    type: PaymentType.BankTransfer,
    url: bankTransfer,
    content: "Pay with bank transfer",
  },
];
