import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { userApis } from "../../apis/auth";
import { useCommonContext } from "../../contexts/commonContext";
import { useToast } from "../../hooks/useToast/useToast";
import { REGEX_PWD } from "../../utils/regex";
import { InputPassword } from "../commons/InputPassword";
import { MessageError } from "../commons/MessageError";

export type FormDataChangePassword = {
  oldPassword: string;
  newPassword: string;
  reNewPassword: string;
};

const schema = yup.object().shape({
  oldPassword: yup.string().required("This field is required").min(8, "Minimum of 8 characters"),
  newPassword: yup
    .string()
    .required("This field is required")
    .matches(REGEX_PWD, "Only use letters, numbers, and common punctuation characters")
    .min(8, "Minimum of 8 characters"),
  reNewPassword: yup
    .string()
    .required("This field is required")
    .oneOf([yup.ref("newPassword")], "Passwords does not match"),
});

const schemaGoogle = yup.object().shape({
  newPassword: yup.string().required("This field is required").min(8, "Minimum of 8 characters"),
  reNewPassword: yup
    .string()
    .required("This field is required")
    .oneOf([yup.ref("newPassword")], "Passwords does not match"),
});

export const FormChangePassword: React.FC = () => {
  const { user, handleUserLogout } = useCommonContext();
  const { userInfo } = user;
  const [loading, setLoading] = useState<boolean>();
  const [errorServer, setErrorServer] = useState<{ code: number; message: string } | null>(null);
  const { toast } = useToast();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm<FormDataChangePassword>({ resolver: yupResolver(userInfo?.isProvidedPassword ? schema : schemaGoogle) });

  const emailValue = watch("oldPassword");

  useEffect(() => {
    setErrorServer(null);
  }, [emailValue]);

  const onSubmit = async (data: FormDataChangePassword) => {
    try {
      userInfo?.isProvidedPassword ? await userApis.changePassword(data) : await userApis.changePasswordGoogle(data);
      toast.success("Password Changed!");
      setErrorServer(null);
      reset();
      handleUserLogout();
    } catch (error: any) {
      setErrorServer(error.response.data.data);
    }
  };

  const renderCurrentPassword = useMemo(
    () => (
      <>
        <label className="text-tlabel font-bold">
          Current Password <span className="text-red-600">*</span>
        </label>
        <InputPassword
          inputAttribute={register("oldPassword")}
          placeholder="Current Password*"
          error={errors?.oldPassword || (errorServer as any)}
        />
        {errorServer && errorServer.code === 3 ? (
          <MessageError message={errorServer.message} />
        ) : (
          errors.oldPassword && userInfo?.isProvidedPassword && <MessageError message={errors.oldPassword.message} />
        )}
      </>
    ),
    [errorServer, errors.oldPassword, userInfo],
  );

  return (
    <div className="card lg:card-side lg:card-normal bg-base-100 rounded-[10px] md:min-w-[640px] w-full">
      <div className="card-body bg-bggrayprimary">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12 mb-5">{userInfo?.isProvidedPassword && renderCurrentPassword}</div>
            <div className="col-span-12 mb-5">
              <label className="text-tlabel font-bold">
                New Password <span className="text-red-600">*</span>
              </label>
              <InputPassword
                className="mt-3"
                inputAttribute={register("newPassword")}
                placeholder="New Password*"
                error={errors?.newPassword || (errorServer?.code === 6 ? (errorServer as any) : undefined)}
              />
              {errorServer && errorServer.code === 6 ? (
                <MessageError message={errorServer.message} />
              ) : (
                errors.newPassword && <MessageError message={errors.newPassword.message} />
              )}
            </div>
            <div className="col-span-12 ">
              <label className="text-tlabel font-bold">
                Confirm Password <span className="text-red-600">*</span>
              </label>
              <InputPassword
                className="mt-3"
                inputAttribute={register("reNewPassword")}
                placeholder="Confirm Password*"
                error={errors?.reNewPassword}
              />
              {errors.reNewPassword && <MessageError message={errors.reNewPassword.message} />}
            </div>
          </div>
          <button
            type="submit"
            disabled={loading || !isDirty}
            className={classnames(
              "btn bg-[#3E80FF] hover:bg-bgbtn text-white mb-3 w-fit self-center border-none outline-none capitalize text-lg font-black px-12 py-3 mt-7 disabled:text-white/30 disabled:bg-[#3E80FF]/30",
              loading && "loading",
            )}
          >
            {loading || "Update"}
          </button>
        </form>
      </div>
    </div>
  );
};
