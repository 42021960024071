import { useEffect } from "react";
import { MdOutlineFavorite } from "react-icons/md";
import { IImageInfo } from "../../types/my-images";
import useFavorite from "../../hooks/favorite/useFavorite";

interface Props {
  item: IImageInfo;
  isMyImage?: boolean;
  toggleFavorite: (props: IImageInfo[]) => void;
  dataArray: IImageInfo[];
}

const Favorite: React.FC<Props> = ({ isMyImage, item, toggleFavorite, dataArray }) => {
  const { isLoaderFavorite: isLoadreFavorite, handleFavorite, dataAfterFavorite } = useFavorite();
  useEffect(() => {
    if (dataAfterFavorite.length > 0) {
      toggleFavorite(dataAfterFavorite);
    }
  }, [dataAfterFavorite]);

  const isFavorite = item?.favorite;
  const classHeart = "rotate-[30deg] translate-x-[-10px] origin-[-5px_10px] w-[16px] h-[24px]";
  const classUnHeart = isFavorite ? "rotate-45 translate-x-[-5px] origin-[1px_14px]" : "";

  return (
    <div className="relative flex cursor-pointer">
      {isLoadreFavorite ? (
        <>
          <div className={`${classHeart} ${classUnHeart}`}>
            <div
              className={`
              absolute
              animate-${isFavorite ? "spin" : "ping"}
              w-[1rem] h-[1rem] 
              ${isFavorite ? "bg-[#ffffff]" : "bg-red-500"}
              before:left-[-0.75rem]  
              before:rounded-tl-[50%] before:rounded-bl-[50%]
              before:content-[''] before:absolute before:block before:w-[1rem] before:h-[1rem]
              ${isFavorite ? "before:bg-[#ffffff]" : "before:bg-red-500"}

              after:top-[-0.75rem] 
              after:rounded-tl-[50%] after:rounded-tr-[50%]
              after:content-[''] after:absolute after:block after:w-[1rem] after:h-[1rem]
              ${isFavorite ? "after:bg-[#ffffff]" : "after:bg-red-500"}
              `}
            ></div>
          </div>
        </>
      ) : (
        <MdOutlineFavorite
          onClick={() => handleFavorite(item, dataArray, isMyImage)}
          className={`hover:text-rose-400 ${item?.favorite && "text-rose-500"} w-full h-full rounded-[50%] p-[5px]`}
        />
      )}
    </div>
  );
};
export { Favorite };
