import React, { useEffect, useRef, useState } from "react";
import { useCommonContext } from "../../../contexts/commonContext";
import { IImageInfo } from "../../../types/my-images";
import { ActionButton } from "../../home/collections/ActionButton";
import { ActionsWithMyImage } from "../../my-images/ActionsWithMyImage";
import { ModalView } from "../modalView/ModalView";
import { DetailBottom } from "./DetailBottom";

interface Props {
  dataImage: IImageInfo;
  type: string;
  tab?: string;
  setListHashtag?: React.Dispatch<React.SetStateAction<string[]>>;
  listHashtag?: string[];
}

const ImageItem: React.FC<Props> = ({ dataImage, type, tab, setListHashtag, listHashtag }) => {
  const { collectionImages, myImage } = useCommonContext();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isPopup, setIsPopup] = useState<boolean>(false);
  const imageList =
    type === "homepage"
      ? collectionImages
      : tab === "myimages"
      ? myImage.listMyImages
      : myImage.listMyFavoriteImages.map((img) => img.image);

  const handViewDetail = () => {
    setOpenModal(true);
  };
  const imgWrap: any = useRef(null);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (imgWrap.current && !imgWrap.current.contains(event.target)) {
        setIsPopup(false);
      }
    };
    document.addEventListener("mouseout", handleClick, true);
    return () => {
      document.removeEventListener("mouseout", handleClick, true);
    };
  }, [imgWrap]);

  const handlePopup = () => {
    setIsPopup(true);
  };

  return (
    <>
      <div className="bg-bggray border-bdcolor border-solid border rounded-lg px-[15px] pt-[13px] pb-[20px]">
        <div className="h-full flex flex-col justify-between rounded-[7px] overflow-hidden">
          <div className="flex items-center relative overflow-hidden group" ref={imgWrap}>
            <div className="w-full h-full bg-black">
              <img
                className="w-full cursor-pointer duration-200 group-hover:scale-[1.08] object-contain
                        group-hover:opacity-60 h-[512px] sm:h-[270px] lg:h-[290px] xl:h-[270px]"
                src={dataImage.imageUrl}
                alt="..."
                onClick={handViewDetail}
              />
            </div>
            <div
              className="flex justify-center items-center absolute bottom-0 left-[50%] translate-x-[-50%] translate-y-[200%] duration-300
              group-hover:translate-x-[-50%]
              group-hover:translate-y-[50%]
              group-hover:bottom-[50%]
               "
            >
              {type === "homepage" ? (
                <ActionButton dataImage={dataImage} isPopup={isPopup} onClickPopup={handlePopup} />
              ) : (
                <ActionsWithMyImage dataImage={dataImage} tab={tab} isPopup={isPopup} onClickPopup={handlePopup} />
              )}
            </div>
          </div>
          <DetailBottom setListHashtag={setListHashtag} listHashtag={listHashtag} item={dataImage} type={type} />
        </div>
      </div>
      {/* See Detail */}
      {openModal && <ModalView detail={dataImage} data={imageList} onClickOutside={() => setOpenModal(false)} />}
    </>
  );
};

export { ImageItem };
