import { useMemo } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { paymentHistoryApis } from "../../apis/payment";
import { imagesCommon } from "../../assets/images";
import { PAYMENT_METHOD_MAP, PAYMENT_STATUS_MAP } from "../../constants/common.constants";
import { classStatus, timeConvert } from "../../utils/helpers";

const DropdownContent = styled.div`
  padding-bottom: 15px;
  .tableWrap {
    overflow-x: auto;
    overflow-y: hidden;
    /* custom scrollbar */
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      height: 7px;
      cursor: pointer;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 20px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #667085;
      border-radius: 20px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const PaymentHistory = () => {
  const thead = [
    { id: 1, title: "Purchase time" },
    { id: 2, title: "Package" },
    { id: 3, title: "Price" },
    { id: 4, title: "Status" },
    { id: 5, title: "Payment Methods" },
  ];

  const fetchPaymentHistory = async () => {
    const response = await paymentHistoryApis.get();
    return response.data.listPayment;
  };

  const { data: listPaymentState }: any = useQuery("payment-history", fetchPaymentHistory);

  const renderListPayment = useMemo(() => {
    return listPaymentState?.map((value: any, index: any) => {
      const priceVND = new Intl.NumberFormat("de-DE").format(value.price);
      const payMethod = PAYMENT_METHOD_MAP.find((item: any) => item.value === value.payMethod);
      return (
        <tr key={value.id} className="relative text-ttitle">
          <td className={`px-[26px] py-[18px]`}>
            <span>{timeConvert(value.createdAt)}</span>
          </td>
          <td className={`px-[26px] py-[18px]`}>
            <span className={"ml-[54px]"}>{value.packageId.title}</span>
          </td>
          <td className={`px-[26px] py-[18px]`}>
            <span className={"ml-[54px]"}>VND {priceVND}</span>
          </td>
          <td className={`px-[26px] py-[18px]`}>
            <span className={`${classStatus(value.payStatus)} ml-[54px]`}>
              {PAYMENT_STATUS_MAP.find((item: any) => item.value === value.payStatus)?.label}
            </span>
          </td>
          <td className={`px-[26px] py-[18px]`}>
            <span className={"ml-[54px]"}>{payMethod?.label}</span>
          </td>
          {/* -------------<hr/> custom-------------------------------------------- */}
          <>
            {index !== 0 && (
              <div className="absolute left-1/2 translate-x-[-50%] px-[26px] w-full">
                <div className="w-full h-[1px] bg-[#667085]" />
              </div>
            )}
          </>
        </tr>
      );
    });
  }, [listPaymentState]);

  const renderDataNotFound = useMemo(
    () => (
      <div className="flex justify-center flex-col items-center h-64">
        <div>
          <img src={imagesCommon.notTransaction} alt="notTransaction" />
        </div>
        <div>No data found!</div>
      </div>
    ),
    [],
  );

  return (
    <DropdownContent className="bg-bggrayprimary rounded-[10px] text-white">
      <div className="overflow-x-auto tableWrap">
        <table className="w-max ">
          <tr>
            {thead.map((th, index) => {
              return (
                <th className="text-start px-[26px] py-[18px] border-solid border-b border-[#667085]" key={th.id}>
                  <span className={`${index !== 0 && "ml-[54px]"}`}>{th.title}</span>
                </th>
              );
            })}
          </tr>
          {renderListPayment}
        </table>

        {listPaymentState?.length < 1 && renderDataNotFound}
      </div>
    </DropdownContent>
  );
};
