import { imagesCommon } from "../../assets/images";

const NoDataFound: React.FC = () => {
  return (
    <div className="w-full h-full flex justify-center items-start">
      <img className="w-[70%] object-cover rounded-3xl" src={imagesCommon.noDataFound} alt="NoData" />
    </div>
  );
};

export { NoDataFound };
