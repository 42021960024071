import { yupResolver } from "@hookform/resolvers/yup";
import classname from "classnames";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { paymentApis } from "../../../apis/payment";
import { imagesCommon, imagesPayment } from "../../../assets/images";
import { useCommonContext } from "../../../contexts/commonContext";
import { useModalContext } from "../../../contexts/modalContext";
import { useToast } from "../../../hooks/useToast/useToast";
import { IProfileAction } from "../../../types/user";
import catchError from "../../../utils/catch-error";
import { numberWithSpaces } from "../../../utils/helpers";
import { numberRegex } from "../../../utils/regex";
import { MessageError } from "../../commons/MessageError";

type FormCreditCard = {
  cardHolder: string;
  cardNumber: string;
  expMonth: string;
  expYear: string;
  CVCNumber: string;
};

const schema = yup.object().shape({
  cardHolder: yup.string().required("This field is required"),
  cardNumber: yup
    .string()
    .required("This field is required")
    .matches(numberRegex, "Please only enter numeric characters only for cardNumber")
    .min(12, "Minimum of 12 characters"),
  expMonth: yup
    .string()
    .required("This field is required")
    .matches(numberRegex, "Please only enter numeric characters only for expMonth")
    .min(2, "Minimum of 2 characters"),
  expYear: yup
    .string()
    .required("This field is required")
    .matches(numberRegex, "Please only enter numeric characters only for expYear")
    .min(2, "Minimum of 2 characters"),
  CVCNumber: yup
    .string()
    .required("This field is required")
    .matches(numberRegex, "Please only enter numeric characters only for CVCNumber")
    .min(2, "Minimum of 4 characters"),
});

export const ModalCreditCard: React.FC = () => {
  const { setProfileAction, user } = useCommonContext();
  const { modalCreditCard, packageUpgrade, setModalCreditCard, setModalWaiting } = useModalContext();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormCreditCard>({
    resolver: yupResolver(schema),
  });

  const watchCardHolder = watch("cardHolder");
  const watchCardNumber = watch("cardNumber");
  const watchExpMonth = watch("expMonth");
  const watchExpYear = watch("expYear");

  useEffect(() => {
    if (!window.document.getElementById("stripe-script")) {
      const s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        (window as { [key: string]: any })["Stripe"].setPublishableKey(process.env.REACT_APP_STRIPE_KEY);
      };
      window.document.body.appendChild(s);
    }
  }, []);

  const handleBack = () => {
    setModalCreditCard(false);
  };

  const onSubmit = async (data: FormCreditCard) => {
    setLoading(true);
    try {
      window.Stripe.card.createToken(
        {
          number: data.cardNumber,
          exp_month: data.expMonth,
          exp_year: data.expYear,
          cvc: data.CVCNumber,
          name: data.cardHolder,
        },
        async (status: number, res: any) => {
          if (status === 402) toast.error(res?.error?.message);
          try {
            if (status === 200) {
              setModalCreditCard(false);
              setModalWaiting(true);
              const response = await paymentApis.createInvoiceStripe({
                token: res,
                email: user.userInfo?.email,
                amount: packageUpgrade?.totalPrice,
                packageId: packageUpgrade?.id,
              });

              if (response) {
                setModalWaiting(false);
                navigate("/profile");
                setProfileAction(IProfileAction.paymentHistory);
              }
            }
          } catch (error: any) {
            toast.error(catchError(error));
            setModalWaiting(false);
            navigate("/profile");
            setProfileAction(IProfileAction.paymentHistory);
          } finally {
            setLoading(false);
          }
        },
      );
    } catch (error: any) {
      toast.error(catchError(error));
    }
  };

  return (
    <div className={classname("modal", modalCreditCard && "modal-open")}>
      <div className="modal-box sm:h-fit lg:h-full bg-[#1D2939] p-0 rounded-rddefault relative overflow-auto scrollbar w-full">
        <img
          className="w-6 h-6 ml-auto mt-[10px] mr-[10px] hover:scale-105 transition-all cursor-pointer"
          onClick={() => setModalCreditCard(false)}
          src={imagesCommon.closeIcon}
          alt="close"
        />
        <div className="px-[30px] pb-[30px] flex flex-col">
          <div className="relative w-full h-full">
            <img className="absolute top-0 left-0 z-10" src={imagesPayment.bgCredit} alt="img" />
            <img className="absolute top-0 left-0 z-20" src={imagesPayment.maskCredit} alt="img" />
            <img className="absolute w-[90%] top-[20px] left-[20px] z-30" src={imagesPayment.logoCredit} alt="img" />
            <img
              className="absolute w-9 h-8 bottom-[-188px] right-[17px] sm:bottom-[-252px] lg:w-[50px] lg:h-[40px] lg:bottom-[-265px] lg:right-[20px] z-40"
              src={imagesPayment.chipCredit}
              alt="img"
            />
            <h3 className="absolute top-[100px] sm:top-[120px] lg:top-[145px] left-[30px] z-50 font-black text-tsecondary text-2xl sm:text-3xl lg:text-4xl">
              {watchCardNumber && numberWithSpaces(watchCardNumber)}
            </h3>
            <div className="absolute top-[145px] sm:top-[210px] lg:top-[210px] left-[30px] z-50">
              <span className="font-normal text-white text-xs lg:text-lg">Card holder name</span>
              <h3 className="font-black text-white text-xs lg:text-base uppercase max-w-[180px]">{watchCardHolder}</h3>
            </div>
            <div className="absolute top-[145px] sm:top-[210px] lg:top-[210px] right-[110px] z-20">
              <span className="font-normal text-white text-xs lg:text-lg">Expiry date</span>
              <h3 className="font-black text-white text-xs lg:text-base">{`${watchExpMonth}/${watchExpYear}`}</h3>
            </div>
          </div>

          <p className="font-normal text-xs lg:text-base text-tlabel text-center mb-10">Choose a payment method</p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-x-[26px] mt-[170px] sm:mt-[250px] lg:mt-[250px]">
              <div className="col-span-12 mb-5">
                <label className="text-tlabel font-bold mb-[14px]">Card Holder</label>
                <input
                  {...register("cardHolder")}
                  type="text"
                  className="input w-full focus:outline-none bg-[#1D2939] border border-solid border-bdinput rounded-rddefault text-tsecondary mt-3"
                />
                {errors.cardHolder && <MessageError message={errors.cardHolder.message} />}
              </div>
              <div className="col-span-12 mb-5">
                <label className="text-tlabel font-bold mb-[14px]">Card Number</label>
                <input
                  type="text"
                  maxLength={16}
                  {...register("cardNumber")}
                  className="input w-full focus:outline-none bg-[#1D2939] border border-solid border-bdinput rounded-rddefault text-tsecondary mt-3"
                />
                {errors.cardNumber && <MessageError message={errors.cardNumber.message} />}
              </div>
              <div className="col-span-6 mb-5">
                <label className="text-tlabel font-bold mb-[14px]">Exp Month</label>
                <input
                  type="text"
                  {...register("expMonth")}
                  maxLength={2}
                  className="input w-full focus:outline-none bg-[#1D2939] border border-solid border-bdinput rounded-rddefault text-tsecondary mt-3"
                />
                {errors.expMonth && <MessageError message={errors.expMonth.message} />}
              </div>
              <div className="col-span-6 mb-5">
                <label className="text-tlabel font-bold mb-[14px]">Exp Year</label>
                <input
                  type="text"
                  maxLength={2}
                  {...register("expYear")}
                  className="input w-full focus:outline-none bg-[#1D2939] border border-solid border-bdinput rounded-rddefault text-tsecondary mt-3"
                />
                {errors.expYear && <MessageError message={errors.expYear.message} />}
              </div>
              <div className="col-span-12 mb-[40px]">
                <label className="text-tlabel font-bold mb-[14px]">CVC Number</label>
                <input
                  type="text"
                  maxLength={4}
                  {...register("CVCNumber")}
                  className="input w-full focus:outline-none bg-[#1D2939] border border-solid border-bdinput rounded-rddefault text-tsecondary mt-3"
                />
                {errors.CVCNumber && <MessageError message={errors.CVCNumber.message} />}
              </div>
              <div className="col-span-6">
                <button
                  type="button"
                  onClick={handleBack}
                  className="btn w-full border border-primary border-solid lg:px-10 lg:py-3 text-base font-black self-center capitalize hover:text-bgbtn bg-[#1D2939] text-primary hover:bg-[#1D2939] hover:border-bgbtn transition-all"
                >
                  Back
                </button>
              </div>
              <div className="col-span-6">
                <button
                  type="submit"
                  className={`btn w-full hover:bg-bgbtn border border-primary border-solid lg:px-10 lg:py-3 text-base font-black self-center capitalize bg-primary text-white transition-all ${
                    loading && "pointer-events-none cursor-not-allowed"
                  }`}
                >
                  Pay Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
