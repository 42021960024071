import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import generateImgApis from "../apis/generateImage";
import { ListMyFavorites, ListMyImages, ModalView, SortAndFilter } from "../components";
import { MY_IMAGE, SORT_FILTER } from "../constants";
import { useCommonContext } from "../contexts/commonContext";
import { ITabMyImages } from "../types/my-images";

export const MyImages: React.FC = () => {
  const { user, getUserInformation, resetPageMyImage, myImageLoadMore } = useCommonContext();
  const [valueSearch, setValueSearch] = useState<string>("");
  const [arrFilterState, setArrFilterState] = useState<string[]>([]);
  const [typeSort, setTypeSort] = useState<number | null>(-1);
  const [sortState, setSortState] = useState<string>("createdAt");
  const [tab, setTab] = useState<string>("myimages");
  const [imageData, setImageData] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (!user.userToken) navigate("/sign-in");
    getUserInformation();
  }, [user.userToken]);

  useEffect(() => {
    resetPageMyImage();
  }, [location.pathname, tab]);

  useEffect(() => {
    return () => {
      resetPageMyImage();
    };
  }, []);

  useEffect(() => {
    if (user.userInfo) {
      !user.userInfo.isProvidedPassword && navigate("/profile");
    }
  }, [user.userInfo]);

  const fetchImage = async () => {
    if (searchParams.get("id")) {
      setOpenModal(true);
      const res = await generateImgApis.getDetailImage(searchParams.get("id"));
      setImageData(res.data);
    } else {
      setImageData(null);
      setOpenModal(false);
    }
  };

  useEffect(() => {
    fetchImage();
  }, [searchParams.get("id")]);

  const handleSearch = useCallback((search: string) => {
    setValueSearch(search);
    resetPageMyImage();
  }, []);

  const handleFilter = useCallback((arrFilter: any[]) => {
    const { children } = arrFilter[0];
    const value = children.map((value: any) => value.text);
    setArrFilterState(value);
    resetPageMyImage();
  }, []);

  const handleChangeTab = (type: string) => {
    setTab(type);
    resetPageMyImage();
  };

  const handleSort = useCallback((sort: string) => {
    switch (sort) {
      case "Newest → Oldest":
        setSortState("createdAt");
        setTypeSort(-1);
        resetPageMyImage();
        break;
      // return setSortState("createdAt"), setTypeSort(-1), dispatch(ResetPage());
      case "Oldest → Newest":
        setSortState("createdAt");
        setTypeSort(1);
        resetPageMyImage();
        break;
      // return setSortState("createdAt"), setTypeSort(1), dispatch(ResetPage());
      default:
        setSortState("numberOfLike");
        setTypeSort(-1);
        resetPageMyImage();
        break;
      // return setSortState("numberOfLike"), setTypeSort(-1), dispatch(ResetPage());
    }
  }, []);

  const handleLoadMore = useCallback(() => {
    myImageLoadMore();
  }, [myImageLoadMore]);

  return (
    <>
      <div className="flex justify-center mx-3 mb-5 md:mb-0">
        <div className="max-w-screen-2xl w-full">
          <div className="card pt-28">
            <div className="tabs justify-center mb-5">
              {MY_IMAGE.TabMyImages.map((item: ITabMyImages) => (
                <a
                  key={item.type}
                  onClick={() => handleChangeTab(item.type)}
                  className={`text-lg font-medium text-ttitle mr-10 last:mr-0 cursor-pointer ${
                    item.type === tab && "!font-black border-b-2 border-primary border-solid"
                  }`}
                >
                  {item.content}
                </a>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-8">
            {/* Filter Areas */}
            <div className="col-span-12">
              <SortAndFilter
                onFilter={handleFilter}
                onSearch={handleSearch}
                onSort={handleSort}
                sortList={SORT_FILTER.SortListMyImagesPage}
                setValueSearch={setValueSearch}
              />
            </div>
            <div className="col-span-12 pb-10">
              {tab === "myimages" ? (
                <ListMyImages
                  valueSearch={valueSearch}
                  arrFilterState={arrFilterState}
                  typeSort={typeSort}
                  sortState={sortState}
                  onLoadMore={handleLoadMore}
                  tab={tab}
                />
              ) : (
                <ListMyFavorites
                  valueSearch={valueSearch}
                  arrFilterState={arrFilterState}
                  typeSort={typeSort}
                  sortState={sortState}
                  onLoadMore={handleLoadMore}
                  tab={tab}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {openModal && imageData && (
        <ModalView detail={imageData} data={[imageData]} onClickOutside={() => navigate("/my-images")} />
      )}
    </>
  );
};
