export interface IUserPackage {
  expiredDays: string;
  packageId: string;
  balanceEdit: number;
  balanceGen: number;
  timesEditLeftOnDay: number;
  timesGenerateLeftOnDay: number;
}

export interface IUserInfo {
  address: string;
  createdAt: string;
  email: string;
  name: string;
  id: string;
  password: string;
  phone: string;
  status: number;
  updateAt: string;
  thumbnailUrl: string;
  packageId: string;
  userPackage: IUserPackage;
  googleId?: string;
  isProvidedPassword: boolean;
  isEarlyAdopter: boolean;
}

export interface IUserLoginInfo {
  userId: string;
  packageId: string;
  startDays: string;
  expiredDays: string;
  balanceEdit: number;
  balanceGen: number;
  maxGenPerDay: number;
  maxEditPerDay: number;
}

export enum IProfileAction {
  info,
  upgrade,
  changePassword,
  paymentHistory,
}

export interface IProfileState {
  type: IProfileAction;
  content: string;
}
