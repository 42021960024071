import { useNavigate } from "react-router-dom";
import { useCommonContext } from "../../contexts/commonContext";
import { useGenerateImage } from "../../contexts/generateImageContext";
import { useModalContext } from "../../contexts/modalContext";
import { IProfileAction } from "../../types/user";
import { ModalComponent } from "../commons/Modal";

interface Props {
  errors?: string;
  onClose?: () => void;
}
const ModalEditExceeds: React.FC<Props> = ({ errors, onClose }) => {
  const { setHiddenBtnEdit } = useGenerateImage();
  const { modalEditExceed, setModalEditExceed } = useModalContext();
  const { setProfileAction } = useCommonContext();
  const navigate = useNavigate();
  const { msg, type } = modalEditExceed;
  return (
    <>
      <ModalComponent
        title={type === "Expired" ? `${type} Package` : `${type} Image Error`}
        btnText="Cancel"
        onClose={() => {
          setModalEditExceed((pre) => ({ ...pre, isOpen: false }));
          setHiddenBtnEdit(false);
        }}
        btnActionName={[type === "Expired" ? "Check Package" : "Upgrade Account"]}
        btnActionClick={() => {
          navigate("/profile");
          setProfileAction(IProfileAction.upgrade);
          setModalEditExceed((pre) => ({ ...pre, isOpen: false }));
        }}
      >
        <div>
          {type === "Expired" ? (
            <>
              <span className="font-semibold text-red-500">{msg}</span>. Please try again!
            </>
          ) : (
            <>
              <span>
                You reach limit exceeded image generator today. Please try again later or contact support@dopikai.com
              </span>
              {/* {type} image exceeds <span className="font-semibold text-red-500">{msg}</span>. */}
              {/* Please upgrade your account to {type} image! */}
            </>
          )}
        </div>
      </ModalComponent>
    </>
  );
};

export { ModalEditExceeds };
