import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import generateImgApis from "../../apis/generateImage";
import { FormArt, ModalView, TabsContent } from "../../components";
import { COMMON } from "../../constants";
import { useCommonContext } from "../../contexts/commonContext";
import { useGenerateImage } from "../../contexts/generateImageContext";
import { useModalContext } from "../../contexts/modalContext";
import { useToast } from "../../hooks/useToast/useToast";
import { InputText } from "../../types/generate";
import CatchError from "../../utils/catch-error";
import { getBase64 } from "../../utils/helpers";
import { Sidenav } from "./Sidenav";
import { BannerRight } from "./bannerRight/BannerRight";
import { IImageInfo } from "../../types/my-images";

export const Art: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<any>();

  const {
    setLoadingGenerate,
    setListImgAfterGenerate,
    setHiddenBtnEdit,
    listImgAfterGenerate,
    saveImage,
    formGenerate,
    setFormGenerate,
    promptEdit,
  } = useGenerateImage();
  const { modalEditImage, setModalRequiredLogin, setModalEditExceed, setModalSessionTimeout } = useModalContext();
  const { user } = useCommonContext();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [collapses, setCollapses] = useState<string | boolean>("");
  const [items, setItems] = useState<any>([]);
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [detail, setDetail] = useState<IImageInfo>();

  useEffect(() => {
    if (user.userInfo) {
      !user.userInfo.isProvidedPassword && navigate("/profile");
    }
  }, [user.userInfo]);

  const generateImage = async (data: { input: InputText; text: string; src_lang: string; base64_image?: any }) => {
    try {
      let res: any;
      if (data?.base64_image?.file) {
        const base64 = await getBase64(data.base64_image.file);
        await generateImgApis
          .generateImage({
            endPoint: COMMON.GENERATE_ENDPOINT.REIMAGE,
            data: {
              text: data.text,
              src_lang: data.src_lang,
              base64_image: base64,
              resolution: data?.input?.resolution,
              negative_prompt: data?.input?.negative_prompt,
            },
          })
          .then()
          .catch((error) => {
            toast.error(CatchError(error));
            setHiddenBtnEdit(false);
            setLoadingGenerate(false);
          });
      } else {
        await generateImgApis
          .generateImage({
            endPoint: COMMON.GENERATE_ENDPOINT.AIART,
            data: {
              text: data.text,
              src_lang: data.src_lang,
              resolution: data?.input?.resolution,
              negative_prompt: data?.input?.negative_prompt,
            },
          })
          .then()
          .catch((error) => {
            toast.error(CatchError(error));
            setHiddenBtnEdit(false);
            setLoadingGenerate(false);
          });
      }
      toast.success("In processing. Please wait for the result!");
    } catch (error: any) {
      toast.error(CatchError(error));
      setHiddenBtnEdit(false);
    }
  };

  const onSubmitContent: SubmitHandler<InputText> = async (dataInput: InputText) => {
    setListImgAfterGenerate([]);
    setLoadingGenerate(true);
    setFormGenerate(dataInput);
    if (typeof dataInput.language === "undefined") {
      dataInput.language = COMMON.LANGUAGE_IMAGE_VALUE.VI;
    }
    const mergeStyleAndPormpt =
      typeof dataInput?.style?.[0] === "undefined"
        ? `${dataInput?.prompt}`
        : `${dataInput?.style?.[0]} ${dataInput?.prompt}`;

    const mergeV4Style = `mdjrny-v4 style ${mergeStyleAndPormpt}`;
    if (!user.userToken) {
      setModalRequiredLogin(true);
      return;
    }
    setHiddenBtnEdit(true);
    generateImage({
      input: dataInput,
      text: mergeV4Style,
      src_lang: dataInput.language,
      base64_image: dataInput.file_input,
    });
  };

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const handleSaveCollections = useCallback(
    async (imgID: string) => {
      const image = listImgAfterGenerate.filter((img) => img.imgID === imgID)?.[0];
      if (image.like === false) {
        await saveImage(image.url, formGenerate, promptEdit);
        toast.success("Add to collections successfully !");
        const newList = listImgAfterGenerate.map((item) =>
          item.imgID === imgID ? { ...item, base64: item.url, like: true } : item,
        );
        setListImgAfterGenerate(newList);
      }
    },
    [listImgAfterGenerate, saveImage, setListImgAfterGenerate],
  );

  const handlerChangeItem = (item: string) => {
    setItems([item]);
    setValue("style", [item]);
  };

  const handleDetail = useCallback((value: any) => {
    const detailValue: any = {
      imageUrl: value.url,
      id: value.imgID,
      favorite: value.like,
    };
    setDetail(detailValue);
    setIsOpenModal(true);
  }, []);

  const isMobile = width <= 690;
  const valueForm = getValues();

  return (
    <>
      <div className="mt-28 mx-auto">
        <div className="w-full flex text-white gap-4 mb-10 px-[18px]">
          <Sidenav>
            <FormArt
              handleSubmit={handleSubmit}
              onSubmitContent={onSubmitContent}
              setValue={setValue}
              register={register}
              errors={errors}
            />
          </Sidenav>
          <div className="grow h-auto">
            <section className="col-span-5 h-full flex justify-center items-center bg-white/[2%] backdrop-blur-[24px] border-[rgb(42,50,64)]  border-[1px] rounded-lg">
              {!modalEditImage && (
                <TabsContent
                  isMobile={isMobile}
                  onSaveCollections={handleSaveCollections}
                  resolutionPresent={valueForm?.resolution || ""}
                  onClickDetail={handleDetail}
                />
              )}
            </section>
          </div>

          <Sidenav type="right" hidecollapsed={true}>
            <BannerRight
              collapes={collapses}
              setCollapes={setCollapses}
              items={items}
              handlerChangeItem={handlerChangeItem}
              errors={errors}
              register={register}
            />
          </Sidenav>
        </div>
      </div>
      <>
        {isOpenModal && detail && (
          <ModalView
            detail={detail}
            data={listImgAfterGenerate.map((item) => ({
              imageUrl: item.url,
              id: item.imgID,
              favorite: item.like,
            }))}
            onClickOutside={() => setIsOpenModal(false)}
            hideInformation={true}
          />
        )}
      </>
    </>
  );
};
