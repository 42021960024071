import { imagesCommon } from "../../assets/images";
import { IAboutUsData } from "../../types/about-us";

type Props = {
  information: IAboutUsData | undefined;
};

export const AboutDokpikAi: React.FC<Props> = ({ information }) => {
  return (
    <div className="bg-bggray text-white flex flex-col-reverse xl:gap-10 xl:flex xl:flex-row xl:justify-evenly rounded-xl text-4 md:text-[18px] p-4 md:p-9">
      <div className="mt-6 w-3/5 m-auto">
        <img
          src={information?.images[0] || imagesCommon.aboutUs05}
          alt="about_us_05"
          className="mx-auto translate-x-[-48px]"
        />
        <img
          src={information?.images[1] || imagesCommon.aboutUs06}
          alt="about_us_06"
          className="mt-[-30px] sm:mt-[-40px] mx-auto translate-x-6 sm:translate-x-16 max-w-[110%]"
        />
      </div>
      <div className="w-full xl:w-7/12 xl:max-w-2xl">
        <span className="text-primary font-bold">About Dokpik AI</span>
        <p className="text-2xl md:text-[32px] font-black leading-10 md:leading-[50px] my-3">{information?.title}</p>
        <div className="about_us" dangerouslySetInnerHTML={{ __html: information?.content as string }} />
      </div>
    </div>
  );
};
