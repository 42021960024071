import { useState } from "react";
import { favoriteApis } from "../../apis/image";
import { useCommonContext } from "../../contexts/commonContext";
import { useModalContext } from "../../contexts/modalContext";
import { IImageInfo } from "../../types/my-images";
import CatchError from "../../utils/catch-error";
import { useToast } from "../useToast/useToast";

const useFavorite = () => {
  const { setModalFavorite } = useModalContext();
  const { user, setListMyFavoriteImg, myImage } = useCommonContext();
  const { toast } = useToast();
  const [isLoaderFavorite, setIsLoaderFavorite] = useState<boolean>(false);
  const [dataAfterFavorite, setDataAfterFavorite] = useState<IImageInfo[]>([]);

  const handleToggleFavorite = (
    idImageToggle: string,
    available: boolean,
    dataArray: IImageInfo[],
    isMyImage?: any,
  ) => {
    const array = dataArray.map((ds: any) => {
      let favorite = ds.favorite;
      let numberOfLike = ds.numberOfLike;
      if (ds.id === idImageToggle) {
        ds.favorite ? numberOfLike : available ? numberOfLike : numberOfLike++;
        favorite = !ds.favorite;
      }
      return {
        ...ds,
        favorite,
        numberOfLike,
      };
    });
    const filterMyFavorite = myImage.listMyFavoriteImages.filter((value: any) => value.imageId !== idImageToggle);
    setDataAfterFavorite(array);
    isMyImage && setListMyFavoriteImg(filterMyFavorite);
  };

  const handleFavorite = (value: IImageInfo, dataArray: IImageInfo[], isMyImage?: any) => {
    if (!user.userInfo) {
      setModalFavorite(true);
      return;
    }
    if (value?.id) {
      setIsLoaderFavorite(true);
      favoriteApis
        .postFavorite({
          imageId: value.id,
        })
        .then((value: any) => {
          handleToggleFavorite(value.data.imageId, value.data.userId ? false : true, dataArray, isMyImage);
          setIsLoaderFavorite(false);
        })
        .catch((error: any) => {
          toast.error(CatchError(error));
        });
    }
  };

  return { isLoaderFavorite: isLoaderFavorite, handleFavorite, dataAfterFavorite };
};

export default useFavorite;
