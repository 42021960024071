import Compressor from "compressorjs";
import { PropsWithChildren, useState } from "react";
import generateImgApis from "../apis/generateImage";
import { GENERATE } from "../constants";
import { useSockets } from "../hooks/useSocket";
import { useToast } from "../hooks/useToast/useToast";
import { IImgEditType, IImgResult, InputText } from "../types/generate";
import CatchError from "../utils/catch-error";
import { createContext } from "../utils/createContext";
import { createFormData, urltoFile } from "../utils/helpers";
import { useCommonContext } from "./commonContext";

interface ContextProps {
  loadingGenerate: boolean;
  setLoadingGenerate: React.Dispatch<React.SetStateAction<boolean>>;
  hiddenBtnGenerate: boolean;
  setHiddenBtnGenerate: React.Dispatch<React.SetStateAction<boolean>>;
  hiddenBtnEdit: boolean;
  setHiddenBtnEdit: React.Dispatch<React.SetStateAction<boolean>>;
  formGenerate: InputText;
  setFormGenerate: React.Dispatch<React.SetStateAction<InputText>>;
  listImgAfterGenerate: IImgResult[];
  setListImgAfterGenerate: React.Dispatch<React.SetStateAction<IImgResult[]>>;
  imgEdit: IImgEditType | null;
  setImgEdit: React.Dispatch<React.SetStateAction<IImgEditType | null>>;
  editType: string;
  setEditType: React.Dispatch<React.SetStateAction<string>>;
  saveImage: (base64: string, description: InputText, promptEdit: string, editMode?: boolean) => Promise<void>;
  promptEdit: string;
  setPromptEdit: React.Dispatch<React.SetStateAction<string>>;
}
const initDescription = {
  id: "" || undefined,
  prompt: "",
  hashtag: "",
  style: [""],
  language: "",
  resolution: "",
  room: "",
  promptEdit: "" || undefined,
  file_input: undefined,
};
const [Provider, useGenerateImage] = createContext<ContextProps>();

export { useGenerateImage };

export default function GenerateImageContextProvider({ children }: PropsWithChildren) {
  const { user } = useCommonContext();
  const [loadingGenerate, setLoadingGenerate] = useState<boolean>(false);
  const [hiddenBtnGenerate, setHiddenBtnGenerate] = useState<boolean>(false);
  const [hiddenBtnEdit, setHiddenBtnEdit] = useState<boolean>(false);
  const [formGenerate, setFormGenerate] = useState<InputText>(initDescription);
  const [listImgAfterGenerate, setListImgAfterGenerate] = useState<IImgResult[]>([]);
  const [imgEdit, setImgEdit] = useState<IImgEditType | null>(null);
  const [editType, setEditType] = useState<string>(GENERATE.TYPE_EDIT.ADD);
  const [promptEdit, setPromptEdit] = useState<string>("");
  const { socket } = useSockets();
  const { toast } = useToast();
  const saveImage = async (base64: string, description: InputText, promptEdit: string, editMode?: boolean) => {
    const file = await urltoFile(base64, "new-image", "image/jpeg");
    new Compressor(file, {
      quality: 0.8,
      success: async (compressedImg) => {
        const formData = await createFormData(compressedImg, description, promptEdit);
        try {
          const response = await generateImgApis.saveImageCollections(formData);
          if (editMode) {
            socket.emit(
              "editImage",
              {
                userId: user.userInfo?.id,
                redirectURL: `/my-images?id=${response?.data?.id}#collections`,
              },
              user.userInfo?.id,
            );
          }
        } catch (error: any) {
          toast.error(CatchError(error));
        }
      },
    });
  };
  return (
    <Provider
      value={{
        loadingGenerate,
        setLoadingGenerate,
        hiddenBtnGenerate,
        setHiddenBtnGenerate,
        hiddenBtnEdit,
        setHiddenBtnEdit,
        formGenerate,
        setFormGenerate,
        listImgAfterGenerate,
        setListImgAfterGenerate,
        imgEdit,
        setImgEdit,
        editType,
        setEditType,
        saveImage,
        promptEdit,
        setPromptEdit,
      }}
    >
      {children}
    </Provider>
  );
}
