import React from "react";
import { MdFileDownload, MdMode, MdOutlineFavorite, MdVisibility } from "react-icons/md";
import { downloadImgToDevice } from "../../utils/helpers";
import { IImgResult } from "../../types/generate";

interface CardImageProps {
  className?: string;
  imgData: IImgResult;
  onClickSaveCollections: (imgID: string) => void;
  onClickEditImage: (id?: string) => void;
  onClickDetail?: any;
}

const CardImage: React.FC<CardImageProps> = (props) => {
  const { className, imgData, onClickSaveCollections, onClickEditImage, onClickDetail } = props;
  const { imgID, url, like } = imgData;

  return (
    <div className={`m-0 aspect-video rounded-xl group duration-300 cursor-pointer relative ${className} `}>
      <div
        className="flex opacity-0 group-hover:opacity-100 group-hover:translate-y-[-50%] translate-y-4 transition duration-300 ease-in-out 
       justify-center items-center absolute top-[50%] left-[50%] translate-x-[-50%]
       z-10
      "
      >
        <span
          className="w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-[#1d2939]
              hover:opacity-70 hover:scale-y-110 active:opacity-90 active:scale-y-120
              mx-[5px] my-0 flex items-center justify-center"
          onClick={() => onClickSaveCollections(imgID)}
        >
          <MdOutlineFavorite
            className={`icon ${
              like === true ? "text-red-500 cursor-not-allowed disabled" : "hover:text-red-400 cursor-pointer"
            }`}
          />
        </span>
        <span
          className="w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-[#1d2939]
              hover:opacity-70 hover:scale-y-110 active:opacity-90 active:scale-y-120
              mx-[5px] my-0 flex items-center justify-center"
          onClick={() => downloadImgToDevice(url)}
        >
          <MdFileDownload className="icon" />
        </span>

        <span
          className="w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-[#1d2939]
              hover:opacity-70 hover:scale-y-110 active:opacity-90 active:scale-y-120
              mx-[5px] my-0
              flex items-center justify-center"
          onClick={() => onClickEditImage(url)}
        >
          <MdMode className="icon" />
        </span>

        <span
          className="w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-[#1d2939]
              hover:opacity-70 hover:scale-y-110 active:opacity-90 active:scale-y-120
              mx-[5px] my-0 flex items-center justify-center"
          onClick={() => onClickDetail(imgData)}
        >
          <MdVisibility color="#fff" className="icon" />
        </span>
      </div>
      <div
        onClick={() => onClickDetail(imgData)}
        className=" 
      w-full h-full
      left-0 top-0  
      duration-300 ease-in-out absolute to-transparent bg-gradient-to-t
    group-hover:from-black/60
       opacity-0 group-hover:opacity-100 
      "
      />
      <img
        onClick={() => onClickDetail(imgData)}
        id={`img-id-${imgID}`}
        alt="img"
        className="rounded-lg object-cover w-full transition "
        src={url}
      />
    </div>
  );
};

export { CardImage };
