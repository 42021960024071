import classname from "classnames";
import { imagesPayment } from "../../../assets/images";
import { useModalContext } from "../../../contexts/modalContext";

export const ModalVNPay: React.FC = () => {
  const { modalVNPay, packageUpgrade, setModalVNPay } = useModalContext();
  return (
    <>
      <div className={classname("modal", modalVNPay && "modal-open")}>
        <div className="modal-box bg-[#1D2939] py-[30px] rounded-rddefault overflow-auto scrollbar w-full">
          <div className="px-[30px] flex flex-col items-center">
            <h3 className="text-2xl font-bold text-center text-tsecondary mb-[30px]">Payment via mobile app</h3>
            <img className="w-[270px] h-[300px] object-contain mb-[30px]" src={imagesPayment.vnPayQR} alt="image" />
            <p className="text-lg font-medium text-center text-tsecondary mb-[15px]">{`Payment for ${packageUpgrade?.title}`}</p>
            <p className="text-2xl font-bold text-center text-tsecondary mb-[15px]">{`$${packageUpgrade?.totalPrice}`}</p>
            <a
              className="text-primary text-sm font-medium mb-[30px]"
              href="http://dopikai.sotatek.works/"
              target="_blank"
            >
              Help for using QRCode?
            </a>
            <button
              onClick={() => setModalVNPay(false)}
              className="btn w-[240px] border border-bdinput border-solid px-10 py-3 text-base font-black self-center capitalize bg-[#1D2939] text-[#D0D5DD] transition-all hover:border-primary hover:bg-[#1D2939]"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
