import { FormDataLogin } from "../pages/authenicator/sign-in/SignIn";
import { FormDataRegister } from "../pages/authenicator/sign-up/SignUp";
import { IUserRegisterResponse } from "../types/auth";
import { axiosClient } from "./axiosClient";

const authApis = {
  login: (params: FormDataLogin) => axiosClient.post<any>(`/api/user/signin/`, params),
  singleSignOnSignin: (params: FormDataLogin) => axiosClient.post<any>(`/api/user/single-sign-on-signin/`, params),
  signUp: (params: FormDataRegister) => axiosClient.post<IUserRegisterResponse>(`/api/user/signup/`, params),
};

const userApis = {
  information: () => axiosClient.get<any>(`/api/user/infomation`),
  uploadImage: (image: any) => axiosClient.post(`/api/user/upload-avatar/`, image),
  updateInformation: (params: any) => axiosClient.put(`/api/user/update-profile/`, params),
  changePassword: (params: any) => axiosClient.post(`/api/user/change-password/`, params),
  changePasswordGoogle: (params: any) => axiosClient.post(`/api/user/new-password/`, params),
  resetPassword: (params: any) => axiosClient.post(`/api/user/reset-password/`, params),
  checkAndUpdatePassword: (email: any, params: any) =>
    axiosClient.post(`/api/user/check-update-password/${email}`, params),
};
export { authApis, userApis };
