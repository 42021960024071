import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ContactHeader, FormContact } from "../components";
import { useCommonContext } from "../contexts/commonContext";
export const PageContact = () => {
  const { user } = useCommonContext();
  const navigate = useNavigate();
  useEffect(() => {
    if (user.userInfo) {
      !user.userInfo.isProvidedPassword && navigate("/profile");
    }
  }, [user.userInfo]);

  return (
    <>
      <div className="mx-3 lg:mx-0">
        <ContactHeader />
        <div className="max-w-screen-2xl mx-auto h-full min-h-[500px] mb-[73px]">
          <div className="h-full mt-[200px] bg-white/10 rounded-2xl px-8 md:px-[8%] lg:px-[3%] py-6 text-white">
            <p className="font-black text-[32px]">Ready to build Better Software?</p>
            <p className="text-[18px] mt-[20px] font-normal">
              Fill out the form below and we will get in touch with you shortly.
            </p>
            <FormContact />
          </div>
        </div>
      </div>
    </>
  );
};
