import { IMyImageParams } from "../types/my-images";
import { axiosClient } from "./axiosClient";

const myImagesApis = {
  publicImgUser: (data: { imageId: string }) => axiosClient.post<any>(`/api/image/toggle-public-image`, data),
  deleteImgUser: (data: { imageId: string }) => axiosClient.post<any>(`/api/image/delete`, data),
  getMyImages: (params: IMyImageParams) => axiosClient.get<any>(`/api/image/my-image/`, { params }),
};

const collectionsApis = {
  getItem: (params: any) => axiosClient.get(`/api/image/`, { params }),
  getParamSearch: (prompt: any) => {
    return axiosClient.get(`/api/image/suggest-prompt`, { params: { prompt } });
  },
};

const favoriteApis = {
  getMyFavoriteImages: (params: IMyImageParams) => axiosClient.get<any>(`/api/image/my-image-favorite/`, { params }),
  postFavorite: (body: any) => axiosClient.post(`/api/image/toggle-favorite-image`, body),
};

export { myImagesApis, collectionsApis, favoriteApis };
