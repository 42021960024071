import React, { useState } from "react";
import { RxDoubleArrowLeft, RxDoubleArrowRight } from "react-icons/rx";

interface Props {
  children?: any;
  className?: string;
  type?: string;
  hidecollapsed?: boolean;
}

const Sidenav: React.FC<Props> = ({ children, className, type, hidecollapsed }) => {
  const [collapsed, setCollapsed] = useState(hidecollapsed);

  const handleOnClick = () => {
    setCollapsed(!collapsed);
  };

  const classss = collapsed ? "w-[0px]" : "w-[300px]";
  const isTypeRight = type === "right";
  return (
    <div className={`${className} relative`}>
      <div className={`${classss}  relative duration-300 mx-auto h-full`}>
        <div className={`w-[300px] h-full duration-300 ${collapsed && !isTypeRight && "translate-x-[-100%]"}`}>
          <>{children}</>
        </div>
      </div>

      <div
        onClick={handleOnClick}
        className={`
        absolute top-[40vh] ${isTypeRight ? "left-[0px]" : "right-[0px]"}
       bg-[#ffffff05] rounded-[50%] w-[35px] h-[35px] 
       flex items-center justify-center
       ${isTypeRight ? "translate-x-[-50%]" : "translate-x-[50%]"}
       cursor-pointer
       border-[2px]
       border-solid
       border-[#667085]
       z-[1]
       `}
      >
        {collapsed ? (
          <>
            {isTypeRight ? (
              <RxDoubleArrowLeft color="#667085" size={20} />
            ) : (
              <RxDoubleArrowRight color="#667085" size={20} />
            )}
          </>
        ) : (
          <>
            {isTypeRight ? (
              <RxDoubleArrowRight color="#667085" size={20} />
            ) : (
              <RxDoubleArrowLeft color="#667085" size={20} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export { Sidenav };
