import { memo, useMemo } from "react";
import { MessageError } from "./MessageError";
import cn from "classnames";
type IPropsInput = {
  label?: string;
  placeholder?: string;
  register: any;
  isRequired?: boolean;
  type?: string;
  errors?: any;
  textarea?: boolean;
  classnamesProp?: string;
  onFocus?: () => void;
  onChange?: any;
  errorsMessage?: string;
};
export const InputContact: React.FC<IPropsInput> = memo(
  ({
    label = "",
    register,
    placeholder,
    type = "text",
    errors,
    textarea,
    isRequired,
    classnamesProp = "",
    onFocus,
    onChange,
    errorsMessage = "",
  }) => {
    const renderTextArea = useMemo(
      () => (
        <textarea
          {...register}
          placeholder={placeholder}
          onFocus={onFocus}
          id={label}
          className={cn(
            "h-[193px] rounded-xl bg-transparent py-2  border-[1px] px-4 outline-none",
            errors ? "border-red-400" : "border-[#667085]",
          )}
          onChange={onChange}
        />
      ),
      [placeholder, label, errors],
    );
    const renderInput = useMemo(
      () => (
        <input
          {...register}
          placeholder={placeholder}
          id={label}
          type={type}
          className={`h-[48px] focus:outline-none  border-solid rounded-xl bg-transparent  border-[1px] px-4 ${
            errors ? "border-red-400" : "border-[#667085]"
          }`}
        />
      ),
      [placeholder, label, type, errors],
    );
    return (
      <div className={`w-full flex flex-col  mt-4 lg:mt-0 ${classnamesProp}`}>
        {label && (
          <label htmlFor={label} className="mb-2 text-white/60 font-bold text-[18px]">
            {label} {isRequired && <span className="text-red-600">*</span>}
          </label>
        )}

        {textarea ? renderTextArea : renderInput}
        {errors && <MessageError message={errors?.message || errorsMessage} />}
      </div>
    );
  },
);
