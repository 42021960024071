import { useNavigate } from "react-router-dom";
import { useCommonContext } from "../../contexts/commonContext";
import { useModalContext } from "../../contexts/modalContext";
import { ModalComponent } from "../commons/Modal";

const ModalSessionTimeout: React.FC = () => {
  const navigate = useNavigate();
  const { setModalSessionTimeout } = useModalContext();
  const { handleUserLogout } = useCommonContext();
  return (
    <ModalComponent
      title="Session timeout"
      btnText="OK"
      onClose={() => {
        handleUserLogout();
        setModalSessionTimeout(false);
        navigate("/sign-in");
      }}
    >
      <div>Session timeout. Please login again!</div>
    </ModalComponent>
  );
};

export { ModalSessionTimeout };
