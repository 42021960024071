import { imagesCommon } from "../assets/images";
import { LANGUAGE_IMAGE, LANGUAGE_IMAGE_VALUE } from "./common.constants";
import { INTERIOR_STYLE, ROOM } from "./interior.constants";

export interface OptionItem {
  name: string;
  value: string;
  img?: string;
}

export const optionItems: OptionItem[] = [
  {
    name: LANGUAGE_IMAGE.VI,
    value: LANGUAGE_IMAGE_VALUE.VI,
    img: imagesCommon.vi,
  },
  {
    name: LANGUAGE_IMAGE.EN,
    value: LANGUAGE_IMAGE_VALUE.EN,
    img: imagesCommon.en,
  },
];

export const optionItemsResolution: OptionItem[] = [
  {
    name: "Square",
    value: "Square",
  },
  {
    name: "Portrait",
    value: "Portrait",
  },
  {
    name: "Landscape",
    value: "Landscape",
  },
];

export const optionItemsRoom: OptionItem[] = [
  {
    name: ROOM.LIVING,
    value: ROOM.LIVING,
  },
  {
    name: ROOM.BED,
    value: ROOM.BED,
  },
  {
    name: ROOM.BATH,
    value: ROOM.BATH,
  },
  {
    name: ROOM.ATTIC,
    value: ROOM.ATTIC,
  },
  {
    name: ROOM.KITCHEN,
    value: ROOM.KITCHEN,
  },
  {
    name: ROOM.DINING,
    value: ROOM.DINING,
  },
  {
    name: ROOM.STUDY,
    value: ROOM.STUDY,
  },
  {
    name: ROOM.HOME_OFFICE,
    value: ROOM.HOME_OFFICE,
  },
  {
    name: ROOM.GAMING,
    value: ROOM.GAMING,
  },
  {
    name: ROOM.OUTDOOR_POOL_AREA,
    value: ROOM.OUTDOOR_POOL_AREA,
  },
  {
    name: ROOM.OUTDOOR_PATIO,
    value: ROOM.OUTDOOR_PATIO,
  },
  {
    name: ROOM.OUTDOOR_GARDEN,
    value: ROOM.OUTDOOR_GARDEN,
  },
  {
    name: ROOM.MEETING,
    value: ROOM.MEETING,
  },
  {
    name: ROOM.WORKSHOP,
    value: ROOM.WORKSHOP,
  },
  {
    name: ROOM.FITNESS_GYM,
    value: ROOM.FITNESS_GYM,
  },
  {
    name: ROOM.COFFEE_SHOP,
    value: ROOM.COFFEE_SHOP,
  },
  {
    name: ROOM.CLOTHING_STORE,
    value: ROOM.CLOTHING_STORE,
  },
  {
    name: ROOM.WALK_CLOSET,
    value: ROOM.WALK_CLOSET,
  },
  {
    name: ROOM.TOILET,
    value: ROOM.TOILET,
  },
  {
    name: ROOM.RESTAURANT,
    value: ROOM.RESTAURANT,
  },
  {
    name: ROOM.OFFICE,
    value: ROOM.OFFICE,
  },
  {
    name: ROOM.COWORKING_SPACE,
    value: ROOM.COWORKING_SPACE,
  },
  {
    name: ROOM.HOTEL_LOBB,
    value: ROOM.HOTEL_LOBB,
  },
  {
    name: ROOM.HOTEL_ROOM,
    value: ROOM.HOTEL_ROOM,
  },
  {
    name: ROOM.HOTEL_BATHROOM,
    value: ROOM.HOTEL_BATHROOM,
  },
  {
    name: ROOM.EXHIBITION_SPACE,
    value: ROOM.EXHIBITION_SPACE,
  },
  {
    name: ROOM.ONSEN,
    value: ROOM.ONSEN,
  },
  {
    name: ROOM.MUDROOM,
    value: ROOM.MUDROOM,
  },
  {
    name: ROOM.DROP_ZONE,
    value: ROOM.DROP_ZONE,
  },
];

export const optionStyleInteriorItems = [
  {
    name: INTERIOR_STYLE.ART_DECO,
    value: INTERIOR_STYLE.ART_DECO,
  },
  {
    name: INTERIOR_STYLE.ART_NOUVEAU,
    value: INTERIOR_STYLE.ART_NOUVEAU,
  },
  {
    name: INTERIOR_STYLE.BAROQUE,
    value: INTERIOR_STYLE.BAROQUE,
  },
  {
    name: INTERIOR_STYLE.BIOPHILIC,
    value: INTERIOR_STYLE.BIOPHILIC,
  },
  {
    name: INTERIOR_STYLE.BOHEMIAN,
    value: INTERIOR_STYLE.BOHEMIAN,
  },
  {
    name: INTERIOR_STYLE.CHINESE_NEW_YEAR,
    value: INTERIOR_STYLE.CHINESE_NEW_YEAR,
  },
  {
    name: INTERIOR_STYLE.CHRISTMAS,
    value: INTERIOR_STYLE.CHRISTMAS,
  },
  {
    name: INTERIOR_STYLE.COASTAL,
    value: INTERIOR_STYLE.COASTAL,
  },
  {
    name: INTERIOR_STYLE.CONTEMPORARY,
    value: INTERIOR_STYLE.CONTEMPORARY,
  },
  {
    name: INTERIOR_STYLE.CYBERPUNK,
    value: INTERIOR_STYLE.CYBERPUNK,
  },
  {
    name: INTERIOR_STYLE.EASTER,
    value: INTERIOR_STYLE.EASTER,
  },
  {
    name: INTERIOR_STYLE.FARMHOUSE,
    value: INTERIOR_STYLE.FARMHOUSE,
  },
  {
    name: INTERIOR_STYLE.FRENCH_COUNTRY,
    value: INTERIOR_STYLE.FRENCH_COUNTRY,
  },
  {
    name: INTERIOR_STYLE.GAMING,
    value: INTERIOR_STYLE.GAMING,
  },
  {
    name: INTERIOR_STYLE.HALLOWEEN,
    value: INTERIOR_STYLE.HALLOWEEN,
  },
  {
    name: INTERIOR_STYLE.INTERIOR_AI,
    value: INTERIOR_STYLE.INTERIOR_AI,
  },
  {
    name: INTERIOR_STYLE.JAPANESE_DESIGN,
    value: INTERIOR_STYLE.JAPANESE_DESIGN,
  },
  {
    name: INTERIOR_STYLE.MAXIMALIST,
    value: INTERIOR_STYLE.MAXIMALIST,
  },
  {
    name: INTERIOR_STYLE.MEDIEVAL,
    value: INTERIOR_STYLE.MEDIEVAL,
  },
  {
    name: INTERIOR_STYLE.MIDCENTURY_MODERN,
    value: INTERIOR_STYLE.MIDCENTURY_MODERN,
  },
  {
    name: INTERIOR_STYLE.MINIMALIST,
    value: INTERIOR_STYLE.MINIMALIST,
  },
  {
    name: INTERIOR_STYLE.MODERN,
    value: INTERIOR_STYLE.MODERN,
  },
  {
    name: INTERIOR_STYLE.NEOCLASSIC,
    value: INTERIOR_STYLE.NEOCLASSIC,
  },
  {
    name: INTERIOR_STYLE.RUSTIC,
    value: INTERIOR_STYLE.RUSTIC,
  },
  {
    name: INTERIOR_STYLE.SCANDINAVIAN,
    value: INTERIOR_STYLE.SCANDINAVIAN,
  },
  {
    name: INTERIOR_STYLE.SKETCH,
    value: INTERIOR_STYLE.SKETCH,
  },
  {
    name: INTERIOR_STYLE.SKI_CHALET,
    value: INTERIOR_STYLE.SKI_CHALET,
  },
  {
    name: INTERIOR_STYLE.TRIBAL,
    value: INTERIOR_STYLE.TRIBAL,
  },
  {
    name: INTERIOR_STYLE.TROPICAL,
    value: INTERIOR_STYLE.TROPICAL,
  },
  {
    name: INTERIOR_STYLE.VAPORWAVE,
    value: INTERIOR_STYLE.VAPORWAVE,
  },
  {
    name: INTERIOR_STYLE.VINTAGE,
    value: INTERIOR_STYLE.VINTAGE,
  },
  {
    name: INTERIOR_STYLE.ZEN,
    value: INTERIOR_STYLE.ZEN,
  },
];
