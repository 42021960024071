// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_APP = "";

// ----------------------------------------------------------------------

export const PATH = {
  home: path(ROOTS_APP, ""),
  art: path(ROOTS_APP, "/art"),
  myImage: path(ROOTS_APP, "/my-images"),
  interior: path(ROOTS_APP, "/interior"),
  collections: path(ROOTS_APP, "/collections"),
  generate: path(ROOTS_APP, "/generate"),
  contact: path(ROOTS_APP, "/contact"),
  aboutus: path(ROOTS_APP, "/about-us"),
  pricing: path(ROOTS_APP, "/pricing"),
};
