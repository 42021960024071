import { ElementType, lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import { Collections } from "../components/home/collections/Collections";
import { AboutUs } from "../pages/AboutUs";
import { Art } from "../pages/art/Art";
import { ResetPassword } from "../pages/authenicator/reset-pwd/ResetPassword.tsx";
import { SignIn } from "../pages/authenicator/sign-in/SignIn";
import { SignUp } from "../pages/authenicator/sign-up/SignUp";
import { PageContact } from "../pages/Contact";
import { DetailImage } from "../pages/detail-image/DetailImage";
import { Home } from "../pages/Home";
import { MyImages } from "../pages/MyImages";
import { Profile } from "../pages/Profile";
import { ResultPayment } from "../pages/ResultPayment";

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<h1>Loading...</h1>}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <LayoutNormal />,
      children: [
        {
          element: <Home />,
          index: true,
        },
        { path: "", element: <Home /> },
        { path: "art", element: <Art /> },
        // { path: "interior", element: <Interior /> },
        { path: "collections", element: <Collections /> },
        { path: "about-us", element: <AboutUs /> },
        { path: "contact", element: <PageContact /> },
        // { path: "pricing", element: <Pricing /> },
        { path: "sign-in", element: <SignIn /> },
        { path: "sign-up", element: <SignUp /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "profile", element: <Profile /> },
        { path: "my-images", element: <MyImages /> },
        { path: "detail-image/:id", element: <DetailImage /> },
        { path: "result-payment/:id", element: <ResultPayment /> },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);
}

const LayoutNormal = Loadable(lazy(() => import("../layout/LayoutNormal")));
const NotFound = Loadable(lazy(() => import("../pages/404")));
