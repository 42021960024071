export const imagesInteriorBanner = {
  background: require("./banner/Background.png"),
  image1: require("./banner/image1.png"),
  image2: require("./banner/image2.png"),
  image3: require("./banner/image3.png"),
  image4: require("./banner/image4.png"),
  logoBanner: require("./banner/logoBanner.png"),
};

export const imagesInterior = {
  art: require("./Art.jpg"),
  artNouveau: require("./ArtNouveau.jpg"),
  baroque: require("./Baroque.jpg"),
  bioPhilic: require("./BioPhilic.jpg"),
  bohemian: require("./Bohemian.jpg"),
  chineseNewyear: require("./ChineseNewyear.jpg"),
  christmas: require("./Christmas.jpg"),
  coastal: require("./Coastal.jpg"),
  contemporary: require("./Contemporary.jpg"),
  cyberpunk: require("./Cyberpunk.jpg"),
  easter: require("./Easter.jpg"),
  farmHouse: require("./FarmHouse.jpg"),
  french: require("./French.jpg"),
  gaming: require("./Gaming.jpg"),
  halloween: require("./Halloween.jpg"),
  iconUpLoad: require("./iconUpload.svg"),
  interiorAi: require("./InteriorAi.jpg"),
  japanese: require("./Japanese.jpg"),
  maximalist: require("./Maximalist.jpg"),
  medieval: require("./Medieval.jpg"),
  midcentury: require("./Midcentury.jpg"),
  minimalist: require("./Minimalist.jpg"),
  modern: require("./Modern.jpg"),
  neoclassic: require("./Neoclassic.jpg"),
  rustic: require("./Rustic.jpg"),
  scandinavian: require("./Scandinavian.jpg"),
  sketch: require("./Sketch.jpg"),
  skiChalet: require("./SkiChalet.jpg"),
  tribal: require("./Tribal.jpg"),
  tropical: require("./Tropical.jpg"),
  vaporware: require("./vaporware.jpg"),
  vintage: require("./Vintage.jpg"),
  zen: require("./Zen.jpg"),
};
