import { createContext, ReactNode, useContext } from "react";
import { io, Socket } from "socket.io-client";

interface IInitContext {
  socket: Socket;
}

interface Props {
  children: ReactNode;
}

const socket = io(`${process.env.REACT_APP_BE_URL}`);
const SocketContext = createContext<IInitContext>({
  socket,
});

const SocketsProvider: React.FC<Props> = ({ children }) => {
  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};

export const useSockets = () => {
  const value = useContext(SocketContext);
  return {
    ...value,
  };
};

export default SocketsProvider;
