import { useCallback, useState } from "react";
import { userApis } from "../../apis/auth";
import { imagesCommon } from "../../assets/images";
import { useCommonContext } from "../../contexts/commonContext";
import { useToast } from "../../hooks/useToast/useToast";
import CatchError from "../../utils/catch-error";
import { ModalComponent } from "../commons/Modal";

export const UserInfo: React.FC = () => {
  const { user, getUserInformation } = useCommonContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalErrorFileType, setModalErrorFileType] = useState<boolean>(false);
  const { toast } = useToast();

  const handleImageChange = async (e: any) => {
    setLoading(true);
    const file = e.target.files[0];
    const fileTypeList = ["svg", "png", "jpg", "jpeg", "webp"];
    const fileType = file.name.split(".").pop() || "";
    if (fileTypeList.indexOf(fileType) === -1) {
      setModalErrorFileType(true);
      setLoading(false);
    } else {
      const bodyFormData = new FormData();
      bodyFormData.append("file", file);
      try {
        const response = (await userApis.uploadImage(bodyFormData)) as any;
        if (response.data) {
          getUserInformation();
          toast.success("Your profile image has been updated!");
        }
      } catch (error: any) {
        toast.error(CatchError(error));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSetModalErrorFileType = useCallback(() => {
    setModalErrorFileType(false);
  }, []);

  return (
    <div className="flex flex-col items-center mb-6">
      {modalErrorFileType && (
        <ModalComponent title="Upload File Error" onClose={handleSetModalErrorFileType}>
          <div>Type of file must be png, jpg, jpeg or svg</div>
        </ModalComponent>
      )}
      <figure className="px-5 pt-5">
        <div className={`avatar group ${loading && "pointer-events-none"}`}>
          <div className="w-40 rounded-full">
            <>
              <div className="rounded-full w-full h-full z-20 opacity-0 group-hover:opacity-100 transition duration-300 ease-in-out absolute from-gray-400/80 to-transparent bg-gradient-to-t inset-x-0 pt-30 flex flex-col justify-center items-center cursor-pointer">
                <label
                  htmlFor="avatars-file"
                  className="transform-gpu flex justify-center items-center mt-3 w-full h-full group-hover:opacity-100 group-hover:translate-y-0 translate-y-4 transition duration-300 ease-in-out cursor-pointer"
                >
                  <div className="flex flex-col justify-center items-center pt-5 pb-6">
                    <img className="!w-10 !h-10" src={imagesCommon.camera} alt="camera" />
                  </div>
                  <input id="avatars-file" type="file" className="hidden" onChange={handleImageChange} />
                </label>
              </div>
              <img
                className="object-cover rounded-lg w-full aspect-square  transition duration-300 ease-in-out"
                src={user.userInfo?.thumbnailUrl ? user.userInfo.thumbnailUrl : imagesCommon.noneAvatar}
                alt="avatar"
              />
            </>
          </div>
        </div>
      </figure>
      <div className="card-body items-center overflow-hidden p-0 pt-5">
        <h2 className="card-title text-center text-tsecondary font-black text-2xl">{user.userInfo?.name}</h2>
      </div>
    </div>
  );
};
