import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { MdOutlineFavorite } from "react-icons/md";
import { IImageInfo } from "../../../types/my-images";
import { imagesCommon } from "../../../assets/images";

interface Props {
  data: IImageInfo[];
  indexImg: number;
  scale: number;
  rotate: number;
  clickNext: () => void;
  clickPrevious: () => void;
  hideInformation?: boolean;
}

const ModalBody: React.FC<Props> = ({ data, indexImg, scale, rotate, clickNext, clickPrevious, hideInformation }) => {
  const name = data[indexImg]?.owner?.name;
  const imageUrl = data[indexImg]?.imageUrl || data[indexImg]?.base64;
  const numberOfLike = data[indexImg]?.numberOfLike;
  const thumbnailUrl = data[indexImg]?.owner?.thumbnailUrl;

  return (
    <div className="inline-block relative overflow-hidden">
      <img
        style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
        className="object-fill mx-auto duration-300 w-full"
        src={imageUrl}
        alt="..."
      />
      {!hideInformation && (
        <div className="absolute w-full flex items-center bottom-[7px] justify-between px-[14px]">
          <div className="flex items-center">
            <div className="w-[50px] h-[50px] border border-solid border-[#f1f1f2] rounded-[50%]">
              <img
                className="w-full h-full rounded-[50%] object-fill"
                src={thumbnailUrl ? thumbnailUrl : imagesCommon.noneAvatar}
                alt="..."
              />
            </div>
            <div style={{ textShadow: "1px 1px 3px #060606" }} className="text-[#fff] font-medium ml-[7px]">
              {name}
            </div>
          </div>
          <div className="flex justify-center items-center">
            <span className="flex flex-col justify-center items-center text-[30px] text-red-500">
              <MdOutlineFavorite />
            </span>
            <span style={{ textShadow: "1px 1px 3px #060606" }} className="text-[#fff] ml-[7px] font-medium">
              {numberOfLike}
            </span>
          </div>
        </div>
      )}

      {/* ------------------------------------------------------------------------------------------ */}
      <>
        <button
          disabled={indexImg < 1}
          className={`${indexImg < 1 && "bg-[#f3f4f64d] text-[#f3f4f64d] cursor-no-drop"} left-[5px] 
          absolute top-[50%] translate-y-[-50%]
          flex justify-center items-center w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] bg-[#1d2939]
              hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-y-120 `}
          onClick={clickNext}
        >
          <AiOutlineArrowLeft />
        </button>
        <button
          disabled={indexImg >= data.length - 1}
          className={`${indexImg >= data.length - 1 && "bg-[#f3f4f64d] text-[#f3f4f64d] cursor-no-drop"}
          right-[5px] 
          absolute top-[50%] translate-y-[-50%]
          flex justify-center items-center w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] bg-[#1d2939]
          hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-y-120`}
          onClick={clickPrevious}
        >
          <AiOutlineArrowRight />
        </button>
      </>
    </div>
  );
};

export { ModalBody };
