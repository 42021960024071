import { useMemo } from "react";
import { imagesCommon } from "../../../assets/images";
import { useCommonContext } from "../../../contexts/commonContext";
import { IImageInfo } from "../../../types/my-images";
import { Favorite } from "../Favorite";
interface Props {
  item: IImageInfo;
  type: string;
  setListHashtag?: React.Dispatch<React.SetStateAction<string[]>>;
  listHashtag: any;
}
const DetailBottom = (props: Props) => {
  const { collectionImages, setCollectionImages, setMyImage, myImage } = useCommonContext();
  const { item, type, setListHashtag, listHashtag } = props;

  const handleHashtag = (hashTag: string[]) => {
    if (type === "homepage" && !listHashtag?.includes(hashTag) && setListHashtag) {
      setListHashtag([...listHashtag, hashTag]);
    }
    return;
  };
  const renderFavoriteInfo = useMemo(
    () =>
      type === "homepage" ? (
        <Favorite
          item={item}
          dataArray={collectionImages}
          toggleFavorite={(array: IImageInfo[]) => setCollectionImages(array)}
        />
      ) : (
        <Favorite
          isMyImage={true}
          item={item}
          dataArray={myImage.listMyImages}
          toggleFavorite={(array: IImageInfo[]) => setMyImage((pre) => ({ ...pre, listMyImages: array }))}
        />
      ),
    [type, item, collectionImages, myImage.listMyImages],
  );
  const renderListHashtag = useMemo(
    () =>
      item.hashtagList &&
      item.hashtagList?.length > 0 && (
        <div className="flex justify-between items-center w-full">
          <div className="text-slate-400">
            {item.hashtagList.map((item: any, index: any) => (
              <div className="cursor-pointer inline mr-2" key={index} onClick={() => handleHashtag(item)}>
                {item}
              </div>
            ))}
          </div>
        </div>
      ),
    [item, handleHashtag],
  );
  return (
    <div className="w-full min-h-[100px] bg-bggray">
      <div className="flex justify-between items-center w-full mt-[6px]">
        <div className="text-start">
          <div className="text-tprimary">Created by</div>
          <div className="flex items-center mt-[8px]">
            <div className="border border-[#f1f1f2] rounded-[50%] flex justify-center items-center">
              <img
                className="w-[35px] h-[35px] rounded-[50%] object-cover"
                src={item.owner?.thumbnailUrl ? item.owner?.thumbnailUrl : imagesCommon.noneAvatar}
              />
            </div>
            <div className="ml-[7px]">
              <div className="text-[#fff] font-black">{item.owner?.name}</div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-end">
          <div className="text-tprimary">Favorites</div>
          <div className="flex justify-center text-[24px] text-[#fff] font-black mt-[8px]">
            {renderFavoriteInfo}
            <span className="text-lg ml-1">{item.numberOfLike || 0}</span>
          </div>
        </div>
      </div>
      {renderListHashtag}
    </div>
  );
};

DetailBottom.propTypes = {};

export { DetailBottom };
