import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import aboutUsApis from "../apis/aboutUs";
import { AboutDokpikAi, AboutSotatek, Loader } from "../components";
import { useCommonContext } from "../contexts/commonContext";
import { useToast } from "../hooks/useToast/useToast";
import { IAboutUsData } from "../types/about-us";

export const AboutUs = () => {
  const { user } = useCommonContext();
  const [data, setData] = useState<IAboutUsData[] | undefined>();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (user.userInfo) {
      !user.userInfo.isProvidedPassword && navigate("/profile");
    }
  }, [user.userInfo]);

  useEffect(() => {
    fetchDataFromServer();
  }, []);

  const fetchDataFromServer = async () => {
    try {
      const response = await aboutUsApis.getContentAboutUs();
      setData(response.data);
    } catch (error: any) {
      error?.message ? toast.error(error?.message) : toast.error("An error occurred, please try again!");
    }
  };

  if (!data)
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Loader />
      </div>
    );

  return (
    <div className=" mx-auto max-w-screen-2xl mt-24 md:mt-36 mb-24">
      <AboutSotatek information={data && data[0]} />
      <AboutDokpikAi information={data && data[1]} />
    </div>
  );
};
