import { useState } from "react";
import { FieldError } from "react-hook-form";
import { BiShow, BiHide } from "react-icons/bi";
import { imagesCommon } from "../../assets/images";

interface Props {
  inputAttribute: any;
  placeholder: string;
  error: FieldError | undefined;
  className?: string;
}

const InputPassword: React.FC<Props> = ({ inputAttribute, error, placeholder, className }) => {
  const [isPassword, setIsPassword] = useState<boolean>(true);

  const toggle = () => setIsPassword(!isPassword);

  return (
    <div className={`relative mt-5 ${className}`}>
      <img
        className="absolute left-4 top-1/2 -translate-y-1/2 object-contain w-5 h-5"
        src={imagesCommon.passwordIcon}
        alt="email"
      />
      <input
        {...inputAttribute}
        type={isPassword ? "password" : "text"}
        autoComplete="off"
        placeholder={placeholder}
        className={`input w-full focus:outline-none bg-bggrayprimary border border-solid border-bdinput rounded-rddefault text-tsecondary px-12 ${
          error && "border-red-600"
        }`}
      />
      <span onClick={toggle} className="absolute top-1/2 right-0 -translate-y-1/2 px-5 cursor-pointer text-gray-300">
        {isPassword ? <BiHide className="text-xl" /> : <BiShow className="text-xl" />}
      </span>
    </div>
  );
};

export { InputPassword };
