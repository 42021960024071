import classname from "classnames";
import { paymentApis } from "../../../apis/payment";
import { imagesCommon } from "../../../assets/images";
import { IPaymentMethod, PaymentMethod } from "../../../constants/payment.constants";
import { useCommonContext } from "../../../contexts/commonContext";
import { useModalContext } from "../../../contexts/modalContext";
import { useToast } from "../../../hooks/useToast/useToast";
import { PaymentType } from "../../../types/payment";

export const ModalPayment: React.FC = () => {
  const { user } = useCommonContext();
  const {
    modalPayment,
    methodPayment,
    packageUpgrade,
    setMethodPayment,
    setModalPayment,
    setModalCreditCard,
    setModalBankTransfer,
  } = useModalContext();
  const { toast } = useToast();

  const handleChooseMethodPayment = (type: PaymentType) => {
    setMethodPayment(type);
  };

  const handleContinueChooseMethodPayment = async () => {
    if (methodPayment === PaymentType.CreditCard) {
      setModalPayment(false);
      setModalCreditCard(true);
    } else if (methodPayment === PaymentType.VNPay) {
      try {
        const response = await paymentApis.createPayment({
          amount: packageUpgrade && packageUpgrade?.totalPrice,
          orderDescription: `${packageUpgrade?.id}-${user.userInfo?.id}`,
          orderType: "billpayment",
          language: "vn",
          bankCode: "",
        });
        window.location = response.data.url;
      } catch (error: any) {
        error?.message ? toast.error(error?.message) : toast.error("An error occurred, please try again!");
      }
    } else {
      setModalPayment(false);
      setModalBankTransfer(true);
    }
  };

  return (
    <>
      <div className={classname("modal", modalPayment && "modal-open")}>
        <div className="modal-box md:min-w-[700px] bg-[#1D2939] p-0 rounded-rddefault">
          <img
            className="w-6 h-6 ml-auto mt-[10px] mr-[10px] hover:scale-105 transition-all cursor-pointer"
            onClick={() => setModalPayment(false)}
            src={imagesCommon.closeIcon}
            alt="close"
          />
          <div className="px-[30px] pb-[30px] flex flex-col">
            <h3 className="text-2xl font-bold text-center text-tsecondary mb-[10px]">Payment</h3>
            <p className="font-normal text-base text-tlabel text-center mb-10">Choose a payment method </p>
            <div className="grid grid-cols-12 gap-[28px] mb-[50px] ml-auto mr-auto ">
              {/* Credit Card */}
              {PaymentMethod.map((item: IPaymentMethod) => (
                <div
                  key={item.type}
                  onClick={() => handleChooseMethodPayment(item.type)}
                  className={`col-span-12 md:col-span-4 h-[161px] w-full border-2 border-solid border-bdinput ${
                    item.type === methodPayment && "!border-primary"
                  } rounded-rddefault flex items-center justify-center cursor-pointer transition-all`}
                >
                  <div className="flex flex-col justify-center items-center">
                    <img className="w-24 h-16 object-contain" src={item.url} alt="credit" />
                    <p className="text-base font-normal text-tsecondary text-center">{item.content}</p>
                  </div>
                </div>
              ))}
            </div>

            <button
              onClick={handleContinueChooseMethodPayment}
              className="btn px-10 py-3 text-base font-black self-center capitalize bg-primary text-white transition-all hover:bg-bgbtn"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
