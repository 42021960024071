import { useState } from "react";
import { FormCheckAndUpdatePwd } from "./FormCheckAndUpdatePwd";
import { FormVerifyEmail } from "./FormVerifyEmail";

export const ResetPassword: React.FC = () => {
  const [isSendSecretCode, setIsSendSecretCode] = useState<boolean>(false);

  return (
    <div className="flex justify-center pt-28 mb-14 mx-3 lg:mx-0">
      <section className="bg-bgdark w-full lg:w-auto lg:max-w-screen-2xl flex justify-center items-center lg:px-8">
        <div className="card w-full lg:w-full lg:card-side lg:card-normal bg-base-100 rounded-[10px] md:min-w-[600px] md:max-w-[650px]">
          <div className="card-body justify-center bg-bggrayprimary">
            {isSendSecretCode ? (
              <FormCheckAndUpdatePwd />
            ) : (
              <FormVerifyEmail setIsSendSecretCode={setIsSendSecretCode} />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};
