import { imagesCommon } from "../assets/images";
import { imagesInterior } from "../assets/images/interior";
import { STYLE_IMAGE } from "./common.constants";
import { INTERIOR_STYLE } from "./interior.constants";

export const optionItemStyles = [
  {
    name: STYLE_IMAGE.CARTOON,
    img: imagesCommon.cartoon,
  },
  {
    name: STYLE_IMAGE.MANGA,
    img: imagesCommon.manga,
  },
  {
    name: STYLE_IMAGE.BLACK_WHITE,
    img: imagesCommon.blackWhite,
  },
  {
    name: STYLE_IMAGE.OLD_PHOTO,
    img: imagesCommon.oldPhoto,
  },
  {
    name: STYLE_IMAGE.CHIBI,
    img: imagesCommon.chiBi,
  },
];

export const optionInteriorItems = [
  {
    name: INTERIOR_STYLE.ART_DECO,
    img: imagesInterior.art,
  },
  {
    name: INTERIOR_STYLE.ART_NOUVEAU,
    img: imagesInterior.artNouveau,
  },
  {
    name: INTERIOR_STYLE.BAROQUE,
    img: imagesInterior.baroque,
  },
  {
    name: INTERIOR_STYLE.BIOPHILIC,
    img: imagesInterior.bioPhilic,
  },
  {
    name: INTERIOR_STYLE.BOHEMIAN,
    img: imagesInterior.bohemian,
  },
  {
    name: INTERIOR_STYLE.CHINESE_NEW_YEAR,
    img: imagesInterior.chineseNewyear,
  },
  {
    name: INTERIOR_STYLE.CHRISTMAS,
    img: imagesInterior.christmas,
  },
  {
    name: INTERIOR_STYLE.COASTAL,
    img: imagesInterior.coastal,
  },
  {
    name: INTERIOR_STYLE.CONTEMPORARY,
    img: imagesInterior.contemporary,
  },
  {
    name: INTERIOR_STYLE.CYBERPUNK,
    img: imagesInterior.cyberpunk,
  },
  {
    name: INTERIOR_STYLE.EASTER,
    img: imagesInterior.easter,
  },
  {
    name: INTERIOR_STYLE.FARMHOUSE,
    img: imagesInterior.farmHouse,
  },
  {
    name: INTERIOR_STYLE.FRENCH_COUNTRY,
    img: imagesInterior.french,
  },
  {
    name: INTERIOR_STYLE.GAMING,
    img: imagesInterior.gaming,
  },
  {
    name: INTERIOR_STYLE.HALLOWEEN,
    img: imagesInterior.halloween,
  },
  {
    name: INTERIOR_STYLE.INTERIOR_AI,
    img: imagesInterior.interiorAi,
  },
  {
    name: INTERIOR_STYLE.JAPANESE_DESIGN,
    img: imagesInterior.japanese,
  },
  {
    name: INTERIOR_STYLE.MAXIMALIST,
    img: imagesInterior.maximalist,
  },
  {
    name: INTERIOR_STYLE.MEDIEVAL,
    img: imagesInterior.medieval,
  },
  {
    name: INTERIOR_STYLE.MIDCENTURY_MODERN,
    img: imagesInterior.midcentury,
  },
  {
    name: INTERIOR_STYLE.MINIMALIST,
    img: imagesInterior.minimalist,
  },
  {
    name: INTERIOR_STYLE.MODERN,
    img: imagesInterior.modern,
  },
  {
    name: INTERIOR_STYLE.NEOCLASSIC,
    img: imagesInterior.neoclassic,
  },
  {
    name: INTERIOR_STYLE.RUSTIC,
    img: imagesInterior.rustic,
  },
  {
    name: INTERIOR_STYLE.SCANDINAVIAN,
    img: imagesInterior.scandinavian,
  },
  {
    name: INTERIOR_STYLE.SKETCH,
    img: imagesInterior.sketch,
  },
  {
    name: INTERIOR_STYLE.SKI_CHALET,
    img: imagesInterior.skiChalet,
  },
  {
    name: INTERIOR_STYLE.TRIBAL,
    img: imagesInterior.tribal,
  },
  {
    name: INTERIOR_STYLE.TROPICAL,
    img: imagesInterior.tropical,
  },
  {
    name: INTERIOR_STYLE.VAPORWAVE,
    img: imagesInterior.vaporware,
  },
  {
    name: INTERIOR_STYLE.VINTAGE,
    img: imagesInterior.vintage,
  },
  {
    name: INTERIOR_STYLE.ZEN,
    img: imagesInterior.zen,
  },
];
