export const PAYMENT_STATUS = {
  FAIL: 0,
  SUCCESS: 1,
  PROCESSING: 2,
};

export const PAYMENT_METHOD = {
  CREDIT: 0,
  VNPAY: 1,
  BANK: 2,
};

export const PAYMENT_METHOD_MAP = [
  { value: 0, label: "Visa Credit" },
  { value: 1, label: "Vnpay" },
  { value: 2, label: "Bank Transfer" },
];

export const PAYMENT_STATUS_MAP = [
  { value: 0, label: "Failed" },
  { value: 1, label: "Success" },
  { value: 2, label: "Processing" },
];

export const USER_STATUS = {
  DEACTIVATE: 0,
  ACTIVATE: 1,
};

export const USER_STATUS_TITLE = {
  ACTIVATE: "Activate",
  DEACTIVATE: "Deactivate",
};

export const USER_IS_EARLY_ADOPTER = {
  ACTIVATE: "Yes",
  DEACTIVATE: "No",
};

export const TYPE_TRADE = {
  BUY: 1,
  SELL: 2,
};

export const STYLE_IMAGE = {
  CARTOON: "Cartoon",
  MANGA: "Manga",
  BLACK_WHITE: "Black & White",
  OLD_PHOTO: "Old Photo",
  CHIBI: "Chibi",
};

export const LANGUAGE_IMAGE = {
  VI: "Vietnamese",
  EN: "English",
};

export const LANGUAGE_IMAGE_VALUE = {
  VI: "vi_VN",
  EN: "en_US",
};

export const LOCAL_STORAGE_TOKEN_NAME = "aiArtToken";
export const LOCAL_STORAGE_USER_INFO = "aiArtUserInfo";

export const HTTP_STATUS = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  AUTHEN: 401,
  AUTHOR: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
};

export const ERROR = {
  USER_LIMIT_GENERATE: 9,
  USER_LIMIT_EDIT: 10,
  USER_PACKAGE_EXPIRED: 18,
};

export const STATUS = {
  FAILURE: "FAILURE",
  SUCCESS: "SUCCESS",
  PENDING: "PENDING",
};

export const GENERATE_ENDPOINT = {
  AIART: "aiart_txt2img",
  IN_PAINT: "aiart_inpaint",
  CHECK_STATUS: "check_status",
  GET_RESULT: "get_result",
  REIMAGE: "aiart_img2img",
  CLEAN: "aiart_clean",
};

export const BRUSH_SIZE = 10;
