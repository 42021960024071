import { imagesArt } from "../assets/images/art";

export enum COLLAPSE {
  STYLES = "STYLES",
  ARTISTS = "ARTIST",
  OTHERS = "OTHERS",
}
export const listArtist = [
  {
    name: "Pablo Picasso",
    img: imagesArt.picasso,
  },
  {
    name: "Van Gogh",
    img: imagesArt.gogh,
  },
  {
    name: "Rembrandt",
    img: imagesArt.rembrandt,
  },
  {
    name: "Edvard Munch",
    img: imagesArt.edvard,
  },
  {
    name: "Klimt",
    img: imagesArt.klimt,
  },
  {
    name: "Cezanne",
    img: imagesArt.cezanne,
  },
  {
    name: "Frida Kahlo",
    img: imagesArt.fridakahlo,
  },
];

export const listOther = [
  {
    name: "Trending",
    img: "",
  },
  {
    name: "4K res",
    img: "",
  },
  {
    name: "8K res",
    img: "",
  },
];

export const rightArtComponent = [
  {
    id: 1,
    value: "STYLES",
    label: "Styles",
  },
  // {
  //   id: 2,
  //   value: "ARTISTS",
  //   label: "Artists",
  // },
  // {
  //   id: 3,
  //   value: "OTHERS",
  //   label: "Others",
  // },
];
