import { collectionsApis } from "../../apis/image";
import outSideClick from "../../hooks/outSideClick";
import { useToast } from "../../hooks/useToast/useToast";
import CatchError from "../../utils/catch-error";
import { yupResolver } from "@hookform/resolvers/yup";
import cn from "classnames";
import React, { memo, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { RiSearchLine } from "react-icons/ri";
import styled from "styled-components";
import * as yup from "yup";
import { Loader } from "./Loader";

interface FormProps {
  onSearch: any;
  placeholder?: string;
  classnames?: string;
  defaultValue?: string;
  autoComplete?: boolean;
  setValueSearch?: any;
}

type FormData = {
  keyword: string;
};

const schema = yup.object().shape({
  keyword: yup.string(),
});

const SearchBoxEl = styled.div`
  button[type="submit"] {
    top: 50%;
    transform: translateY(-50%);
    svg {
      width: "100%";
      height: "100%";
      color: "inherit";
    }
  }
  input {
    &:focus,
    &:hover,
    &.shadow {
      box-shadow: 0 1px 6px rgb(32 33 36 / 28%);
    }
  }
  ul {
    box-shadow: 0 4px 6px rgb(32 33 36 / 28%);
  }
`;

const SearchBox: React.FC<FormProps> = memo((props) => {
  const { onSearch, placeholder, classnames, setValueSearch } = props;
  const [openList, setOpenList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([""]);
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const onFormSubmit = (data: FormData) => {
    onSearch(data?.keyword.trim() || "");
  };

  const handleClickOutside = () => {
    setOpenList(false);
  };
  const listRef = outSideClick(handleClickOutside);
  const handleSelect = (text: string) => {
    setValueSearch(text);
    setValue("keyword", text);
    setSearchText(text);
    setOpenList(false);
  };

  const handleChangeText = async (text: string) => {
    setValue("keyword", text);
    setSearchText(text);
    setLoading(true);
  };
  const getParamsSearch = async () => {
    try {
      const res = await collectionsApis.getParamSearch(searchText);
      if (res.status === 200) {
        setData(res.data);
      }
    } catch (error: any) {
      toast.error(CatchError(error));
    }
  };
  useEffect(() => {
    const queryPrompt = setTimeout(() => {
      getParamsSearch();
    }, 500);
    return () => clearTimeout(queryPrompt);
  }, [searchText]);

  const dataList = useMemo(() => {
    if (searchText.trim()) {
      const result = data.filter((d) => d.toLowerCase().includes(searchText.trim().toLowerCase()));
      setLoading(false);
      return result;
    } else {
      return [];
    }
  }, [searchText]);
  return (
    <SearchBoxEl ref={listRef}>
      <form onSubmit={handleSubmit(onFormSubmit)} className="flex flex-col">
        <div className={`w-full relative ${classnames || ""}`}>
          <input
            {...register("keyword")}
            type="text"
            className={cn(
              "input input-bordered rounded-md border-bdinput border-1 w-full text-tsecondary pr-10 bg-bgdark hover:border-primary shadow-none",
              openList && searchText.trim() ? " border-transparent shadow" : "",
              openList && dataList && dataList.length > 0 && " rounded-b-none",
            )}
            placeholder={placeholder || "Search by keyword"}
            onFocus={() => setOpenList(true)}
            autoComplete="off"
            onChange={(e) => handleChangeText(e.target.value)}
          />
          <button type="submit" className="absolute right-2 text-xl p-1">
            <RiSearchLine className="text-tsecondary" />
          </button>
          {openList && searchText.trim() && (
            <>
              {loading ? (
                <Loader />
              ) : (
                <>
                  {data && data.length > 0 && (
                    <ul className="absolute top-full left-0 border-none bg-white z-10 pb-2 rounded-b-md max-h-[200px] w-full  overflow-y-auto">
                      <li className="px-4">
                        <span className="border-t border-t-neutral-200 block mb-2"></span>
                      </li>
                      {data.map((d, i) => (
                        <li
                          className="py-2 px-4 text-sm hover:bg-zinc-100 cursor-pointer"
                          key={i}
                          onClick={() => handleSelect(d)}
                        >
                          {d}
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </form>
    </SearchBoxEl>
  );
});

export { SearchBox };
