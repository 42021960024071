import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../contexts/modalContext";
import { ModalComponent } from "../commons/Modal";

const ModalRequiredLogin: React.FC = () => {
  const navigate = useNavigate();
  const { setModalRequiredLogin } = useModalContext();
  return (
    <ModalComponent
      title="Generate Image Error"
      btnText="Cancel"
      onClose={() => setModalRequiredLogin(false)}
      btnActionName={["Login"]}
      btnActionClick={() => {
        setModalRequiredLogin(false);
        navigate("/sign-in");
      }}
    >
      <div>Please login to generate your content to image!</div>
    </ModalComponent>
  );
};

export { ModalRequiredLogin };
