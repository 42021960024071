import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Banner, Collections } from "../components";
import { useCommonContext } from "../contexts/commonContext";
import { useGenerateImage } from "../contexts/generateImageContext";
import { IImgResult } from "../types/generate";

export const Home = () => {
  const { user } = useCommonContext();
  // const { listImgAfterGenerate } = useGenerateImage();
  // const [imgListResult, setImgListResult] = useState<IImgResult[]>(() => {
  //   return listImgAfterGenerate?.length > 0 ? listImgAfterGenerate : [];
  // });
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (listImgAfterGenerate?.length > 0) {
  //     console.log("first");
  //     setImgListResult(listImgAfterGenerate);
  //   }
  // }, [listImgAfterGenerate]);

  useEffect(() => {
    if (user.userInfo) {
      !user.userInfo.isProvidedPassword && navigate("/profile");
    }
  }, [user.userInfo]);

  return (
    <>
      <div className="mt-16" />
      <div className="max-w-screen-2xl mx-auto">
        <div className="mx-3">
          <Banner />
          <Collections />
        </div>
      </div>
    </>
  );
};
