import cn from "classnames";
import React, { memo, useMemo } from "react";
import { useToast } from "../../hooks/useToast/useToast";
import CatchError from "../../utils/catch-error";
import { Loader } from "../commons/Loader";
import { CardImage } from "./CardImage";
import { useGenerateImage } from "../../contexts/generateImageContext";
import { useModalContext } from "../../contexts/modalContext";
import { IImgResult } from "../../types/generate";

interface TabsContentProps {
  onSaveCollections: (imgID: string) => void;
  isMobile?: boolean;
  resolutionPresent?: string;
  onClickDetail?: any;
}
const TabsContent: React.FC<TabsContentProps> = memo((props) => {
  const { onSaveCollections, isMobile, resolutionPresent, onClickDetail } = props;
  const { loadingGenerate, listImgAfterGenerate, setImgEdit, formGenerate } = useGenerateImage();
  const { setModalChooseOptionEdit } = useModalContext();
  const { toast } = useToast();

  const isSettingIMG1 = listImgAfterGenerate?.length === 1;

  const handleWidth = useMemo(() => {
    switch (resolutionPresent) {
      case "Portrait":
        return isSettingIMG1 ? "max-w-[550px]" : "max-w-[650px]";
      case "Landscape":
        return isSettingIMG1 ? "max-w-[850px]" : "max-w-[950px]";
      default:
        return isSettingIMG1 ? "max-w-[750px]" : "max-w-[800px]";
    }
  }, [resolutionPresent, isSettingIMG1]);

  const handleClickEditImage = async (img: IImgResult) => {
    try {
      setImgEdit({
        ...img,
        prompt: formGenerate.prompt,
        styleImage: formGenerate.style,
        hashtag: formGenerate.hashtag,
      });
      setModalChooseOptionEdit(true);
    } catch (e: any) {
      toast.error(CatchError(e));
    }
  };

  return (
    <div className="content w-full flex justify-center items-center py-3" id="img-render">
      {loadingGenerate && (
        <div className="loader-img w-full h-[280px] flex justify-center items-center">
          <Loader />
        </div>
      )}
      <div
        className={cn(
          `w-full  grid gap-4 
          ${handleWidth}
          ${isSettingIMG1 ? "grid-cols-1" : ""}
          ${isMobile && !isSettingIMG1 ? "grid-cols-1" : isSettingIMG1 ? "grid-cols-1" : "grid-cols-2"}
           `,
          { hidden: loadingGenerate },
        )}
      >
        {listImgAfterGenerate?.map((img, index) => {
          return (
            <CardImage
              key={index}
              imgData={img}
              onClickSaveCollections={onSaveCollections}
              onClickEditImage={() => handleClickEditImage(img)}
              onClickDetail={(value: any) => onClickDetail(value)}
            />
          );
        })}
      </div>
    </div>
  );
});
export { TabsContent };
