import { useNavigate } from "react-router-dom";
import { ModalComponent } from "../commons/Modal";
import { useModalContext } from "../../contexts/modalContext";

interface Props {
  title: string;
  text: string;
}
const ModalFavoriteToLogin = (props: Props) => {
  const navigate = useNavigate();
  const { setModalFavorite } = useModalContext();
  return (
    <>
      <ModalComponent
        title={props.title}
        btnText="Cancel"
        onClose={() => setModalFavorite(false)}
        btnActionName={["Login"]}
        btnActionClick={() => {
          setModalFavorite(false);
          navigate("/sign-in");
        }}
      >
        <div>{props.text}</div>
      </ModalComponent>
    </>
  );
};

export { ModalFavoriteToLogin };
