import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { FieldErrors, SubmitHandler, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { COMMON, SELECTED } from "../../constants";
import { useCommonContext } from "../../contexts/commonContext";
import { useGenerateImage } from "../../contexts/generateImageContext";
import outSideClick from "../../hooks/outSideClick";
import { InputText } from "../../types/generate";
import { InputContact } from "../commons/InputContact";
import { Selected } from "../commons/Selected";
import { DropZone } from "../interior-ai/DropAndSelectImage";
import { initValue } from "../interior-ai/FormInterior";

type Props = {
  handleSubmit: any;
  onSubmitContent: SubmitHandler<InputText>;
  setValue: UseFormSetValue<any>;
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
};
export const FormArt: React.FC<Props> = ({ handleSubmit, onSubmitContent, setValue, register, errors }) => {
  const { loadingGenerate, hiddenBtnEdit, formGenerate } = useGenerateImage();
  const { user } = useCommonContext();

  const [promptInputArt, setPromptInputArt] = useState<string>(formGenerate?.file_input ? "" : formGenerate?.prompt);
  const [valuePrompt, setValuePrompt] = useState<string>("");
  const [styleInput, setStyleInput] = useState<string[]>([]);
  const [isVisible, setVisible] = useState(false);
  const [openList, setOpenList] = useState(false);
  const [isVisibleResolution, setVisibleResolution] = useState(false);
  const [fileState, setFileState] = useState<{ file: File | null; base64URL: string | undefined }>({
    file: formGenerate?.file_input?.[0] || null,
    base64URL: undefined,
  });

  const languageRef = outSideClick(() => setVisible(false));
  const promptRef = outSideClick(() => setOpenList(false));

  useEffect(() => {
    if (!user.userInfo) {
      handleResetForm();
    }
  }, [user.userInfo]);

  const handleResetForm = () => {
    setPromptInputArt("");
  };

  const handleChangePromptArt = useCallback(async (text: any, setValue: UseFormSetValue<any>) => {
    if (text.target.value.length === 0) {
      setStyleInput([]);
      return;
    }
    setValue("prompt", text.target.value);
    setValuePrompt(text.target.value);
  }, []);

  const handleChangeInputPrompt = (e: ChangeEvent<HTMLInputElement>) => {
    setPromptInputArt(e.target.value);
    handleChangePromptArt(e, setValue);
    errors.prompt = undefined;
  };

  return (
    <section className="w-full bg-white/[2%] backdrop-blur-[24px] border-[rgb(42,50,64)]  border-[1px] p-5 rounded-lg">
      <form onSubmit={handleSubmit(onSubmitContent)}>
        <div ref={languageRef} className="relative col-span-6 sm:col-span-3 mb-6">
          <label htmlFor="language" className=" block font-[700] text-[18px] text-white/60  mb-[12px]">
            Languages
          </label>
          <Selected
            initValue={COMMON.LANGUAGE_IMAGE.EN}
            setValue={setValue}
            setVisible={setVisible}
            isVisible={isVisible}
            fieldName={"language"}
            dataOption={SELECTED.optionItems}
          />
        </div>
        <div ref={promptRef} className={`relative col-span-6 sm:col-span-3 md:col-span-6 lg:col-span-6  mb-6`}>
          <InputContact
            register={register("prompt", { required: true, setValueAs: (value) => value.trim() })}
            label="Prompt"
            isRequired
            textarea
            onFocus={() => setOpenList(true)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputPrompt(e)}
            errors={errors.prompt}
            errorsMessage="This field is required"
          />
        </div>
        <div className={`relative col-span-6 sm:col-span-3 md:col-span-6 lg:col-span-6  mb-6`}>
          <p className="text-[18px] text-white/60 font-bold">Negative Prompt</p>
          <p className="text-[12px] font-normal text-white/60 mb-2">Specify what you don't want to see.</p>
          <InputContact
            register={register("negative_prompt", { setValueAs: (value) => value.trim() })}
            // label="Negative Prompt"
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeInputPrompt(e)}
          />
        </div>

        <div className={`relative col-span-6 sm:col-span-3 md:col-span-6 lg:col-span-6  mb-6`}>
          <InputContact
            register={register("hashtag", { pattern: /^#[^#\s]+(?: #[^#\s]+)*$/ })}
            label="Hashtag"
            errors={errors.hashtag}
            errorsMessage="Invalid hashtag, please add # before each hashtag"
          />
        </div>

        <div className={`relative col-span-6 sm:col-span-3 md:col-span-6 lg:col-span-6  mb-6`}>
          <label htmlFor="resolution" className="mb-1 block font-[700] text-[18px] text-white/60 mt-4">
            Resolution
          </label>
          <Selected
            initValue={initValue.Resolution}
            setValue={setValue}
            setVisible={setVisibleResolution}
            isVisible={isVisibleResolution}
            fieldName={"resolution"}
            dataOption={SELECTED.optionItemsResolution}
            showScroll={false}
          />
        </div>
        <p className="text-[18px] text-white/60 font-bold">IMG TO IMG</p>
        <p className="text-[12px] font-normal text-white/60 mb-2">
          Upload an image to give AI Art an idea of what you want to create.
        </p>
        <DropZone
          register={register("file_input")}
          isIcon={false}
          content="Drag & Drop here or Browse"
          classDrops="!h-[96px]"
          setFileState={setFileState}
          fileState={fileState}
          setValue={setValue}
        />
        <button
          type="submit"
          className={`w-full h-[51px] bg-[#3E80FF] rounded-xl mt-[15px] text-[18px] font-black btn capitalize hover:bg-bgbtn transition-all ${
            loadingGenerate && "opacity-50 cursor-not-allowed disabled:bg-primary disabled:text-tprimary"
          }`}
          disabled={loadingGenerate || hiddenBtnEdit}
        >
          Generate
        </button>
      </form>
    </section>
  );
};
