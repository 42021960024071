import { memo, useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AiOutlineFilter } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import { INTERIOR, SELECTED, SELECT_IMAGE } from "../../constants";
import { useCommonContext } from "../../contexts/commonContext";
import { useGenerateImage } from "../../contexts/generateImageContext";
import { useModalContext } from "../../contexts/modalContext";
import outSideClick from "../../hooks/outSideClick";
import { getBase64FromUrl } from "../../utils/helpers";
import { InputContact } from "../commons/InputContact";
import { SearchBox } from "../commons/SearchBox";
import { Selected } from "../commons/Selected";
import { FilterBox } from "../home/Filter";
import { SortBox } from "../home/SortBox";
import { DropZone } from "./DropAndSelectImage";

export const initValue = {
  room: "",
  style: "",
  Resolution: "Square",
};
const FormGenerate = memo(() => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm<any>();
  const { setImgEdit } = useGenerateImage();
  const { setModalChooseOptionEdit, setModalRequiredLogin } = useModalContext();
  const { user } = useCommonContext();
  const [isVisibleRoom, setVisibleRoom] = useState(false);
  const [isVisibleResolution, setVisibleResolution] = useState(false);
  const [isVisibleStyle, setVisibleStyle] = useState(false);
  const resolutionRef = outSideClick(() => setVisibleResolution(false));
  const styleRef = outSideClick(() => setVisibleStyle(false));
  const roomRef = outSideClick(() => setVisibleRoom(false));
  const [selectedImageId, setSelectedImageId] = useState<string>("");
  const scrollRef = useRef<HTMLDivElement>(null);
  const itemStyle = "relative col-span-6 sm:col-span-3";
  const labelStyle = "mb-1 block font-[700] text-[18px] text-white/60  mb-[12px] mt-4";

  // filter
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const SortAndFilter: any = useRef(null);
  const buttonFilter: any = useRef(null);

  const handleClickImage = (name: string) => {
    setSelectedImageId(name);
    setVisibleStyle(false);
    if (scrollRef.current) {
      const imageElement = document.getElementById(`image-${name}`);
      if (imageElement) {
        const scrollContainer = scrollRef.current;
        const imageLeft = imageElement.offsetLeft;
        const imageWidth = imageElement.offsetWidth;
        const containerWidth = scrollContainer.clientWidth;
        const scrollLeft = imageLeft - (containerWidth - imageWidth) / 2;
        scrollContainer.scrollTo({
          left: scrollLeft,
          behavior: "smooth",
        });
      }
    }
  };
  const onToggle = useCallback(() => {
    setVisibleStyle(!isVisibleStyle);
  }, [isVisibleStyle]);
  const handleClickEditInterior = async (imageURL: string) => {
    if (!user.userInfo) {
      setModalRequiredLogin(true);
      return;
    }
    const base64 = await getBase64FromUrl(imageURL);
    setImgEdit({
      imgID: "idRandom",
      styleImage: [""],
      prompt: "example",
      url: base64 as string,
      hashtag: "",
      like: false,
    });
    setModalChooseOptionEdit(true);
  };

  return (
    <>
      <div className="w-full flex flex-col md:flex-row  md:justify-between mb-4">
        <div id="interior" className="text-ttitle text-3xl font-black">
          Start using Interior AI
        </div>
        <div className="flex items-center mt-3 md:mt-0">
          <div>
            <SearchBox onSearch={() => console.log()} setValueSearch={""} placeholder="Search Image" />
          </div>
          <div className="relative">
            <button
              ref={SortAndFilter}
              className="btn ml-4 bg-bgdark border-input border-bdinput hover:bg-primary hover:text-white active:bg-bgdark"
              onClick={() => console.log()}
            >
              <span className="text-tsecondary">Filter</span>
              <span className="text-tsecondary ml-2 text-lg">
                <AiOutlineFilter />
              </span>
            </button>
            <>
              <div
                ref={buttonFilter}
                className={`
                  ${isFilter ? "visible" : "invisible"}
                  card shadow-x border border-solid tprimary shadow-lg bg-bggray border-bdcolor px-5 pr-20 py-3 w-max translate-y-1 absolute right-0 top-full z-[999]
                `}
              >
                <SortBox onSort={() => console.log()} sortList={[""]} />
                <FilterBox onFilter={() => console.log()} />
              </div>
            </>
          </div>
        </div>
      </div>

      <div className="w-full grid grid-cols-10 h-[790px] gap-5 text-white">
        <section className="col-span-4  h-full bg-white/[2%] rounded-xl border-[1px] border-[rgb(42,50,64)] backdrop-blur-[24px]  p-[35px]">
          <form>
            <InputContact
              register={register("interior")}
              placeholder="Describle your room"
              errors={errors.firstName}
              classnamesProp="mb-6"
            />
            <DropZone isIcon />
            <div ref={roomRef} className={itemStyle}>
              <label htmlFor="room" className={labelStyle}>
                Room
              </label>
              <Selected
                initValue={initValue.room}
                setValue={setValue}
                setVisible={setVisibleRoom}
                isVisible={isVisibleRoom}
                fieldName={"room"}
                dataOption={SELECTED.optionItemsRoom}
              />
            </div>
            <div ref={styleRef} className={itemStyle}>
              <label htmlFor="style" className={labelStyle}>
                Style
              </label>
              <div
                onClick={onToggle}
                className="relative cursor-pointer border-solid border-[#667085] border-[1px] h-[48px] rounded-[10px] px-2  w-full flex items-center"
              >
                <div className="flex w-full justify-between items-center text-ttitle">
                  <div className="flex gap-2 ">{selectedImageId}</div>
                  <IoIosArrowDown />
                </div>
              </div>
              <div
                className={`absolute  cursor-pointer  bg-bggray w-full text-tprimary  shadow-xl mt-2 drop-shadow-xl rounded-lg  duration-300 origin-[97%_0%] z-[99]  overflow-y-scroll max-h-[200px] ${
                  isVisibleStyle ? "scale-100" : "scale-0"
                }`}
              >
                {SELECTED.optionStyleInteriorItems.map((item) => (
                  <button
                    className="flex flex-col hover:bg-blue-100 hover:text-primary px-2 py-2 w-full"
                    key={item.name}
                    onClick={() => handleClickImage(item.name)}
                    type="button"
                  >
                    <div className="flex gap-2 items-center">{item.name}</div>
                  </button>
                ))}
              </div>
            </div>
            <div ref={resolutionRef} className={itemStyle}>
              <label htmlFor="resolution" className={labelStyle}>
                Resolution
              </label>
              <Selected
                initValue={initValue.Resolution}
                setValue={setValue}
                setVisible={setVisibleResolution}
                isVisible={isVisibleResolution}
                fieldName={"resolution"}
                dataOption={SELECTED.optionItemsResolution}
                showScroll={false}
              />
            </div>
            <button
              type="submit"
              className="bg-gradient-to-r from-[#6bdbe3] via-[#588BFE] to-[#A334FF] w-full h-[51px] rounded-xl mt-[31px] text-[18px] font-black btn capitalize hover:bg-bgbtn transition-all"
            >
              Render new idea
            </button>
          </form>
        </section>
        <section className="col-span-6  overflow-y-auto grid grid-cols-2 gap-3 ">
          {INTERIOR.listImage.map((imageURL, index) => (
            <div className="relative  group" key={index}>
              <img
                className="rounded-xl h-full group-hover:opacity-60 duration-300  group-hover:scale-[101%] cursor-pointer"
                src={imageURL}
                alt="interior"
                onClick={() => handleClickEditInterior(imageURL)}
              />
              <div className="absolute bottom-0 flex left-24 opacity-0 translate-y-0  group-hover:-translate-y-24 duration-500 group-hover:opacity-100 group-hover:duration-300">
                {/* <ActionButton dataImage={[]} isPopup={true} onClickPopup={() => console.log()} /> */}
              </div>
            </div>
          ))}
        </section>
      </div>
      <section className="overflow-x-auto whitespace-no-wrap mt-5 " ref={scrollRef}>
        <div className="flex gap-4">
          {SELECT_IMAGE.optionInteriorItems?.map((i, id) => {
            return (
              <div
                key={id}
                id={`image-${i.name}`}
                className={`${
                  selectedImageId === i.name ? " border-white" : ""
                } min-w-[176px] h-[127px] rounded-lg bg-[#2d3642] border-transparent border-[2px] cursor-pointer`}
                onClick={() => setSelectedImageId(i?.name)}
              >
                <img className="w-full rounded-t-lg h-[96px] block" src={i.img} alt="interior-style" />
                <p className="text-white h-[29px] text-center leading-[28px] font-medium  text-sm">{i.name}</p>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
});

export { FormGenerate };
