import { PropsWithChildren, useState } from "react";
import { createContext } from "../utils/createContext";
import { PackageAllDataType } from "../components/profile-page/Upgrade";
import { PaymentType } from "../types/payment";

interface ContextProps {
  modalRequiredLogin: boolean;
  setModalRequiredLogin: React.Dispatch<React.SetStateAction<boolean>>;
  modalChooseOptionEdit: boolean;
  setModalChooseOptionEdit: React.Dispatch<React.SetStateAction<boolean>>;
  modalEditImage: boolean;
  setModalEditImage: React.Dispatch<React.SetStateAction<boolean>>;
  modalSessionTimeout: boolean;
  setModalSessionTimeout: React.Dispatch<React.SetStateAction<boolean>>;
  modalFavorite: boolean;
  setModalFavorite: React.Dispatch<React.SetStateAction<boolean>>;
  modalEditExceed: IModalEditExceed;
  setModalEditExceed: React.Dispatch<React.SetStateAction<IModalEditExceed>>;
  // payment
  methodPayment: PaymentType | null;
  setMethodPayment: React.Dispatch<React.SetStateAction<PaymentType | null>>;
  randomCode: number;
  setRandomCode: React.Dispatch<React.SetStateAction<number>>;
  modalPayment: boolean;
  setModalPayment: React.Dispatch<React.SetStateAction<boolean>>;
  modalCreditCard: boolean;
  setModalCreditCard: React.Dispatch<React.SetStateAction<boolean>>;
  modalVNPay: boolean;
  setModalVNPay: React.Dispatch<React.SetStateAction<boolean>>;
  modalBankTransfer: boolean;
  setModalBankTransfer: React.Dispatch<React.SetStateAction<boolean>>;
  modalWaiting: boolean;
  setModalWaiting: React.Dispatch<React.SetStateAction<boolean>>;
  modalUploadImageConfirm: boolean;
  setModalUploadImageConfirm: React.Dispatch<React.SetStateAction<boolean>>;
  packageUpgrade: PackageAllDataType | null;
  setPackageUpgrade: React.Dispatch<React.SetStateAction<PackageAllDataType | null>>;
}
interface IModalEditExceed {
  isOpen: boolean;
  type: "Generate" | "Edit" | "Expired";
  msg: string;
}
const [Provider, useModalContext] = createContext<ContextProps>();

export { useModalContext };

export default function ModalContextProvider({ children }: PropsWithChildren) {
  const [modalRequiredLogin, setModalRequiredLogin] = useState<boolean>(false);
  const [modalEditExceed, setModalEditExceed] = useState<IModalEditExceed>({
    isOpen: false,
    type: "Generate",
    msg: "",
  });
  const [modalChooseOptionEdit, setModalChooseOptionEdit] = useState<boolean>(false);
  const [modalEditImage, setModalEditImage] = useState<boolean>(false);
  const [modalSessionTimeout, setModalSessionTimeout] = useState<boolean>(false);
  const [modalFavorite, setModalFavorite] = useState<boolean>(false);

  // modal payment
  const [methodPayment, setMethodPayment] = useState<PaymentType | null>(PaymentType.CreditCard);
  const [randomCode, setRandomCode] = useState<number>(0);
  const [modalPayment, setModalPayment] = useState<boolean>(false);
  const [modalCreditCard, setModalCreditCard] = useState<boolean>(false);
  const [modalVNPay, setModalVNPay] = useState<boolean>(false);
  const [modalBankTransfer, setModalBankTransfer] = useState<boolean>(false);
  const [modalWaiting, setModalWaiting] = useState<boolean>(false);
  const [modalUploadImageConfirm, setModalUploadImageConfirm] = useState<boolean>(false);
  const [packageUpgrade, setPackageUpgrade] = useState<PackageAllDataType | null>(null);
  return (
    <Provider
      value={{
        modalRequiredLogin,
        setModalRequiredLogin,
        modalChooseOptionEdit,
        setModalChooseOptionEdit,
        modalEditImage,
        setModalEditImage,
        modalSessionTimeout,
        setModalSessionTimeout,
        modalFavorite,
        setModalFavorite,
        modalEditExceed,
        setModalEditExceed,
        methodPayment,
        setMethodPayment,
        randomCode,
        setRandomCode,
        modalPayment,
        setModalPayment,
        modalCreditCard,
        setModalCreditCard,
        modalVNPay,
        setModalVNPay,
        modalBankTransfer,
        setModalBankTransfer,
        modalWaiting,
        setModalWaiting,
        modalUploadImageConfirm,
        setModalUploadImageConfirm,
        packageUpgrade,
        setPackageUpgrade,
      }}
    >
      {children}
    </Provider>
  );
}
