import { yupResolver } from "@hookform/resolvers/yup";
import classnames from "classnames";
import { useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { userApis } from "../../apis/auth";
import { COMMON } from "../../constants";
import { useCommonContext } from "../../contexts/commonContext";
import { useToast } from "../../hooks/useToast/useToast";
import { IUserInfo } from "../../types/user";
import CatchError from "../../utils/catch-error";
import { Loader } from "../commons/Loader";
import { MessageError } from "../commons/MessageError";

type FormData = {
  name?: string;
};

const schema = yup.object().shape({
  name: yup.string().required("Name is required").max(50, "Maximum of 50 characters"),
});

export const FormInfoUser: React.FC = () => {
  const { user, setUser, getUserInformation } = useCommonContext();
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { userInfo, loading } = user;
  const { toast } = useToast();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormData>({
    defaultValues: {
      name: userInfo?.name,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: FormData) => {
    try {
      setSubmitLoading(true);
      const response: any = await userApis.updateInformation({ ...data, name: data.name?.trim() });
      if (response?.success) {
        toast.success("Your changes have been saved!");
        setUser((pre) => ({ ...pre, userInfo: { ...pre.userInfo, ...data, name: data?.name?.trim() } as IUserInfo }));
        reset({ name: data?.name?.trim() });
        getUserInformation();
      }
    } catch (error) {
      toast.error(CatchError(error));
    } finally {
      setSubmitLoading(false);
    }
  };
  if (loading && !userInfo) return <Loader />;
  return (
    <div className="card lg:card-side lg:card-normal bg-base-100 rounded-[10px] md:min-w-[640px] w-full">
      <div className="card-body bg-bggrayprimary">
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-12">
              <label className="text-tlabel font-bold">
                Full Name <span className="text-red-600">*</span>
              </label>

              <input
                {...register("name")}
                type="text"
                className="input w-full focus:outline-none bg-bggrayprimary border border-solid border-bdinput rounded-rddefault text-tsecondary mt-3"
              />
              {errors.name && <MessageError message={errors.name.message} />}
            </div>
            <div className="col-span-12">
              <label className="text-tlabel font-bold">
                Email <span className="text-red-600">*</span>
              </label>

              <input
                type="text"
                disabled
                className="input w-full focus:outline-none bg-bggrayprimary border border-solid border-bdinput rounded-rddefault text-tsecondary disabled:opacity-75 disabled:text-black mt-3"
                defaultValue={userInfo?.email}
              />
            </div>
            <div className="col-span-12 md:col-span-6">
              <label className="text-tlabel font-bold">Status</label>
              <span
                className={classnames(
                  "text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold ml-2 text-white rounded-full",
                  userInfo?.status === COMMON.USER_STATUS.ACTIVATE ? "bg-green-500" : "bg-red-600",
                )}
              >
                {userInfo?.status === COMMON.USER_STATUS.ACTIVATE
                  ? COMMON.USER_STATUS_TITLE.ACTIVATE
                  : COMMON.USER_STATUS_TITLE.DEACTIVATE}
              </span>
            </div>
            <div className="col-span-12 md:col-span-6">
              <label className="text-tlabel font-bold">Is Early Adopter</label>
              <span
                className={classnames(
                  "text-xs inline-block py-1 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold ml-2 text-white rounded-full",
                  userInfo?.isEarlyAdopter ? "bg-green-500" : "bg-red-600",
                )}
              >
                {userInfo?.isEarlyAdopter
                  ? COMMON.USER_IS_EARLY_ADOPTER.ACTIVATE
                  : COMMON.USER_IS_EARLY_ADOPTER.DEACTIVATE}
              </span>
            </div>
          </div>
          <button
            type="submit"
            disabled={submitLoading || !isDirty}
            className={classnames(
              "btn bg-[#3E80FF] hover:bg-bgbtn text-white mb-3 w-fit self-center border-none outline-none capitalize text-lg font-black px-12 py-3 mt-12 disabled:text-white/30 disabled:bg-[#3E80FF]/30",
              submitLoading && "loading",
            )}
          >
            Update
          </button>
        </form>
      </div>
    </div>
  );
};
