import { FaPencilAlt } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { IImageInfo } from "../../../types/my-images";

interface Props {
  data: IImageInfo[];
  indexImg: number;
  hideInformation?: boolean;
}

const ModalBottom: React.FC<Props> = ({ data, indexImg, hideInformation }) => {
  const prompt = data[indexImg]?.prompt;
  const promptEdit = data[indexImg]?.promptEdit;
  const createdAt = data[indexImg]?.createdAt;

  const timeCreated = new Date(createdAt);
  const year = timeCreated.getFullYear();
  const month = timeCreated.getMonth() + 1;
  const date = timeCreated.getDate();

  return (
    <div className="bg-bggray flex flex-col items-start p-[20px] rounded-bl-[10px] rounded-br-[10px] select-text">
      {!hideInformation && (
        <>
          <div className=" flex  justify-center items-center w-full text-[#fff]">
            <FaPencilAlt />
            <div className="dropdown dropdown-top ml-[7px] text-start flex-1 w-min" title={prompt}>
              <label className="line-clamp-2 cursor-pointer" tabIndex={0}>
                {prompt}
              </label>
              <div
                tabIndex={0}
                className="dropdown-content px-[10px] bg-[#f1f1f2] text-[#060606] rounded-[10px] 
                translate-x-[-15px] translate-y-[-10px] shadow-lg shadow-bggray 
                before:content-[''] 
                before:inline-block before:w-0 before:h-0 
                before:border-r-[15px] before:border-solid before:border-transparent
                before:border-l-[15px]  before:border-t-[15px] before:border-t-[#f1f1f2] 
                before:left-[5px] before:absolute before:bottom-[1px] before:translate-y-[100%] "
              >
                {prompt}
              </div>
            </div>
          </div>
          {/* {promptEdit && (
        <div className={style.textAndIcon}>
          <FaEraser />
          <div className={`${style.prompt} dropdown dropdown-top`} title={promptEdit}>
            <label className={style.text} tabIndex={0}>
              {promptEdit}
            </label>
            <div tabIndex={0} className={`dropdown-content ${style.contentDropdown}`}>
              {promptEdit}
            </div>
          </div>
        </div>
      )} */}
          <div className="flex justify-start items-center w-full text-[#fff] ">
            <MdDateRange />
            <div className="ml-[7px]"> {`${date} / ${month} / ${year}`}</div>
          </div>
        </>
      )}
    </div>
  );
};

ModalBottom.propTypes = {};

export { ModalBottom };
