import { useEffect, useRef, useState, memo, useMemo } from "react";
import { AiOutlineFilter } from "react-icons/ai";
import { IoClose } from "react-icons/io5";
import { ArrFilterArray } from "../../types/my-images";
import { FilterBox } from "../home/Filter";
import { SortBox } from "../home/SortBox";
import { SearchBox } from "./SearchBox";

interface Props {
  onSearch: (search: string) => void;
  onFilter: (arrFilter: ArrFilterArray) => void;
  onSort: (sort: string) => void;
  sortList: string[];
  setValueSearch?: any;
  listHashtag?: string[];
  setListHashtag?: React.Dispatch<React.SetStateAction<string[]>>;
}

const SortAndFilter: React.FC<Props> = memo(
  ({ onSearch, onSort, onFilter, sortList, setValueSearch, listHashtag, setListHashtag }) => {
    const [isFilter, setIsFilter] = useState<boolean>(false);

    const SortAndFilter: any = useRef(null);
    const buttonFilter: any = useRef(null);

    const handleClick = () => {
      setIsFilter(!isFilter);
    };

    useEffect(() => {
      const handleClickOutSide = (event: any) => {
        if (
          SortAndFilter.current &&
          !SortAndFilter?.current?.contains(event.target) &&
          buttonFilter.current &&
          !buttonFilter?.current?.contains(event.target)
        ) {
          setIsFilter(false);
        }
      };

      document.addEventListener("click", handleClickOutSide, true);

      return () => {
        document.removeEventListener("click", handleClickOutSide, true);
      };
    }, []);
    const handleRemoveHashTag = (hashTag: string) => {
      if (listHashtag && setListHashtag) {
        const newListHashTag = listHashtag?.filter((item: any) => item !== hashTag);
        setListHashtag([...newListHashTag]);
      }
    };
    const renderInputFilterHashtag = useMemo(
      () =>
        listHashtag &&
        listHashtag.length > 0 && (
          <div className="text-white flex gap-4  items-center px-2 mr-4 flex-1 shadow-none border-bdinput h-[46px] bg-[#101828]  rounded-md border">
            {listHashtag.map((item: any, index: any) => (
              <div className="flex items-center gap-[2px] rounded-md" key={index}>
                {item}
                <IoClose className="relative z-999 cursor-pointer mt-[2px]" onClick={() => handleRemoveHashTag(item)} />
              </div>
            ))}
          </div>
        ),
      [listHashtag],
    );
    return (
      <>
        <div className="w-full flex flex-col md:flex-row  md:justify-between">
          <div id="collections" className="text-ttitle text-3xl font-black ">
            Best Collections
          </div>
          <div className="flex items-center mt-3 md:mt-0">
            {renderInputFilterHashtag}
            <div>
              <SearchBox onSearch={onSearch} setValueSearch={setValueSearch} placeholder="Search Image" />
            </div>
            <div className="relative">
              <button
                ref={buttonFilter}
                className="btn ml-4 bg-bgdark border-input border-bdinput hover:bg-primary hover:text-white active:bg-bgdark"
                onClick={handleClick}
              >
                <span className="text-tsecondary">Filter</span>
                <span className="text-tsecondary ml-2 text-lg">
                  <AiOutlineFilter />
                </span>
              </button>
              <>
                <div
                  ref={SortAndFilter}
                  className={`
                  ${isFilter ? "visible" : "invisible"}
                  card
                  shadow-x
                  border 
                  border-solid
                  tprimary 
                  shadow-lg
                  bg-bggray
                  border-bdcolor
                  px-5 
                  pr-20
                  py-3 
                  w-max
                  translate-y-1
                  absolute
                  right-0
                  top-full
                  z-[999]
                `}
                >
                  <SortBox onSort={onSort} sortList={sortList} />
                  <FilterBox onFilter={onFilter} />
                </div>
              </>
            </div>
          </div>
        </div>
      </>
    );
  },
);

export { SortAndFilter };
