import classname from "classnames";
import Compressor from "compressorjs";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { paymentApis } from "../../../../apis/payment";
import { imagesCommon, imagesPayment } from "../../../../assets/images";
import { PAYMENT_METHOD, PAYMENT_STATUS } from "../../../../constants/common.constants";
import { useCommonContext } from "../../../../contexts/commonContext";
import { useModalContext } from "../../../../contexts/modalContext";
import { useToast } from "../../../../hooks/useToast/useToast";
import { IProfileAction } from "../../../../types/user";
import CatchError from "../../../../utils/catch-error";
import { getBase64, urltoFile } from "../../../../utils/helpers";
import { ModalComponent } from "../../../commons/Modal";

export const ModalUploadImageConfirm: React.FC = () => {
  const { setProfileAction } = useCommonContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalErrorFileType, setModalErrorFileType] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);
  const [fileState, setFileState] = useState<{ file: File | null; base64URL: string | undefined }>({
    file: null,
    base64URL: undefined,
  });

  const { randomCode, modalUploadImageConfirm, packageUpgrade, setModalUploadImageConfirm, setModalWaiting } =
    useModalContext();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (fileState.file !== null) {
      getBase64(fileState.file)
        .then((result) => {
          setFileState({ ...fileState, base64URL: result });
        })
        .catch((error) => {
          toast.error(CatchError(error));
        });
    }
  }, [fileState.file]);

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const fileTypeList = ["svg", "png", "jpg", "jpeg"];
    let isUpload = true;

    let { file } = fileState;
    file = e?.target?.files[0];
    const fileType = file.name.split(".").pop() || "";
    if (fileTypeList.indexOf(fileType) === -1) {
      isUpload = false;
      setModalErrorFileType(true);
    }
    isUpload && setFileState({ ...fileState, file: e.target.files[0] });
    setShowError(false);
  };

  const UploadFile = () => {
    document.getElementById("upload-image")?.click();
  };

  const handleSetModalErrorFileType = useCallback(() => {
    setModalErrorFileType(false);
  }, []);

  const createFormData = async (
    blobImage: Blob | File,
    packageId: string,
    price: number,
    payStatus: number,
    payMethod: number,
    messageCodeBank: number,
  ) => {
    const formData = new FormData();
    formData.append("file", blobImage);
    formData.append("packageId", packageId);
    formData.append("price", price as unknown as string);
    formData.append("payStatus", payStatus as unknown as string);
    formData.append("payMethod", payMethod as unknown as string);
    formData.append("messageCodeBank", messageCodeBank as unknown as string);
    return formData;
  };

  const handleConfirm = async () => {
    setLoading(true);
    try {
      if (!fileState?.file) {
        setShowError(true);
        return;
      }
      const file = await urltoFile(fileState.base64URL as string, "new-image", "image/jpeg");
      new Compressor(file, {
        quality: 0.8,
        success: async (compressedImg) => {
          const formData = await createFormData(
            compressedImg,
            packageUpgrade?.id as string,
            packageUpgrade?.totalPrice as number,
            PAYMENT_STATUS.PROCESSING,
            PAYMENT_METHOD.BANK,
            randomCode,
          );
          setModalUploadImageConfirm(false);
          setModalWaiting(true);
          const response = await paymentApis.signTransactionBankTransfer(formData);
          if (response) {
            navigate("/profile");
            setModalWaiting(false);
            setProfileAction(IProfileAction.paymentHistory);
          }
        },
      });
    } catch (error) {
      toast.error(CatchError(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className={classname("modal w-full", modalUploadImageConfirm && "modal-open")}>
        <div className="modal-box md:min-w-[700px] bg-[#1D2939] py-[30px] rounded-rddefault overflow-auto scrollbar w-full mx-5">
          <img
            className="w-6 h-6 ml-auto mb-3 md:mt-0 md:mr-[10px] md:mb-0 hover:scale-105 transition-all cursor-pointer"
            onClick={() => setModalUploadImageConfirm(false)}
            src={imagesCommon.closeIcon}
            alt="close"
          />
          <h2 className="font-bold text-2xl text-tsecondary text-center mb-6">Please upload your payment proof.</h2>
          {modalErrorFileType && (
            <ModalComponent title="Upload File Error" onClose={handleSetModalErrorFileType}>
              <div>Type of file must be png, jpg, jpeg or svg</div>
            </ModalComponent>
          )}
          <div className="flex flex-col justify-center items-center mb-10">
            <div
              onClick={UploadFile}
              className="flex justify-center items-center card bg-bgdark w-2/3 h-2/3 border border-solid border-[#667085] cursor-pointer"
            >
              {fileState.base64URL === undefined ? (
                <div className="py-10 flex flex-col justify-center items-center">
                  <img className="w-1/2 h-1/2 object-cover" src={imagesPayment.uploadImage} alt="upload" />
                  <h3 className="text-xl font-medium text-ttitle mt-3">UpLoad Image</h3>
                </div>
              ) : (
                <div>
                  <img className="w-full h-full object-cover" src={fileState.base64URL} alt="upload" />
                </div>
              )}
              <input id="upload-image" className="hidden" type="file" onChange={(e) => handleFileInputChange(e)} />
            </div>
            <p className="text-red-500 font-medium text-tsecondary mt-2">{fileState.file?.name}</p>
            {showError && <p className="text-red-500 font-medium text-xs">File field should be mandatory</p>}
          </div>
          <div className="flex justify-center items-center">
            <button
              onClick={() => handleConfirm()}
              className={classname(
                "btn w-[240px] px-10 py-3 text-base font-black self-center capitalize bg-primary text-white transition-all hover:bg-blue-600 border border-primary hover:border-solid hover:border-bggrayprimary",
                loading && "pointer-events-none cursor-not-allowed",
              )}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
