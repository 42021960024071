import { ERROR, HTTP_STATUS } from "../constants/common.constants";
import * as MSG from "../constants/msg.constants";
import { useModalContext } from "../contexts/modalContext";

export const CatchError = (error: any) => {
  // const { setModalSessionTimeout, setModalEditExceed } = useModalContext();
  const statusCode = error.response?.status;
  if (statusCode === HTTP_STATUS.AUTHEN) {
    // setModalSessionTimeout(true);
  } else if (statusCode === HTTP_STATUS.BAD_REQUEST) {
    const { code, message } = error.response.data.data;
    if (code === ERROR.USER_LIMIT_GENERATE) {
      // setModalEditExceed({
      //   isOpen: true,
      //   type: "Generate",
      //   msg: "",
      // });
    } else if (code === ERROR.USER_LIMIT_EDIT) {
      // setModalEditExceed({
      //   isOpen: true,
      //   type: "Edit",
      //   msg: "",
      // });
    } else if (code === ERROR.USER_PACKAGE_EXPIRED) {
      // setModalEditExceed({
      //   isOpen: true,
      //   type: "Expired",
      //   msg: "",
      // });
    } else {
      return message ? message : MSG.COMMON.error;
    }
  } else {
    return error?.response?.data?.message ? error?.response?.data?.message : MSG.COMMON.error;
  }
};

export default CatchError;
