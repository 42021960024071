import { memo, useEffect, useState } from "react";
import {
  AiOutlineCloseCircle,
  AiOutlineRotateLeft,
  AiOutlineRotateRight,
  AiOutlineZoomIn,
  AiOutlineZoomOut,
} from "react-icons/ai";
import { IImageInfo } from "../../../types/my-images";
import outSideClick from "../../../hooks/outSideClick";
import { ModalBottom } from "./ModalBottom";
import { ModalBody } from "./ModalBody";
import { IImgResult } from "../../../types/generate";
// needed fix
interface Props {
  detail: IImageInfo;
  data: any;
  onClickOutside: () => void;
  hideInformation?: boolean;
}

const ModalView: React.FC<Props> = memo(({ onClickOutside, data, detail, hideInformation }) => {
  const [indexImg, setIndexImg] = useState<number>(-1);
  const [rotate, setRotate] = useState(0);
  const [scale, setScale] = useState(1);

  const handleClickOutside = () => {
    onClickOutside();
  };
  const content = outSideClick(handleClickOutside);

  useEffect(() => {
    if (data) {
      const findIndex = data?.findIndex((value: any) => {
        return (value?.id || value?.imgID) === detail?.id;
      });
      setIndexImg(findIndex);
    }
  }, [data]);

  const icons = [
    <button
      onClick={() => setRotate((v) => v - 90)}
      className="w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] cursor-pointer
    hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120"
    >
      <AiOutlineRotateLeft />
    </button>,
    <button
      onClick={() => setRotate((v) => v + 90)}
      className="w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] cursor-pointer
    hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120"
    >
      <AiOutlineRotateRight />
    </button>,
    <button
      onClick={() => setScale((v) => v + 1)}
      className="w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] cursor-pointer
    hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120"
    >
      <AiOutlineZoomIn />
    </button>,
    <button
      disabled={scale < 2}
      onClick={() => setScale((v) => v - 1)}
      className={`${
        scale < 2 && "opacity-50 cursor-no-drop"
      } w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] 
      hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120`}
    >
      <AiOutlineZoomOut />
    </button>,
    <button
      onClick={handleClickOutside}
      className="w-[35px] h-[35px] text-[24px] flex justify-center items-center text-[#fff] cursor-pointer
    hover:opacity-70 hover:scale-110 active:opacity-90 active:scale-120"
    >
      <AiOutlineCloseCircle />
    </button>,
  ];

  return (
    <div
      className="flex justify-center items-center select-none
    fixed top-0 left-0 bottom-0 right-0 w-full h-full bg-[#f7f7f780] z-[999]"
    >
      <div
        ref={content}
        className="inline-flex flex-col justify-center relative rounded-[10px] shadow-lg shadow-bggray max-w-full max-h-full"
      >
        <div className="flex justify-between bg-bggray py-[5px] px-[10px] rounded-tl-[10px] rounded-tr-[10px]">
          {icons.map((icon, index) => (
            <span key={index}>{icon}</span>
          ))}
        </div>
        <ModalBody
          data={data}
          clickNext={() => setIndexImg((i: number) => i - 1)}
          clickPrevious={() => setIndexImg((i: number) => i + 1)}
          indexImg={indexImg}
          scale={scale}
          rotate={rotate}
          hideInformation={hideInformation}
        />
        <ModalBottom data={data} indexImg={indexImg} hideInformation={hideInformation} />
      </div>
    </div>
  );
});

export { ModalView };
