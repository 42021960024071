import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import contactApis from "../../apis/contact";
import { useToast } from "../../hooks/useToast/useToast";
import CatchError from "../../utils/catch-error";
import { emailRegExp } from "../../utils/regex";
import { InputContact } from "../commons/InputContact";

type InputType = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  note: string;
  checkbox: boolean;
};

const schema = yup.object({
  firstName: yup.string().required("First Name is a required field").trim(),
  lastName: yup.string().required("Last Name is a required field").trim(),
  email: yup
    .string()
    .required("Email is a required field")
    .matches(emailRegExp, "Email is invalid")
    .email("Email is invalid")
    .trim(),
  phone: yup.string().trim(),
  note: yup.string().required("Note is a required field").trim(),
});

const initFormValue = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
};

export const FormContact: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<InputType>({
    defaultValues: initFormValue,
    resolver: yupResolver(schema),
  });
  const { toast } = useToast();
  const onSubmit = async (e: InputType) => {
    if (!e.checkbox) {
      toast.error("You need to agree to the policy");
      return;
    }
    const form = {
      firstName: e?.firstName,
      lastName: e?.lastName,
      email: e?.email,
      phone: e?.phone,
      note: e?.note,
    };
    try {
      await contactApis.createContact(form);
      toast.success("Send Contact Success");
      reset();
    } catch (error: any) {
      toast.error(CatchError(error));
    }
  };
  return (
    <form className="pt-[40px]" onSubmit={handleSubmit(onSubmit)}>
      <div className=" flex-col  lg:flex lg:flex-row w-full gap-4">
        <InputContact
          register={register("firstName")}
          label="First Name"
          isRequired={true}
          placeholder="First Name"
          errors={errors.firstName}
        />
        <InputContact
          register={register("lastName")}
          label="Last Name"
          isRequired={true}
          placeholder="Last Name"
          errors={errors.lastName}
        />
      </div>
      <div className=" w-full gap-4 mt-[30px] flex-col lg:flex lg:flex-row">
        <InputContact
          register={register("email")}
          label={"Email"}
          isRequired={true}
          placeholder={"Email"}
          errors={errors.email}
        />
        <InputContact
          register={register("phone")}
          label="Phone Number"
          placeholder="Phone Number"
          errors={errors.phone}
        />
      </div>
      <div className="w-full flex flex-col mb-2 mt-[30px]">
        <InputContact
          register={register("note")}
          label="Describe your issue or your feature request"
          isRequired={true}
          placeholder="How can we help you?"
          errors={errors.note}
          textarea
        />
      </div>
      <div className="flex gap-2 mt-[31px]">
        <input
          {...register("checkbox", {
            required: true,
          })}
          type="checkbox"
          className="bg-transparent mt-[1px]"
        />
        <a href="#">
          I agree to the <span className="text-[#3E80FF]">Privacy Policy</span>
        </a>
      </div>
      <button
        type="submit"
        className="bg-[#3E80FF] w-[165px] h-[51px] rounded-xl mt-[31px] text-[18px] border border-primary font-black btn capitalize hover:bg-bgbtn transition-all"
      >
        Submit
      </button>
    </form>
  );
};
