import { memo, useEffect } from "react";
import { HiArrowRight } from "react-icons/hi";
import { favoriteApis } from "../../apis/image";
import { useCommonContext } from "../../contexts/commonContext";
import { IMyFavoriteData } from "../../types/my-images";
import { ImageItem } from "../commons/ImageItem/ImageItem";
import { Loader } from "../commons/Loader";
import { NoDataFound } from "../commons/NoDataFound";

interface Props {
  onLoadMore: () => void;
  valueSearch: string;
  arrFilterState: string[];
  typeSort: number | null;
  sortState: string;
  tab: string;
}

const ListMyFavorites: React.FC<Props> = memo(
  ({ onLoadMore, arrFilterState, sortState, typeSort, valueSearch, tab }) => {
    const { myImage, setMyImage } = useCommonContext();
    const { listMyFavoriteImages, limit, page, loadingMore, total } = myImage;
    useEffect(() => {
      setMyImage((pre) => ({ ...pre, loading: true, success: false }));
      favoriteApis
        .getMyFavoriteImages({
          prompt: valueSearch,
          styleImage: arrFilterState,
          sort: sortState,
          type: typeSort,
          limit,
          page,
        })
        .then((response) => {
          let myFavoritesData: any[];
          if (myImage.page === 1) {
            myFavoritesData = [...response.data.image];
          } else if (myImage.listMyFavoriteImages.length === myImage.total) {
            myFavoritesData = [...myImage.listMyFavoriteImages];
          } else if (myImage.page > 1 && myImage.isUnlike) {
            const lastImg = response.data.image.slice(-1);
            myFavoritesData = [...myImage.listMyFavoriteImages, ...lastImg];
          } else {
            myFavoritesData = [...myImage.listMyFavoriteImages, ...response.data.image];
          }
          setMyImage((pre) => ({
            ...pre,
            loading: false,
            success: true,
            total: response.data.total,
            data: response.data.image.map((item: IMyFavoriteData) => item.image),
            listMyFavoriteImages: myFavoritesData,
          }));
        })
        .catch((error) => {
          setMyImage((pre) => ({ ...pre, loading: false, success: false, error: error }));
        });
    }, [valueSearch, arrFilterState, sortState, typeSort, page, total]);

    return (
      <>
        {/* List My Favorites */}
        {listMyFavoriteImages.length >= 1 ? (
          <div
            className=" grid 
        gap-x-4 
        gap-y-4 
        md:gap-x-3 
        md:gap-y-3 
        lg:gap-x-4 
        lg:gap-y-4 

        2xl:grid-cols-4 
        lg:grid-cols-3
        sm:grid-cols-2

        mt-5
        sm:mt-5
        md:mt-9"
          >
            {listMyFavoriteImages?.map((item: IMyFavoriteData) => (
              <ImageItem key={item.id} dataImage={item.image} type="myImages" tab="myfavorites" />
            ))}
          </div>
        ) : (
          <div className="mt-[20px] w-4/6 mx-auto">
            <NoDataFound />
          </div>
        )}
        <>
          {listMyFavoriteImages.length < total && (
            <div className="load-more text-center mt-10">
              {loadingMore ? (
                <Loader />
              ) : (
                <div className="flex justify-center items-center">
                  <button
                    className="text-white px-[50px] py-[14px] text-lg font-black bg-primary rounded-rddefault flex items-center hover:bg-bgbtn transition-all"
                    onClick={onLoadMore}
                  >
                    Load More
                    <span className="w-4 h-[14px] ml-[14px] mb-1">
                      <HiArrowRight />
                    </span>
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      </>
    );
  },
);

export { ListMyFavorites };
