import { PropsWithChildren, useState } from "react";
import { createContext } from "../utils/createContext";
import { IImageInfo, IMyFavoriteData, IMyImageParams } from "../types/my-images";
import { IProfileAction, IUserInfo } from "../types/user";
import { INotificationMessage } from "../types/notification";
import { LOCAL_STORAGE_TOKEN_NAME, LOCAL_STORAGE_USER_INFO } from "../constants/common.constants";
import { authApis, userApis } from "../apis/auth";
import { FormDataLogin } from "../pages/authenicator/sign-in/SignIn";

interface ContextProps {
  collectionImages: IImageInfo[];
  setCollectionImages: React.Dispatch<React.SetStateAction<IImageInfo[]>>;
  profileAction: IProfileAction;
  setProfileAction: React.Dispatch<React.SetStateAction<IProfileAction>>;
  notification: TNotificationType;
  setNotification: React.Dispatch<React.SetStateAction<TNotificationType>>;
  handleClickOnNoti: () => void;
  isRefreshPage: boolean;
  user: IUserState;
  setUser: React.Dispatch<React.SetStateAction<IUserState>>;
  handleUserLogout: () => void;
  getUserInformation: () => void;
  userLogin: (data: FormDataLogin, callback: () => void) => void;
  myImage: IMyImageState;
  setMyImage: React.Dispatch<React.SetStateAction<IMyImageState>>;
  myImageLoadMore: () => void;
  resetPageMyImage: () => void;
  setListMyFavoriteImg: (data: IMyFavoriteData[] | []) => void;
}
export type TNotificationType = {
  listNoti: INotificationMessage[];
  geneAndEditNoti: INotificationMessage[];
  historyPaymentNoti: INotificationMessage[];
  promotionNoti: INotificationMessage[];
  total: number;
};
export type IUserState = {
  loading: boolean;
  userInfo: IUserInfo | null;
  userToken: string | null;
  avatar: string | null;
  error: any;
  regisSuccess: boolean;
};
export type IMyImageState = {
  loading: boolean;
  imageId: string | null;
  listMyImages: IImageInfo[];
  listMyFavoriteImages: IMyFavoriteData[] | [];
  data: IImageInfo[];
  loadingMore: boolean;
  page: number;
  isDelete: boolean;
  isUnlike: boolean;
  limit: number;
  total: number;
  success: boolean;
  error: string | null;
};

const initMyImage: IMyImageState = {
  loading: false,
  imageId: null,
  listMyImages: [],
  listMyFavoriteImages: [],
  data: [],
  loadingMore: false,
  limit: 12,
  page: 1,
  isDelete: false,
  isUnlike: false,
  total: 0,
  success: false,
  error: null,
};
const initNotification: TNotificationType = {
  listNoti: [],
  geneAndEditNoti: [],
  historyPaymentNoti: [],
  promotionNoti: [],
  total: 0,
};

// initialize userToken from local storage
const userToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME)
  ? localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME)
  : null;
const userInfo: IUserInfo = localStorage.getItem(LOCAL_STORAGE_USER_INFO)
  ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_INFO) || "{}")
  : null;

const initUserState: IUserState = {
  loading: false,
  userInfo,
  userToken,
  avatar: null,
  error: null,
  regisSuccess: false,
};

const [Provider, useCommonContext] = createContext<ContextProps>();

export { useCommonContext };

export default function CommonContextProvider({ children }: PropsWithChildren) {
  const [collectionImages, setCollectionImages] = useState<IImageInfo[]>([]);
  const [profileAction, setProfileAction] = useState<IProfileAction>(IProfileAction.info);
  const [notification, setNotification] = useState<TNotificationType>(initNotification);
  const [isRefreshPage, setRefreshPage] = useState<boolean>(false);
  const [user, setUser] = useState<IUserState>(initUserState);
  const [myImage, setMyImage] = useState<IMyImageState>(initMyImage);

  const handleClickOnNoti = () => {
    setRefreshPage(!isRefreshPage);
  };

  const handleUserLogout = () => {
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME);
    localStorage.removeItem(LOCAL_STORAGE_USER_INFO);
    setUser({
      loading: false,
      userInfo: null,
      userToken: null,
      error: null,
      avatar: null,
      regisSuccess: false,
    });
  };
  const userLogin = (data: FormDataLogin, callback: () => void) => {
    setUser((pre) => ({ ...pre, loading: true, error: null, success: false }));
    if (data.googleId || data.facebookId) {
      authApis
        .singleSignOnSignin(data)
        .then((response) => {
          callback();
          setUser((pre) => ({
            ...pre,
            loading: false,
            error: null,
            success: true,
            userToken: response.data.accessToken,
          }));
        })
        .catch((error) => {
          setUser((pre) => ({ ...pre, loading: false, error: error, success: false }));
        });
    } else {
      authApis
        .login(data)
        .then((response) => {
          callback();
          setUser((pre) => ({
            ...pre,
            loading: false,
            error: null,
            success: true,
            userToken: response.data.accessToken,
          }));
        })
        .catch((error) => {
          setUser((pre) => ({ ...pre, loading: false, error: error, success: false }));
        });
    }
  };
  const getUserInformation = () => {
    setUser((pre) => ({ ...pre, loading: true }));
    userApis
      .information()
      .then((response) => {
        localStorage.setItem(LOCAL_STORAGE_USER_INFO, JSON.stringify(response.data));
        setUser((pre) => ({ ...pre, loading: false, userInfo: response.data }));
      })
      .catch((error) => {
        setUser((pre) => ({ ...pre, loading: false }));
      });
  };
  const myImageLoadMore = () => {
    setMyImage((pre) => ({ ...pre, page: pre.page + 1, loadingMore: true, isDelete: false, isUnlike: false }));
  };
  const resetPageMyImage = () => {
    setMyImage((pre) => ({ ...pre, page: 1 }));
  };
  const setListMyFavoriteImg = (data: IMyFavoriteData[] | []) => {
    setMyImage((pre) => ({ ...pre, listMyFavoriteImages: data, isUnlike: true, total: pre.total - 1 }));
  };
  return (
    <Provider
      value={{
        collectionImages,
        setCollectionImages,
        profileAction,
        setProfileAction,
        notification,
        setNotification,
        handleClickOnNoti,
        isRefreshPage,
        user,
        setUser,
        handleUserLogout,
        getUserInformation,
        userLogin,
        myImage,
        setMyImage,
        myImageLoadMore,
        resetPageMyImage,
        setListMyFavoriteImg,
      }}
    >
      {children}
    </Provider>
  );
}
