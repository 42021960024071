import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { SELECTED } from "../../constants";
import { IoIosArrowDown } from "react-icons/io";

interface IPropsSelected {
  isVisible: boolean;
  setVisible(p: boolean): void;
  setValue(p: any, v: any): void;
  initValue: string;
  fieldName: string;
  dataOption: SELECTED.OptionItem[];
  handleClickImage?: any;
  selectedImageId?: string;
  showScroll?: boolean;
}

const Selected: React.FC<IPropsSelected> = memo((props) => {
  const {
    isVisible,
    setVisible,
    setValue,
    initValue,
    fieldName,
    dataOption,
    handleClickImage,
    showScroll = true,
  } = props;
  const [items, setItems] = useState<string>(initValue || "");

  const onToggle = useCallback(() => {
    setVisible(!isVisible);
  }, [isVisible]);

  const handlerChangeItem = useCallback(
    async (item: SELECTED.OptionItem) => {
      setItems(item.name);
      setVisible(false);
    },
    [items, isVisible],
  );

  useEffect(() => {
    setValue(fieldName, items);
  }, [items]);

  const memoizedValueOption = useMemo(() => dataOption, []);

  return (
    <>
      <div
        onClick={onToggle}
        className="relative cursor-pointer border-solid border-[#667085] border-[1px] h-[48px] rounded-[10px] px-2  w-full flex items-center"
      >
        <div className="flex w-full justify-between items-center text-ttitle">
          <div className="flex gap-2 ">{items}</div>
          <IoIosArrowDown />
        </div>
      </div>
      <div
        className={`absolute  cursor-pointer  bg-bggray w-full text-tprimary  shadow-xl mt-2 drop-shadow-xl rounded-lg  duration-300 origin-[97%_0%] z-[99] ${
          showScroll ? "overflow-y-scroll" : ""
        }  max-h-[200px] ${isVisible ? "scale-100" : "scale-0"}`}
      >
        {memoizedValueOption.map((item) => (
          <button
            className="flex flex-col hover:bg-blue-100 hover:text-primary px-2 py-2 w-full"
            key={item.name}
            onClick={() => handlerChangeItem(item)}
            type="button"
          >
            <div className="flex gap-2 items-center">
              {item?.img && (
                <img className="object-cover object-center rounded-full w-[30px] h-[30px]" src={item?.img}></img>
              )}
              {item.name}
            </div>
          </button>
        ))}
      </div>
    </>
  );
});

export { Selected };
