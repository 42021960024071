import React, { useEffect, useState } from "react";
import { InputContact, StylesArt } from "../../../components";
import backImage from "../../../assets/images/BackImage.svg";
export interface Props {
  collapes: string | boolean;
  setCollapes(e: string): void;
  items: string[];
  handlerChangeItem(i: string): void;
  register?: any;
  errors?: any;
}

const BannerRight: React.FC<Props> = (props) => {
  const { register, errors, collapes, setCollapes, items, handlerChangeItem } = props;
  // const [isOpen, setIsOpen] = useState(false);

  // const handleCollapes = () => {
  //   setIsOpen(!isOpen);
  // };

  // const optionsInput = [
  //   {
  //     id: 1,
  //     element: (
  //       <InputContact
  //         register={register("hashtag", { pattern: /^#[^#\s]+(?: #[^#\s]+)*$/ })}
  //         label="Hashtag"
  //         errors={errors.hashtag}
  //         errorsMessage="Invalid hashtag, please add # before each hashtag"
  //       />
  //     ),
  //   },
  // ];

  // useEffect(() => {
  //   if (errors?.hashtag) {
  //     setIsOpen(true);
  //   }
  // }, [errors?.hashtag]);

  return (
    <>
      <div className="w-full h-full overflow-y-auto bg-white/[2%] backdrop-blur-[24px] border-[rgb(42,50,64)]  border-[1px]  p-5 rounded-lg">
        {/* <div className="mt-4">
          <>
            {isOpen ? (
              <div className="flex cursor-pointer" onClick={handleCollapes}>
                <img className="rotate-0" src={backImage} alt="" />
                <span className="ml-2 font-black ">INPUTS</span>
              </div>
            ) : (
              <div
                onClick={handleCollapes}
                className="cursor-pointer border-solid justify-between border-[#667085] border-[1px] h-[48px] rounded-xl px-2  w-full flex items-center"
              >
                Inputs
                <img className="rotate-180" src={backImage} alt="" />
              </div>
            )}
          </>
          <>
            <div className={`${!isOpen && "hidden"} py-3 duration-75`}>
              {optionsInput?.map((value, index) => {
                return (
                  <div className={`${index !== 0 && " mt-4"}`} key={value.id}>
                    {value.element}
                  </div>
                );
              })}
            </div>
          </>
        </div> */}

        <StylesArt collapes={collapes} setCollapes={setCollapes} items={items} handlerChangeItem={handlerChangeItem} />
      </div>
    </>
  );
};

export { BannerRight };
