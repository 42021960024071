import { NavLink } from "react-router-dom";
import { imagesCommon } from "../../../assets/images";
import { LogoBanner } from "../../../layout/header/LogoBanner";
import { MagicWand } from "./MagicWand";
import { PATH } from "../../../routes/paths";

const styles = {
  backgroundBanner: {
    background: "radial-gradient(51.89% 51.89% at 50% 50%, rgba(71, 118, 230, 0.3) 0%, rgba(142, 84, 233, 0) 100%)",
  },
  ellipse8: {
    background: "radial-gradient(50% 50% at 50% 50%, rgba(0, 82, 212, 0.3) 0%, rgba(111, 177, 252, 0) 100%)",
  },
};

const Banner = () => {
  return (
    <div className="flex pt-[154px] relative w-full max-[1150px]:flex-col">
      <div className="absolute top-0 left-0 translate-y-[28px] translate-x-[-10px]">
        <img src={imagesCommon.ellipse13} />
      </div>
      {/* -----------------content--------------- */}
      <div className="flex relative w-1/2 max-[1150px]:w-full max-[1150px]:justify-center px-[20px]">
        <div style={styles.backgroundBanner} className="z-[1] flex flex-col w-full translate-y-[-45px]">
          <div
            className="text-primary text-[85px] font-black
             max-[550px]:text-[60px] max-[370px]:text-[55px] text-center leading-[60px]"
          >
            Welcome to
          </div>
          <div className="flex justify-center bgh mt-2">
            <div className="flex justify-center items-center ">
              <div className="relative w-full flex justify-center items-center">
                <div className="z-[1] flex justify-center ">
                  <LogoBanner />
                </div>
                <img
                  src={imagesCommon.traced}
                  className="absolute top-[50%] left-[50%] w-[300px] translate-x-[-50%] translate-y-[-50%]"
                />
              </div>
            </div>
            <div>
              <MagicWand />
            </div>
          </div>

          <div className="mt-6">
            <div className="text-white text-center text-ttitle">
              Unleash your creativity with our AI Art Generator - effortlessly generate stunning and unique artworks
              with a click!
            </div>
            <div className="flex justify-center mt-4">
              <NavLink
                to={PATH.art}
                className="text-white px-[30px] py-[14px] text-lg font-black rounded-rddefault w-max bg-primary cursor-pointer
              hover:bg-bgbtn transition-all "
              >
                Generate AI Art
              </NavLink>
            </div>
          </div>
        </div>

        {/* -----------------icon elip--------------- */}
        <div
          className="absolute bottom-[20px] right-[10px] 
          max-[1360px]:bottom-0 max-[1360px]:right-0 
          max-[1360px]:translate-x-[50%] max-[1360px]:translate-y-[50%] 
          max-[1360px]:scale-[0.8]
          max-[1150px]:bottom-0
          max-[1150px]:left-[50%]
          max-[1150px]:translate-x-[25%] max-[1150px]:translate-y-[100%]"
        >
          <img src={imagesCommon.ellipse11} />
        </div>
      </div>

      <div className="w-1/2 relative flex justify-center max-[1150px]:w-full max-[1150px]:pt-[137px] items-start">
        <div className="gap-x-2 md:gap-x-6 flex justify-center relative w-[60%] max-[1150px]:w-[60%] max-[550px]:w-[90%]">
          <img src={imagesCommon.rectangle1} className="w-1/3 object-contain z-[1] rounded-[10px]" />
          <img
            src={imagesCommon.rectangle2}
            className="w-1/3 object-contain z-[1] rounded-[10px] translate-y-[-61px]"
          />
          <img src={imagesCommon.rectangle3} className="w-1/3 object-contain z-[1] rounded-[10px]" />
        </div>

        {/* -----------------icon elip--------------- */}
        <div className="absolute top-0 left-0 translate-x-[100%] translate-y-[-50%]">
          <img src={imagesCommon.ellipse10} />
        </div>
        <div className="absolute bottom-0 left-[50%] translate-y-[25%]">
          <img src={imagesCommon.ellipse12} />
        </div>
        <div
          style={styles.ellipse8}
          className="absolute top-0 right-0 inline-block translate-y-[50%] translate-x-[25%] w-[762px] h-[500px]"
        />
      </div>
    </div>
  );
};

export { Banner };
