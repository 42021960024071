import { axiosClient } from "./axiosClient";

const paymentApis = {
  signTransactionVisa: (data: any) => axiosClient.post(`/api/payment/signed-transaction-visa`, data),
  signTransactionBankTransfer: (data: any) => axiosClient.post(`/api/payment/signed-transaction-bank-transfer`, data),
  createInvoiceStripe: (data: any) => axiosClient.post(`/api/payment/stripe-payment`, data),
  getResultPayment: (paymentId: any) => axiosClient.get(`/api/payment/check-result-payment-vnpay/${paymentId}`),
  getStatusPayment: () => axiosClient.get(`/api/payment/check-status-payment`),
  createPayment: (data: any) => axiosClient.post(`/api/payment/create-payment-vnpay-url`, data),
};

const paymentHistoryApis = {
  get: () => axiosClient.get(`/api/payment/user-payment-history`),
};

export { paymentApis, paymentHistoryApis };
