import { memo } from "react";
import { MdFileDownload, MdOutlineModeEdit, MdPublic, MdPublicOff, MdShare } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import { myImagesApis } from "../../apis/image";
import generateImgApis from "../../apis/generateImage";
import { COMMON } from "../../constants";
import { useCommonContext } from "../../contexts/commonContext";
import { useGenerateImage } from "../../contexts/generateImageContext";
import { useModalContext } from "../../contexts/modalContext";
import { useToast } from "../../hooks/useToast/useToast";
import { IImageInfo } from "../../types/my-images";
import CatchError from "../../utils/catch-error";
import { downloadImgToDevice, getBase64FromUrl } from "../../utils/helpers";
import { ShareSocial } from "../commons/ShareSocial";

interface Props {
  tab?: string;
  dataImage: IImageInfo;
  isPopup: boolean;
  onClickPopup?: () => void;
}

const ActionsWithMyImage: React.FC<Props> = memo(({ tab, dataImage, isPopup, onClickPopup }) => {
  const { id, isPublic, imageUrl } = dataImage;
  const { toast } = useToast();
  const { setModalChooseOptionEdit } = useModalContext();
  const { setImgEdit } = useGenerateImage();
  const { myImage, setMyImage } = useCommonContext();

  const handlePublicImage = (id: string) => {
    setMyImage((pre) => ({ ...pre, loading: true, success: false }));
    myImagesApis
      .publicImgUser({ imageId: id })
      .then((response) => {
        setMyImage((pre) => ({ ...pre, loading: false, success: true }));
        const indexImage = myImage.listMyImages.findIndex((image: IImageInfo) => image._id === id);
        if (indexImage !== -1) {
          const newListMyImage = myImage.listMyImages.map((img, index) =>
            index === indexImage ? { ...img, isPublic: !img.isPublic } : img,
          );
          setMyImage((pre) => ({ ...pre, listMyImages: newListMyImage }));
        }
      })
      .catch((error) => {
        setMyImage((pre) => ({ ...pre, loading: false, success: false, error: error }));
      });
  };

  const handleDeleteImage = (id: string) => {
    setMyImage((pre) => ({ ...pre, loading: true, success: false }));
    myImagesApis
      .deleteImgUser({ imageId: id })
      .then((response) => {
        setMyImage((pre) => ({
          ...pre,
          loading: false,
          success: true,
          isDelete: true,
          total: pre.total - 1,
          listMyImages: myImage.listMyImages.filter((image: IImageInfo) => image._id !== response.data.imageId),
        }));
      })
      .catch((error) => {
        setMyImage((pre) => ({ ...pre, loading: false, success: false, error: error }));
      });
  };

  const handleEditImage = async (imageUrl: string) => {
    try {
      const times = await generateImgApis.checkEditTimes();
      if (times.status === COMMON.HTTP_STATUS.SUCCESS) {
        const base64 = await getBase64FromUrl(imageUrl);
        setImgEdit({
          imgID: dataImage.id,
          styleImage: dataImage.styleImage,
          prompt: dataImage.prompt,
          url: base64 as string,
          like: false,
          hashtag: dataImage?.hashtag || "",
        });
        setModalChooseOptionEdit(true);
      }
    } catch (e) {
      toast.error(CatchError(e));
    }
  };
  return (
    <>
      {tab === "myimages" && (
        <label
          htmlFor={`${id}/public`}
          className="z-[999] w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-bggray flex justify-center items-center
          hover:opacity-70 hover:scale-[1.1] active:opacity-90 active:scale-[1.2] mx-[5px]"
          onClick={onClickPopup}
        >
          {isPublic ? (
            <MdPublicOff className="w-full h-full p-[5px] rounded-[50%]" />
          ) : (
            <MdPublic className="w-full h-full p-[5px] rounded-[50%]" />
          )}
        </label>
      )}
      <label
        className="z-[999] w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-bggray flex justify-center items-center
          hover:opacity-70 hover:scale-[1.1] active:opacity-90 active:scale-[1.2] mx-[5px]"
      >
        <MdFileDownload onClick={() => downloadImgToDevice(imageUrl)} className="w-full h-full p-[5px] rounded-[50%]" />
      </label>

      <label
        htmlFor={`${id}/social`}
        className="z-[999] w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-bggray flex justify-center items-center
        hover:opacity-70 hover:scale-[1.1] active:opacity-90 active:scale-[1.2] mx-[5px]"
        onClick={onClickPopup}
      >
        <MdShare className="w-full h-full p-[5px] rounded-[50%]" />
      </label>
      <label
        className="z-[999] w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-bggray flex justify-center items-center
        hover:opacity-70 hover:scale-[1.1] active:opacity-90 active:scale-[1.2] mx-[5px]"
      >
        <MdOutlineModeEdit onClick={() => handleEditImage(imageUrl)} className="w-full h-full p-[5px] rounded-[50%]" />
      </label>
      {tab === "myimages" && (
        <label
          htmlFor={`${id}/delete`}
          className="z-[999] w-[35px] h-[35px] rounded-[50%] text-[24px] text-[#ffffff] cursor-pointer bg-bggray flex justify-center items-center
          hover:opacity-70 hover:scale-[1.1] active:opacity-90 active:scale-[1.2] mx-[5px]"
          onClick={onClickPopup}
        >
          <RiDeleteBinLine className="w-full h-full p-[5px] rounded-[50%]" />
        </label>
      )}

      <>
        {isPopup && (
          <div>
            <input type="checkbox" id={`${id}/public`} className="modal-toggle" />
            <div
              className="modal w-[300px] h-[250px] absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] 
              rounded-lg bg-transparent flex justify-center items-center"
            >
              <div className="modal-box rounded-lg p-[10px]">
                <p className="pb-2 text-center">{isPublic ? "Set private this image?" : "Public this image?"}</p>
                <div className="modal-action mt-1 justify-center">
                  <label
                    onClick={() => handlePublicImage(id)}
                    htmlFor={`${id}/public`}
                    className="btn btn-sm bg-blue-600 border-none"
                  >
                    Yes
                  </label>
                  <label htmlFor={`${id}/public`} className="btn btn-sm border-none">
                    No
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
      </>

      {isPopup && (
        <div>
          <input type="checkbox" id={`${id}/delete`} className="modal-toggle" />
          <div
            className="modal w-[300px] h-[max-content] absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] 
            rounded-lg bg-transparent flex justify-center items-center"
          >
            <div className="modal-box rounded-lg p-[10px]">
              <p className="pb-2 text-center">You want to delete this image?</p>
              <div className="modal-action mt-1 justify-center">
                <label
                  onClick={() => handleDeleteImage(id)}
                  htmlFor={`${id}/delete`}
                  className="btn btn-sm bg-red-600 border-none"
                >
                  Delete
                </label>
                <label htmlFor={`${id}/delete`} className="btn btn-sm border-none">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
      )}

      <>{isPopup && <ShareSocial id={id} />}</>
    </>
  );
});

export { ActionsWithMyImage };
