import { useState } from "react";
import { IoClose } from "react-icons/io5";
import { imagesInterior } from "../../assets/images";
interface IDropZone {
  icon?: any;
  content?: string;
  isIcon: boolean;
  classDrops?: string;
  isGetPromp?: boolean;
  register?: any;
  fileState?: any;
  setFileState?: any;
  setValue?: any;
}
export function DropZone(props: IDropZone) {
  const {
    icon,
    content,
    isIcon,
    classDrops = "",
    isGetPromp = false,
    register,
    setFileState,
    fileState,
    setValue,
  } = props;
  const [img, setImg] = useState<string>("");
  const onChangeFile = (e: any, multiple = false) => {
    const file = multiple ? e.target.files : e.target.files[0];
    const imgRender: any = URL.createObjectURL(file);
    setImg(imgRender);
    setFileState({ ...fileState, file: file });
    setValue("file_input", { file: file });
  };
  const handleRemoveImage = () => {
    setImg("");
    setValue("file_input", undefined);
  };
  return (
    <>
      {img ? (
        <div className={`relative h-[222px]`}>
          <div
            onClick={handleRemoveImage}
            className={`text-white absolute top-2 right-2 text-xl h-5 w-5 rounded-full bg-black duration-500  hover:duration-500 hover:shadow-remove `}
          >
            <IoClose className="text-white cursor-pointer ml-[2px] w-4" />
          </div>
          {isGetPromp && (
            <div
              onClick={(e) => {
                setImg("");
              }}
              className={`bg-[#0f1828] absolute text-white w-[84px] h-[24px] flex justify-center items-center top-2 left-2 cursor-pointer rounded-md text-[12px]  duration-500 hover:duration-500 hover:shadow-remove`}
            >
              Get prompt !
            </div>
          )}
          <img className="w-full h-full object-center rounded-xl" src={img} alt="interior" />
        </div>
      ) : (
        <label
          className={`relative flex cursor-pointer justify-center items-center h-[222px]  mb-4  border-[1.6px] border-dashed border-[#667085] rounded-xl text-white/60 font-medium ${classDrops}`}
        >
          <div className="flex flex-col  justify-center  items-center">
            {isIcon && <img src={icon || imagesInterior.iconUpLoad} width={30} height={28} alt="" />}
            <p className={` ${isIcon ? "mt-[24px]" : ""}`}>
              {content || "Drop an image, tap to selector take a photo"}
            </p>
          </div>
          <input
            {...register}
            type="file"
            multiple={true}
            id="input-file"
            hidden
            onChange={onChangeFile}
            accept={"image/*, image/HEIC"}
          />
        </label>
      )}
    </>
  );
}
