const MagicWand = () => {
  return (
    <>
      <svg width="123" height="121" viewBox="0 0 123 121" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M115.888 119.532L121.11 114.301L44.135 37.3256L38.9122 42.5484L115.888 119.532Z"
          fill="url(#paint0_linear_790_3396)"
        />
        <path
          d="M40.5912 39.0131C42.4897 37.1147 44.1265 37.3256 44.1265 37.3256L57.7278 50.9269C56.9262 50.7497 55.5172 51.4331 54.26 52.6819C53.0112 53.9306 52.3278 55.3397 52.505 56.1497L38.9037 42.5485C38.9122 42.5485 38.6928 40.92 40.5912 39.0131Z"
          fill="url(#paint1_linear_790_3396)"
        />
        <path
          d="M103.822 102.244C105.72 100.345 107.357 100.556 107.357 100.556L121.001 114.191C120.199 114.014 118.748 114.664 117.491 115.913C116.242 117.161 115.592 118.621 115.769 119.423L102.134 105.779C102.134 105.779 101.923 104.142 103.822 102.244Z"
          fill="url(#paint2_linear_790_3396)"
        />
        <path
          d="M115.806 119.453C115.167 118.813 115.819 117.125 117.262 115.682C118.705 114.239 120.393 113.587 121.033 114.226C121.672 114.865 121.02 116.554 119.577 117.997C118.133 119.44 116.445 120.092 115.806 119.453Z"
          fill="#9E9E9E"
        />
        <path
          opacity="0.85"
          d="M44.1519 37.3425L101.476 94.6753C102.118 95.3166 101.468 97.0041 100.025 98.4384C98.5822 99.8812 96.8947 100.531 96.2619 99.8897L38.9291 42.5569C38.9291 42.5569 38.5747 40.9706 40.5997 39.0047C42.6247 37.0387 44.1519 37.3425 44.1519 37.3425Z"
          fill="url(#paint3_linear_790_3396)"
        />
        <path
          opacity="0.22"
          d="M63.7522 56.9428L121.077 114.276C121.718 114.917 121.068 116.604 119.625 118.039C118.183 119.482 116.495 120.131 115.862 119.49L58.521 62.1657C58.521 62.1657 58.1666 60.5794 60.1916 58.6135C62.225 56.656 63.7522 56.9428 63.7522 56.9428Z"
          fill="url(#paint4_linear_790_3396)"
        />
        <path
          d="M43.7553 37.6715C46.9109 38.4815 50.1087 39.1818 53.374 39.4518C56.6394 39.7471 59.9553 39.5109 63.1784 38.9287C66.4015 38.3296 69.5656 37.5787 72.755 36.9881C75.9444 36.3975 79.1506 35.925 82.3737 35.5537C83.9853 35.3681 85.6053 35.2331 87.2169 35.0728C88.7947 34.9209 90.3556 34.9631 91.8406 35.309C93.3003 35.6803 94.6587 36.3806 95.5362 37.5281C96.4222 38.6587 96.844 40.1268 96.844 41.6371C96.8609 44.6831 95.6375 47.7206 94.085 50.4628C92.524 53.2218 90.4737 55.7278 88.4234 58.2337C84.3397 63.2625 79.9269 68.0465 75.5478 72.8643C73.354 75.2775 71.1603 77.6821 69.0003 80.129C66.8234 82.559 64.7225 85.065 62.7144 87.6468C60.6978 90.2203 58.7825 92.9118 57.2215 95.8312C56.8334 96.54 56.4031 97.3247 56.2765 98.2697C56.2344 98.7506 56.285 99.2822 56.555 99.7546C56.825 100.227 57.2722 100.522 57.7025 100.717C58.5884 101.071 59.4659 101.096 60.3097 101.054C61.1534 101.003 61.9803 100.868 62.7903 100.683C66.0134 99.8981 69.0256 98.5565 71.9281 97.0462C73.3794 96.2868 74.7969 95.4768 76.189 94.6247C77.5728 93.764 78.9312 92.8528 80.2644 91.9246C77.4969 93.6459 74.6618 95.249 71.734 96.6412C68.8062 98.025 65.7603 99.2315 62.63 99.8643C61.8453 100.008 61.0606 100.126 60.2844 100.151C59.5081 100.177 58.7234 100.109 58.099 99.839C57.7953 99.6956 57.5422 99.51 57.4156 99.2568C57.289 99.0122 57.2553 98.7 57.289 98.3709C57.3903 97.6959 57.7531 97.0209 58.1665 96.3206C59.7444 93.5362 61.6765 90.9375 63.7353 88.44C71.9703 78.45 81.3528 69.3712 89.7228 59.2968C91.7731 56.7571 93.8487 54.1753 95.4772 51.2475C97.0803 48.345 98.405 45.1218 98.3628 41.6034C98.3459 39.8653 97.8397 38.0343 96.6922 36.6C95.5531 35.1403 93.8487 34.2965 92.1697 33.8915C90.4737 33.5203 88.7356 33.4781 87.0734 33.6721C85.4534 33.8746 83.825 34.0434 82.205 34.2712C78.965 34.7268 75.7419 35.2837 72.544 35.9503C69.3462 36.6253 66.1822 37.4353 63.0181 38.1187C59.8625 38.8106 56.6394 39.1734 53.3993 39.0046C50.1678 38.8528 46.9531 38.3043 43.7553 37.6715Z"
          fill="#3E80FF"
        />
        <path
          d="M67.8107 45.0713C77.3029 47.2819 88.1957 40.8525 98.2532 41.9832C102.092 42.4894 103.67 47.0794 99.9829 54.3525C92.7435 66.8147 82.1798 74.1722 78.2816 78.7369C83.5213 75.8007 94.2876 68.0635 101.772 60.7819C113.036 49.9819 110.952 36.4819 100.506 36.5241C97.0044 36.1866 90.2291 37.8741 87.3941 38.9119C84.6013 40.0341 75.0923 44.1769 67.8107 45.0713Z"
          fill="#3E80FF"
          fillOpacity="0.1"
        />
        <circle
          cx="28.9901"
          cy="28.9902"
          r="20.5"
          transform="rotate(-44.4701 28.9901 28.9902)"
          fill="url(#paint5_diamond_790_3396)"
        />
        <circle
          cx="29.0278"
          cy="75.0278"
          r="12.041"
          transform="rotate(-44.4701 29.0278 75.0278)"
          fill="url(#paint6_diamond_790_3396)"
        />
        <circle
          cx="75.0278"
          cy="20.0278"
          r="12.041"
          transform="rotate(-44.4701 75.0278 20.0278)"
          fill="url(#paint7_diamond_790_3396)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_790_3396"
            x1="82.6236"
            y1="75.8143"
            x2="77.3985"
            y2="81.0396"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.595" stopColor="#616161" />
            <stop offset="0.775" stopColor="#565656" />
            <stop offset="1" stopColor="#424242" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_790_3396"
            x1="50.0075"
            y1="44.1246"
            x2="44.2826"
            y2="49.8503"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.262" stopColor="#F5F5F5" />
            <stop offset="0.466" stopColor="#EAEAEA" />
            <stop offset="0.822" stopColor="#CDCDCD" />
            <stop offset="0.989" stopColor="#BDBDBD" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_790_3396"
            x1="113.261"
            y1="107.37"
            x2="107.522"
            y2="113.109"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.262" stopColor="#F5F5F5" />
            <stop offset="0.466" stopColor="#EAEAEA" />
            <stop offset="0.822" stopColor="#CDCDCD" />
            <stop offset="0.989" stopColor="#BDBDBD" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_790_3396"
            x1="67.2993"
            y1="65.7153"
            x2="38.1073"
            y2="36.5232"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FADA80" stopOpacity="0" />
            <stop offset="1" stopColor="#FADA80" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_790_3396"
            x1="127.118"
            y1="125.534"
            x2="106.915"
            y2="105.331"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1A237E" />
            <stop offset="0.186" stopColor="#1D2A87" stopOpacity="0.814" />
            <stop offset="0.49" stopColor="#243DA0" stopOpacity="0.51" />
            <stop offset="0.873" stopColor="#2F5CC7" stopOpacity="0.127" />
            <stop offset="1" stopColor="#3367D6" stopOpacity="0" />
          </linearGradient>
          <radialGradient
            id="paint5_diamond_790_3396"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(28.9901 28.9902) scale(20.5)"
          >
            <stop stopColor="#E6CD47" />
            <stop offset="1" stopColor="#E9E354" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint6_diamond_790_3396"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(29.0278 75.0278) scale(12.041)"
          >
            <stop stopColor="#E6CD47" />
            <stop offset="1" stopColor="#E9E354" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint7_diamond_790_3396"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(75.0278 20.0278) scale(12.041)"
          >
            <stop stopColor="#E6CD47" />
            <stop offset="1" stopColor="#E9E354" stopOpacity="0" />
          </radialGradient>
        </defs>
      </svg>
    </>
  );
};

export { MagicWand };
