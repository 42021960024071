import { IProfileAction, IProfileState } from "../types/user";

export const ProfileState: IProfileState[] = [
  {
    type: IProfileAction.info,
    content: "Personal Information",
  },
  {
    type: IProfileAction.changePassword,
    content: "Change Password",
  },
  // {
  //   type: IProfileAction.upgrade,
  //   content: "Upgrade Account",
  // },
  {
    type: IProfileAction.paymentHistory,
    content: "Payment history",
  },
];

export interface IPackage {
  id: number;
  title: string;
  content: string;
  sale?: string;
  price: string;
}

// export const Packages: IPackage[] = [
//   {
//     id: 1,
//     title: "1 Week (17$)",
//     content: "Generate thoả thích trong vòng 1 tuần",
//     price: "17$/Week",
//   },
//   {
//     id: 2,
//     title: "1 Month (42$)",
//     content: "Generate thoả thích trong vòng 1 tháng",
//     sale: "Save 10%",
//     price: "14$/Week",
//   },
//   {
//     id: 3,
//     title: "3 Month (144$)",
//     content: "Generate thoả thích trong vòng 3 tháng",
//     sale: "Save 15%",
//     price: "12$/Week",
//   },
//   {
//     id: 4,
//     title: "1 Years (480$)",
//     content: "Generate thoả thích trong vòng 1 năm",
//     sale: "Save 23%",
//     price: "10$/Week",
//   },
// ];
