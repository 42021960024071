import { axiosClient } from "./axiosClient";

const generateImgApis = {
  checkGenerateTime: () => axiosClient.post<any>(`/api/image/check-generate-image/`),
  increaseTimesGenerateImage: () => axiosClient.post<any>(`/api/image/increase-times-generate-image`),
  checkEditTimes: () => axiosClient.post<any>(`/api/image/check-edit-image/`),
  increaseTimesEditImage: () => axiosClient.post<any>(`/api/image/increase-times-edit-image`),
  saveImageCollections: (data: any) => axiosClient.post<any>(`/api/image/save/`, data),
  myImageCollections: (params: any) => axiosClient.get<any>(`/api/image/my-image/`, { params }),
  getDetailImage: (imageId: any) => axiosClient.get<any>(`/api/image/detail-image/${imageId}`),
  generateImage: (data: any) => axiosClient.post<any>("api/image/generate/", data),
};

export default generateImgApis;
