import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { ToastProvider } from "./hooks/useToast/useToast";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import SocketsProvider from "./hooks/useSocket";
import GenerateImageContextProvider from "./contexts/generateImageContext";
import ModalContextProvider from "./contexts/modalContext";
import CommonContextProvider from "./contexts/commonContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <SocketsProvider>
        <CommonContextProvider>
          <GenerateImageContextProvider>
            <ModalContextProvider>
              <ToastProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </ToastProvider>
            </ModalContextProvider>
          </GenerateImageContextProvider>
        </CommonContextProvider>
      </SocketsProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
