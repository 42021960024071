import classname from "classnames";
import { useModalContext } from "../../../contexts/modalContext";

export const ModalWaiting: React.FC = () => {
  const { modalWaiting } = useModalContext();

  return (
    <div className={classname("modal ", modalWaiting && "modal-open")}>
      <div className="modal-box bg-[#1D2939] py-[30px] rounded-rddefault overflow-auto scrollbar w-full">
        <div className="flex items-center">
          <h3 className="text-white block mr-6">Your payment is being processed. Please wait for a while</h3>
          <div className="dot-typing"></div>
        </div>
      </div>
    </div>
  );
};
