import { imagesCommon } from "../../assets/images";
import { IAboutUsData } from "../../types/about-us";

type Props = {
  information: IAboutUsData | undefined;
};

export const AboutSotatek: React.FC<Props> = ({ information }) => {
  return (
    <div className="bg-bggray text-white block xl:flex xl:justify-evenly rounded-xl text-4 md:text-[18px] my-8 p-4 md:p-9">
      <div className="w-full xl:w-3/5 xl:max-w-2xl">
        <span className="text-primary font-bold">About Sotatek</span>
        <p className="text-2xl md:text-[32px] font-black leading-10 md:leading-[50px] my-3">{information?.title}</p>
        <div dangerouslySetInnerHTML={{ __html: information?.content as string }} />
      </div>
      <div className="w-full sm:w-3/5 lg:w-1/2 xl:w-2/5 xl:max-w-xl mt-6 xl:mt-0 mx-auto">
        <div className="w-full text-center xl:text-right mb-4">
          <img
            src={information?.images[0] || imagesCommon.aboutUs01}
            alt="about_us_01"
            className="inline-block w-2/5 align-bottom mr-4"
          />
          <img
            src={information?.images[1] || imagesCommon.aboutUs02}
            alt="about_us_02"
            className="inline-block w-2/5 align-bottom"
          />
        </div>
        <div className="w-full text-center xl:text-right">
          <img
            src={information?.images[2] || imagesCommon.aboutUs03}
            alt="about_us_03"
            className="inline-block w-1/3 mr-4 align-top"
          />
          <img
            src={information?.images[3] || imagesCommon.aboutUs04}
            alt="about_us_04"
            className="inline-block w-3/5 align-top"
          />
        </div>
      </div>
    </div>
  );
};
