import React, { memo } from "react";
interface ModalPropsType {
  className?: string;
  title: string;
  children?: React.ReactNode;
  onClose: () => void;
  btnText?: string;
  hiddenButton?: boolean;
  btnCorner?: boolean;
  maxSize?: boolean;
  btnActionName?: string[];
  btnActionClick?: any;
}

const ModalComponent: React.FC<ModalPropsType> = memo((props) => {
  const {
    className,
    title,
    children,
    onClose,
    hiddenButton,
    btnCorner,
    maxSize,
    btnActionName,
    btnActionClick,
    btnText,
  } = props;

  return (
    <div className={`modal__login-to-generate ${className}`}>
      <input type="checkbox" id="my-modal" className="modal-toggle" defaultChecked />
      <div className="modal ">
        <div className={`modal-box bg-bggray text-ttitle ${maxSize ? "w-11/12 max-w-5xl" : ""}`}>
          {btnCorner && (
            <label
              htmlFor="my-modal-3"
              className="btn btn-sm btn-circle absolute right-2 top-2 bg-primary border-0 hover:bg-primary"
              onClick={onClose}
            >
              ✕
            </label>
          )}
          <h3 className="font-bold text-lg">{title}</h3>
          <p className="pt-4">{children}</p>
          {!hiddenButton && (
            <div
              className={`flex flex-col items-center justify-center sm:flex-row mt-6 ${
                btnActionName !== undefined ? "sm:justify-center" : "sm:justify-end"
              }`}
            >
              {btnActionName?.map((btn, index) => (
                <button
                  key={index}
                  type="button"
                  className={`btn bg-primary border-0 hover:bg-bgbtn transition-all text-white self-end outline-none  mx-auto mb-4 sm:mr-4  sm:mb-0 w-5/6 sm:w-fit`}
                  onClick={() => btnActionClick(btn)}
                >
                  {btn}
                </button>
              ))}
              <button
                type="button"
                className={`btn bg-primary border-0 hover:bg-bgbtn transition-all text-white self-end outline-none w-5/6 sm:w-fit mx-auto `}
                onClick={onClose}
              >
                {btnText ? btnText : "Close"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export { ModalComponent };
