import { InputText } from "../types/generate";

export const getBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result?.toString() || "");
    reader.onerror = (error) => reject(error);
  });
};

export const urltoFile = async (url: string, filename: string, mimeType: string) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
};

export const downloadImgToDevice = async (imgURL: string) => {
  const originalImage = imgURL;
  const image = await fetch(originalImage);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const link = document.createElement("a");
  link.href = imageURL;
  link.download = "";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const timeConvert = (time?: string) => {
  if (time) {
    const timeCreated = new Date(time);
    const year = String(timeCreated.getFullYear()).padStart(2, "0");
    const month = String(timeCreated.getMonth() + 1).padStart(2, "0");
    const date = String(timeCreated.getDate()).padStart(2, "0");
    const hours = String(timeCreated.getHours()).padStart(2, "0");
    const minutes = String(timeCreated.getMinutes()).padStart(2, "0");
    const seconds = String(timeCreated.getSeconds()).padStart(2, "0");
    return `${date}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  } else {
    return "";
  }
};

export const classStatus = (status: number) => {
  switch (status) {
    case 0:
      return "text-red-500";
    case 1:
      return "text-green-500";
    case 2:
      return "text-primary";
    default:
      return null;
  }
};

export const createFormData = async (blobImage: Blob | File, description: InputText, promptEdit?: string) => {
  const hashtags = description.hashtag.split(" ").map((hashtag: string) => hashtag);
  const formData = new FormData();
  if (description?.id) {
    formData.append("id", description?.id);
  }
  if (typeof description?.isPublic === "boolean") {
    formData.append("isPublic", description.isPublic as any);
  }
  formData.append("file", blobImage);
  formData.append("prompt", description?.prompt);
  formData.append("styleImage", description?.style?.[0]);
  formData.append("promptEdit", promptEdit as string);
  formData.append("hashtagList", hashtags as any);
  return formData;
};

export const randomId = () => {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(2, 10);
};

export const getBase64FromUrl = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const getBase64EditMark = async (canvasRef: any, canvasRef2: any) => {
  if (canvasRef.current !== null) {
    const pathsExport = await canvasRef.current.exportPaths();
    const pathChangeColor = pathsExport.map((item: any) => ({ ...item, strokeColor: "white" }));

    await canvasRef2.current?.loadPaths(pathChangeColor);
    return await canvasRef2.current?.exportImage("png");
  }
};

export const numberWithSpaces = (x: string) => {
  return x.replace(/\B(?=(\d{4})+(?!\d))/g, " ");
};
