import React from "react";
import { Link } from "react-router-dom";

const LogoBanner: React.FC = () => {
  return (
    <Link to={"/"} className="normal-case text-xl p-0">
      <div className="w-full">
        <svg width="252.5" height="65" viewBox="0 0 101 26" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.4824 24.1079L23.0076 25.6043C24.0052 25.8849 26.2997 25.9223 27.4969 23.8273C27.9645 23.1415 28.5257 21.1899 27.0292 18.8704L18.0507 3.43851C17.5207 2.34737 16.0118 0.183784 14.2161 0.258605L13.7839 0.260478C11.9882 0.185656 10.4793 2.34924 9.94931 3.44038L0.970753 18.8723C-0.525673 21.1917 0.0354865 23.1433 0.50312 23.8292C1.70026 25.9242 3.99478 25.8868 4.9924 25.6062L7.51762 24.1098L13.8419 20.3668L14.029 20.4722L20.4824 24.1079ZM20.1491 19.0137L14.364 16.4914C14.0948 16.3741 13.7876 16.381 13.5239 16.5103L12.9732 16.7804L7.8801 19.001C6.98648 19.3906 6.12044 18.4018 6.62447 17.5673L13.1987 6.68335C13.589 6.03715 14.5272 6.03993 14.9137 6.68842L21.4078 17.5851C21.9053 18.4199 21.04 19.4021 20.1491 19.0137Z"
            fill="url(#paint0_linear_800_3517)"
          />
          <path
            d="M66.4286 22.107L65.2573 19.0727H55.2841L54.1128 22.1729C53.6554 23.3823 53.2649 24.2013 52.9414 24.6301C52.6179 25.0478 52.088 25.2567 51.3517 25.2567C50.727 25.2567 50.1748 25.0313 49.6951 24.5806C49.2154 24.1298 48.9756 23.6186 48.9756 23.047C48.9756 22.7171 49.0314 22.3763 49.1429 22.0245C49.2545 21.6727 49.4385 21.1835 49.6951 20.5568L55.9702 4.85764C56.1487 4.40689 56.3606 3.86819 56.6061 3.24154C56.8626 2.6039 57.1304 2.0762 57.4093 1.65843C57.6993 1.24066 58.073 0.905351 58.5304 0.652492C58.9989 0.38864 59.5735 0.256714 60.254 0.256714C60.9456 0.256714 61.5201 0.38864 61.9775 0.652492C62.446 0.905351 62.8198 1.23517 63.0986 1.64194C63.3887 2.04871 63.6285 2.48846 63.8182 2.9612C64.019 3.42294 64.27 4.04409 64.5712 4.82466L70.9801 20.4249C71.4821 21.6123 71.7331 22.4753 71.7331 23.014C71.7331 23.5747 71.4933 24.0914 71.0136 24.5641C70.545 25.0258 69.9761 25.2567 69.3068 25.2567C68.9163 25.2567 68.5817 25.1853 68.3028 25.0423C68.0239 24.9104 67.7896 24.729 67.6 24.4981C67.4103 24.2563 67.2039 23.8935 66.9808 23.4097C66.7689 22.915 66.5848 22.4808 66.4286 22.107ZM56.5893 15.3952H63.9186L60.2205 5.41832L56.5893 15.3952Z"
            fill="white"
          />
          <path
            d="M79.6146 18.7594V22.5192C79.6146 23.4317 79.3971 24.1189 78.962 24.5806C78.5269 25.0313 77.9747 25.2567 77.3054 25.2567C76.6472 25.2567 76.1061 25.0258 75.6822 24.5641C75.2583 24.1024 75.0464 23.4207 75.0464 22.5192V9.98627C75.0464 7.9634 75.7882 6.95196 77.2719 6.95196C78.0305 6.95196 78.5771 7.18833 78.9118 7.66107C79.2465 8.1338 79.4305 8.83191 79.464 9.75539C80.0106 8.83191 80.5684 8.1338 81.1373 7.66107C81.7174 7.18833 82.4872 6.95196 83.4466 6.95196C84.406 6.95196 85.3374 7.18833 86.2411 7.66107C87.1447 8.1338 87.5965 8.76045 87.5965 9.54101C87.5965 10.0907 87.4012 10.547 87.0108 10.9097C86.6315 11.2616 86.2187 11.4375 85.7725 11.4375C85.6052 11.4375 85.198 11.3385 84.551 11.1406C83.9151 10.9317 83.3517 10.8273 82.8609 10.8273C82.1916 10.8273 81.6449 11.0032 81.221 11.355C80.7971 11.6958 80.468 12.207 80.2337 12.8886C79.9995 13.5703 79.8377 14.3838 79.7485 15.3293C79.6592 16.2638 79.6146 17.4071 79.6146 18.7594Z"
            fill="white"
          />
          <path
            d="M90.7926 7.34774H91.2946V4.64326C91.2946 3.91766 91.3113 3.35148 91.3448 2.94471C91.3894 2.52694 91.5009 2.16964 91.6794 1.87281C91.8579 1.56498 92.1145 1.31762 92.4492 1.13072C92.7838 0.932835 93.1576 0.833891 93.5703 0.833891C94.1504 0.833891 94.6747 1.04827 95.1433 1.47703C95.4556 1.76287 95.6509 2.11467 95.729 2.53244C95.8182 2.93921 95.8628 3.52189 95.8628 4.28046V7.34774H97.5362C98.1832 7.34774 98.674 7.50166 99.0087 7.80948C99.3545 8.10632 99.5274 8.4911 99.5274 8.96384C99.5274 9.5685 99.282 9.99176 98.7912 10.2336C98.3115 10.4755 97.6198 10.5964 96.7162 10.5964H95.8628V18.8748C95.8628 19.5784 95.8851 20.1226 95.9297 20.5074C95.9855 20.8812 96.1194 21.189 96.3314 21.4309C96.5545 21.6617 96.9114 21.7772 97.4023 21.7772C97.67 21.7772 98.0326 21.7332 98.49 21.6452C98.9473 21.5463 99.3043 21.4968 99.5609 21.4968C99.929 21.4968 100.258 21.6452 100.548 21.9421C100.849 22.2279 101 22.5852 101 23.014C101 23.7396 100.598 24.2948 99.7952 24.6795C98.992 25.0643 97.8374 25.2567 96.3314 25.2567C94.9034 25.2567 93.8213 25.0203 93.0851 24.5476C92.3488 24.0749 91.8635 23.4207 91.6292 22.5852C91.4061 21.7497 91.2946 20.6338 91.2946 19.2376V10.5964H90.6922C90.034 10.5964 89.532 10.4425 89.1862 10.1347C88.8403 9.82685 88.6674 9.43657 88.6674 8.96384C88.6674 8.4911 88.8459 8.10632 89.2029 7.80948C89.571 7.50166 90.1009 7.34774 90.7926 7.34774Z"
            fill="white"
          />
          <path
            d="M31.6229 22.2719V3.22505C31.6229 2.23561 31.8516 1.49352 32.309 0.998798C32.7664 0.504075 33.3576 0.256714 34.0827 0.256714C34.8302 0.256714 35.4326 0.504075 35.89 0.998798C36.3585 1.48253 36.5928 2.22461 36.5928 3.22505V22.2719C36.5928 23.2723 36.3585 24.0199 35.89 24.5146C35.4326 25.0094 34.8302 25.2567 34.0827 25.2567C33.3688 25.2567 32.7775 25.0094 32.309 24.5146C31.8516 24.0089 31.6229 23.2613 31.6229 22.2719Z"
            fill="url(#paint1_linear_800_3517)"
          />
          <defs>
            <linearGradient id="paint0_linear_800_3517" x1="0" y1="13" x2="28" y2="13" gradientUnits="userSpaceOnUse">
              <stop stopColor="#0052D4" />
              <stop offset="1" stopColor="#6FB1FC" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_800_3517"
              x1="31.6229"
              y1="12.7567"
              x2="36.5928"
              y2="12.7567"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#0052D4" />
              <stop offset="1" stopColor="#6FB1FC" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </Link>
  );
};

export { LogoBanner };
