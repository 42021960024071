export const imagesPayment = {
  bankTransfer: require("./bank_transfer_logo.png"),
  bgCredit: require("./bg_credit.png"),
  chipCredit: require("./chip_credit.png"),
  creditCardLogo: require("./creditcard_logo.png"),
  logoCredit: require("./logo_credit.png"),
  maskCredit: require("./mask_credit.png"),
  paymentFail: require("./payment-fail.webp"),
  paymentSuccess: require("./payment-succes.png"),
  qrCode: require("./QR_Code.png"),
  tpBank: require("./TPBank.png"),
  uploadImage: require("./upload-image.png"),
  vnPayLogo: require("./vnpay_logo.png"),
  vnPayQR: require("./VNPay_QR.png"),
};
