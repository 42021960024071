import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import { COMMON } from "../../constants";
import { ArrFilterArray } from "../../types/my-images";

const filterArray = [
  {
    id: 1,
    title: "Style",
    children: [
      {
        id: 1,
        text: COMMON.STYLE_IMAGE.CARTOON,
      },
      {
        id: 2,
        text: COMMON.STYLE_IMAGE.MANGA,
      },
      {
        id: 3,
        text: COMMON.STYLE_IMAGE.BLACK_WHITE,
      },
      {
        id: 4,
        text: COMMON.STYLE_IMAGE.OLD_PHOTO,
      },
      {
        id: 5,
        text: COMMON.STYLE_IMAGE.CHIBI,
      },
    ],
  },
];

type filterObjType = { id: number; title: string; children: filterItemType[] }[];
type filterItemType = { id: number; text: string };

const Checkbox = styled.input`
  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  }
`;

interface Props {
  label?: string;
  onFilter: (arrFilter: ArrFilterArray) => void;
}

const FilterBox: React.FC<Props> = memo(({ label, onFilter }) => {
  const [filterList, setFilterList] = useState<filterObjType>([]);
  const [selectedList, setSelectedList] = useState<filterObjType>([]);

  useEffect(() => {
    setFilterList([...filterArray]);
    const newList: filterObjType = [...filterArray].map((f) => {
      return { ...f, children: [] };
    });
    setSelectedList(newList);
  }, [filterArray]);

  const handleSelect = (idx: number, item: filterItemType) => {
    const newSelectedList: filterObjType = [...selectedList];

    if (newSelectedList[idx].children.includes(item)) {
      newSelectedList[idx].children = newSelectedList[idx].children.filter((i: filterItemType) => i !== item);
    } else {
      newSelectedList[idx].children.push(item);
    }
    setSelectedList(newSelectedList);
    onFilter(newSelectedList);
  };

  return (
    <div>
      <ul>
        {filterList.map((value, idx) => {
          return (
            <div className="mt-4" key={value.id}>
              <div className="font-bold text-tprimary mb-2">{value.title}</div>
              <>
                {value.children.map((item: any) => {
                  return (
                    <div className="flex align-center py-1 w-40" key={item.id}>
                      <Checkbox
                        type="checkbox"
                        className="checkbox checked:bg-primary border-bdcolor border-2"
                        checked={item.check}
                        id={`item${item.id}`}
                        onClick={() => handleSelect(idx, item)}
                      />
                      <label className="labelText ml-2 text-tprimary cursor-pointer" htmlFor={`item${item.id}`}>
                        {item.text}
                      </label>
                    </div>
                  );
                })}
              </>
            </div>
          );
        })}
      </ul>
    </div>
  );
});

export { FilterBox };
