import { imagesInterior } from "../assets/images/interior";

export const ROOM = {
  LIVING: "Living room",
  BED: "Bedroom",
  BATH: "Bath room",
  ATTIC: "Attic",
  KITCHEN: "Kitchen",
  DINING: "Dining",
  STUDY: "Study room",
  HOME_OFFICE: "Home Office",
  GAMING: "GAMING ROOM",
  OUTDOOR_POOL_AREA: "Outdoor pool area",
  OUTDOOR_PATIO: "Outdoor patio",
  OUTDOOR_GARDEN: "Outdoor garden",
  MEETING: "Meeting room",
  WORKSHOP: "Workshop",
  FITNESS_GYM: "Fitness gym",
  COFFEE_SHOP: "Coffee shop",
  CLOTHING_STORE: "Clothing store",
  WALK_CLOSET: "Walk in closet",
  TOILET: "Toilet",
  RESTAURANT: "Restaurant",
  OFFICE: "Office",
  COWORKING_SPACE: "Coworking space",
  HOTEL_LOBB: "Hotel lobby ",
  HOTEL_ROOM: "Hotel room",
  HOTEL_BATHROOM: "Hotel bathroom",
  EXHIBITION_SPACE: "Exhibition space",
  ONSEN: "Onsen",
  MUDROOM: "Mudroom",
  DROP_ZONE: "Drop zone",
};

export const INTERIOR_STYLE = {
  MODERN: "Modern",
  MINIMALIST: "Minimalist",
  CONTEMPORARY: "Contemporary",
  SCANDINAVIAN: "Scandinavian",
  INTERIOR_AI: "Interior AI",
  ZEN: "ZEN",
  MIDCENTURY_MODERN: "Midcentury",
  TROPICAL: "Tropical",
  BIOPHILIC: "Biophilic",
  INDUSTRIAL: "Industrial",
  ART_DECO: "Art deco",
  FARMHOUSE: "Farmhouse",
  RUSTIC: "Rustic",
  BOHEMIAN: "Bohemian",
  JAPANESE_DESIGN: "Japanese design",
  VINTAGE: "Vintage",
  COASTAL: "Coastal",
  COTTAGECORE: "Cottagecore",
  FRENCH_COUNTRY: "French country",
  ART_NOUVEAU: "Art nouveau",
  CYBERPUNK: "Cyberpunk",
  MAXIMALIST: "Maximalist",
  GAMING: "Gaming room",
  VAPORWAVE: "Vaporwave",
  BAROQUE: "Baroque",
  SKI_CHALET: "Ski chalet",
  CHRISTMAS: "Christmas",
  SKETCH: "Sketch",
  TRIBAL: "Tribal",
  MEDIEVAL: "Medieval",
  CHINESE_NEW_YEAR: "Chinese New Year",
  HALLOWEEN: "Halloween",
  EASTER: "Easter",
  NEOCLASSIC: "Neoclassic (Pro)",
};
export const listImage = [
  imagesInterior.art,
  imagesInterior.artNouveau,
  imagesInterior.baroque,
  imagesInterior.bioPhilic,
  imagesInterior.bohemian,
  imagesInterior.chineseNewyear,
  imagesInterior.bohemian,
  imagesInterior.chineseNewyear,
  imagesInterior.bohemian,
  imagesInterior.chineseNewyear,
];
