import React, { useCallback, useEffect, useState } from "react";
import { collectionsApis } from "../../../apis/image";
import { SORT_FILTER } from "../../../constants";
import { useCommonContext } from "../../../contexts/commonContext";
import { useModalContext } from "../../../contexts/modalContext";
import { useToast } from "../../../hooks/useToast/useToast";
import { ArrFilterArray, IImageInfo } from "../../../types/my-images";
import CatchError from "../../../utils/catch-error";
import { Loader } from "../../commons/Loader";
import { SortAndFilter } from "../../commons/SortAndFilter";
import { ModalFavoriteToLogin } from "../../modal/ModalFavoriteToLogin";
import ImageBox from "./ImageBox";

export const Collections: React.FC = () => {
  const { toast } = useToast();
  const { modalFavorite } = useModalContext();
  const { collectionImages, setCollectionImages, user } = useCommonContext();

  const [count, setCount] = useState<number>(0);
  const [valueSearch, setValueSearch] = useState<string>("");
  const [arrFilterState, setArrFilterState] = useState<string[]>([]);
  const [typeSort, setTypeSort] = useState<number | null>(-1);
  const [sortState, setSortState] = useState<string>("createdAt");
  const [page, setPage] = useState<number>(1);
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [isLoadData, setIsLoadData] = useState<boolean>(false);
  const [listHashtag, setListHashtag] = useState<string[]>([]);
  const getImage = (
    valueSearch: string,
    arrFilterState: string[],
    sortState: string,
    page: number,
    typeSort: number | null,
    listHashtag?: string[],
  ) => {
    return collectionsApis
      .getItem({
        isPublic: true,
        prompt: valueSearch || "",
        styleImage: arrFilterState || [],
        sort: sortState || "createdAt",
        type: typeSort || -1,
        limit: 12,
        page: page,
        userId: user.userInfo?.id ? user.userInfo.id : undefined,
        hashtagListQuery: listHashtag,
      })
      .then((response: any) => {
        if (response.message === "ok") {
          const listImageRes: IImageInfo[] = response?.data?.listImage;
          if (page !== 1) {
            setCollectionImages((pre) => {
              return [...pre, ...listImageRes];
            });
          } else {
            setCollectionImages(listImageRes);
          }
          setIsLoadMore(false);
          setIsLoadData(false);
          setCount(response?.data?.count);
        }
      })
      .catch((error: any) => {
        toast.error(CatchError(error));
      });
  };

  useEffect(() => {
    setIsLoadData(true);
    setPage(1);
  }, [valueSearch, arrFilterState, sortState, typeSort, listHashtag]);

  useEffect(() => {
    getImage(valueSearch, arrFilterState, sortState, page, typeSort, listHashtag);
  }, [valueSearch, arrFilterState, sortState, page, typeSort, listHashtag]);

  const handleSearch = useCallback((search: string) => {
    setValueSearch(search);
  }, []);

  const handleSort = useCallback((sort: string) => {
    switch (sort) {
      case "Newest → Oldest":
        return setSortState("createdAt"), setTypeSort(-1);
      case "Oldest → Newest":
        return setSortState("createdAt"), setTypeSort(1);
      default:
        return setSortState("numberOfLike"), setTypeSort(null);
    }
  }, []);

  const handleFilter = useCallback((arrFilter: ArrFilterArray) => {
    const { children } = arrFilter[0];
    const value = children.map((value: { id: number | string; text: string }) => value.text);
    setArrFilterState(value);
  }, []);

  const handleLoadMore = useCallback(() => {
    setIsLoadMore(true);
    setPage((p) => p + 1);
  }, []);

  return (
    <div>
      <>{modalFavorite && <ModalFavoriteToLogin title="Favorite Error" text="Please login to drop favorites!" />}</>
      <div className="py-12">
        <div className="w-full">
          <SortAndFilter
            onSearch={handleSearch}
            onSort={handleSort}
            onFilter={handleFilter}
            sortList={SORT_FILTER.SortListHomePage}
            setValueSearch={setValueSearch}
            listHashtag={listHashtag}
            setListHashtag={setListHashtag}
          />
        </div>
        {/* ========================================================================== */}
        {isLoadData ? (
          <div className="h-[1236px] mt-[36px] pt-[200px] flex items-start justify-center w-full">
            <Loader />
          </div>
        ) : (
          <ImageBox
            setListHashtag={setListHashtag}
            listHashtag={listHashtag}
            data={collectionImages}
            count={count}
            isLoadMore={isLoadMore}
            onLoadMore={handleLoadMore}
          />
        )}
      </div>
    </div>
  );
};
